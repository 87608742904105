import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from '@fullcalendar/interaction'
import elLocale from "@fullcalendar/core/locales/el";
import { EventContent } from "./timeline_components/EventContent";
import { useCallback, createRef, useLayoutEffect, useMemo } from "react";
import LabelContent from "./timeline_components/LabelContent";
import { Holiday } from "../../../backoffice/dtos/holidays/holiday";
import { TimelineEventClick } from "../../../app/types";
import { resourceAreaColumns, ResourceItem, TimelineEvent } from "../models/NTimelineModel";
import { DateSelectArg, EventChangeArg, FormatterInput } from "@fullcalendar/core";
import { getDataRangeMonthDuration, getDefaultTimelineRange, rangeParse } from "../../../helpers/Types";
import { dateOnly, getMonthDuration } from "../../../helpers/dateHelper";
import { getCanEdit } from "../../../backoffice/dtos/authentication/user";
import { getUser } from "../../../helpers/localStorage";
import { PromotionCriteria, PromotionCriteriaComparison } from "../../../backoffice/dtos/product/promotionCriteria";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { TimelineDefaultPromotionCriteria } from "./PromotionsTimelineBase";
import { downloadExcel } from "../../../backoffice/apis/excelAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export default function PromotionsTimeline(
    {
        holidays,
        promotionCriteria,
        toggleStatistics,
        toggleShowFilters,
        timelineResources,
        timelineEvents,
        eventClickHandler,
        calendarSelectHandler,
        eventDateRangeChange,
        setPromotionCriteria,
        toggleColorInfo
    }: {
        holidays: Holiday[] | undefined
        promotionCriteria: PromotionCriteria
        toggleStatistics: () => void
        toggleShowFilters: () => void
        timelineEvents: TimelineEvent[]
        timelineResources: ResourceItem[]
        eventClickHandler: (info: TimelineEventClick) => void
        calendarSelectHandler: (arg: DateSelectArg) => void
        eventDateRangeChange: (e: EventChangeArg) => void
        setPromotionCriteria: (promotionCriteria: PromotionCriteria) => void
        toggleColorInfo: () => void
    }
) {
    const user = getUser()
    const ref = createRef<FullCalendar>();

    const { t } = useTranslation(['promotion_timeline'])

    const dateRange = useMemo(() => {
        if (!promotionCriteria.dateRange)
            return getDefaultTimelineRange();
        return rangeParse(promotionCriteria.dateRange);
    }, [promotionCriteria.dateRange])

    const labelClassnameGenerator = useCallback((args: any) => {
        if (holidays === undefined)
            return '';
        const hdays = (holidays as Holiday[]).map(m => dateOnly(m.dateTime));
        return hdays.some(m => m.getTime() === dateOnly(args.date).getTime()) ? "fc-holy-day" : "";
    }, [holidays])

    useLayoutEffect(() => {
        const span = document.createElement('span');
        span.classList.add("fa", "fa-filter", "p-1");
        const elements = document.getElementsByClassName("fc-filters-button");
        const firstElement = elements.length > 0 ? elements[0] : null;
        firstElement?.appendChild(span);
        return () => { firstElement?.removeChild(span) }
    }, [])

    useLayoutEffect(() => {
        const span = document.createElement('span');
        span.classList.add("fa", "fa-info", "p-1");
        span.style.color = "#333"
        span.style.fontWeight = "bold"
        const elements = document.getElementsByClassName("fc-colorInfo-button");
        const firstElement = elements.length > 0 ? elements[0] : null;
        firstElement?.appendChild(span);
        return () => { firstElement?.removeChild(span) }
    }, [])

    useLayoutEffect(() => {
        const span = document.createElement('span');
        span.classList.add("fa", "fa-file-excel-o", "p-1");
        span.style.color = "#3a3"
        span.style.fontWeight = "bold"
        const elements = document.getElementsByClassName("fc-excel-button");
        const firstElement = elements.length > 0 ? elements[0] : null;
        firstElement?.appendChild(span);
        return () => { firstElement?.removeChild(span) }
    }, [])

    const visibleMonthsIndex = useMemo(() => {
        return getDataRangeMonthDuration(dateRange);
    }, [promotionCriteria.dateRange])

    const slotLblFormat: FormatterInput[] = useMemo(() => {
        return visibleMonthsIndex === 0 ?
        [{ weekday: 'short' }, { day: 'numeric' }]
        : [{ month: 'short' }, { weekday: 'short' }, { day: 'numeric' }];
    }, [visibleMonthsIndex])

    const nowFromBeginning = useMemo(() => {
        return getMonthDuration(dateRange.fromDate, new Date()) - 1;
    }, [])

    const leftButtons = useMemo(() => {
        const buttons = ['colorInfo', 'filters', 'excel', 'focus']
        if (!PromotionCriteriaComparison(promotionCriteria, TimelineDefaultPromotionCriteria)) {
            buttons[1] = "filters,cancelFilters"
        }
        return buttons.join(" ")
    }, [promotionCriteria])

    return (
                //<React.Suspense fallback={<Loading />}>
        <div className="d-flex flex-fill m-2 mt-3">
            <FullCalendar

                initialView='resourceTimelineMonth'
                slotLabelFormat={slotLblFormat}
                plugins={[resourceTimelinePlugin, interactionPlugin]}
                headerToolbar={{
                    left: leftButtons,
                    center: 'title',
                    right: 'statistics'
                }}

                locale={i18next.language === 'el' ? elLocale : i18next.language}
                resourceOrder='order'
                resourceGroupField='customer'
                resourceGroupLabelClassNames={"customers-text"}
                resourceAreaColumns={resourceAreaColumns()}
                resources={timelineResources}
                initialDate={dateRange.fromDate}
                events={timelineEvents}

                eventContent={EventContent}
                slotLabelContent={LabelContent}
                resourceAreaWidth={"470px"}
                slotMinWidth={40}

                slotLabelClassNames={labelClassnameGenerator}
                slotLaneClassNames={labelClassnameGenerator}

                editable
                selectable
                selectMirror
                dayMaxEvents
                weekends
                eventStartEditable
                eventAllow={(dropLocation, draggedEvent) => user !== undefined && getCanEdit(user)}
                height="unset"
                resourceLaneClassNames={GenerateResourceLaneClassNames}
                eventClick={eventClickHandler}
                eventChange={eventDateRangeChange}

                select={calendarSelectHandler}
                schedulerLicenseKey='0218300512-fcs-1674722985'

                ref={ref}
                scrollTime={{
                    day: 0,
                    month: nowFromBeginning,
                    year: 0
                }}

                customButtons={{
                    focus: {
                        text: t("today"),
                        click: function () {
                            ref.current?.getApi().scrollToTime({
                                day: 0,
                                month: nowFromBeginning,
                                year: 0
                            })
                        }
                    },
                    colorInfo: {
                        text: "",
                        click: function() {
                            toggleColorInfo()
                        }
                    },
                    statistics: {
                        text: t('statistics_title'),
                        click: function () {
                            toggleStatistics()
                        }
                    },
                    excel: {
                        click: async function () {
                            await downloadExcel(promotionCriteria)
                        }
                    },
                    filters: {
                        text: "",
                        click: () => {
                            toggleShowFilters()
                        }
                    },
                    cancelFilters: {
                        text: '❌',
                        click: () => {
                            setPromotionCriteria(TimelineDefaultPromotionCriteria)
                        }
                    }
                }}

                views={{
                    resourceTimelineMonth: {
                        type: 'resourceTimeline',
                        duration: { months: visibleMonthsIndex },
                        buttonText: "",
                        slotDuration: { days: 1 }
                    }
                }}
            />
        </div>
    )

}


function GenerateResourceLaneClassNames(args: any) {
    const order = args.resource.extendedProps.order;
    return `lane_color_${order % 2} min-height-50px`;
}
