import { Button, Container, Modal, ModalBody, ModalHeader, Spinner, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCircleXmark, faFileCircleXmark} from "@fortawesome/free-solid-svg-icons";
import { useGetPromotionByIdOrSapQuery } from "../../backoffice/apis/promotionAPI";
import { useMemo } from "react";
import { formatToDMY } from "../../helpers/dateHelper";
import { useTranslation } from "react-i18next";
import { useSetPromotionId } from "../../app/hooks";

export default function PromotionSelectionModal(
    {
        promotionId,
        isOpen,
        toggle
    }:{
        promotionId: string,
        isOpen: boolean,
        toggle: () => void
    }
) {
    const {data: promotions, isFetching: promotionIsFetching, error: promotionError} = useGetPromotionByIdOrSapQuery(promotionId,{skip:!isOpen})
    const {t} = useTranslation(['header'])
    const setPromotionId = useSetPromotionId()

    const error = useMemo(() => {
        if (!promotionError || promotionId.length === 0)
            return undefined;

        return 'data' in promotionError ? JSON.stringify((promotionError as any).data) : "unknown error";
    }, [promotionError])

    let body: JSX.Element = <></>
    if (promotionIsFetching) {
        body = (
            <div className="d-flex flex-row align-content-center justify-content-center">
                <Spinner className="m-4"/>
            </div>
        );
    } if (error) {
        body = (
            <div className="d-flex flex-column align-items-center justify-content-center">
                <FontAwesomeIcon icon={faCircleXmark} size="4x" className="m-2 text-danger"/>
                <h5>{error}</h5>
            </div>
        );
    } if (promotions && promotions.length > 0) {
        body = (
            <>
                <Container>
                    <Table>
                        <thead>
                            <tr>
                                <th>{t('custom_id')}</th>
                                <th>{t('sap_id')}</th>
                                <th>{t('start_date')}</th>
                                <th>{t('end_date')}</th>
                                <th style={{width:"50px"}}></th>
                            </tr>
                        </thead>
                        <tbody >  
                            {promotions.map((prom) => {                                
                                return (
                                    <tr key={prom.id} >
                                        <td style={{borderLeft:"4px solid #cc8a00"}}>
                                            {prom.id}
                                        </td>   
                                        <td>
                                            {prom.poNumber}
                                        </td>
                                        <td>
                                            {formatToDMY(new Date(prom.fromDate))}
                                        </td>
                                        <td>
                                            {formatToDMY(new Date(prom.toDate))}
                                        </td>
                                        <td style={{minWidth:"50px"}}>
                                            <Button className="w-100" color="primary" onClick={() => setPromotionId(prom.id)}><FontAwesomeIcon icon={faArrowRight}/></Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Container>
            </>
        );
    } else {
        body = (
            <div className="d-flex flex-column align-items-center justify-content-center">
                <FontAwesomeIcon icon={faFileCircleXmark} size="4x" className="m-2 text-danger"/>
                <h5>{t('on_error_promotion_find_modal')}</h5>
            </div>
        );
    }

    return (
        <Modal unmountOnClose isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>
            {t('promotion_find_modal_title')}
            </ModalHeader>
            <ModalBody>
                {body}
            </ModalBody>
        </Modal>
    )
}