import { faCircleRight, faSearch, faTimes, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { title } from "process";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Container, Input, InputGroup, InputGroupText, Row, Spinner } from "reactstrap";

export default function ListSelection(
    {
        titleFrom,
        titleTo,
        items,
        onValuesChange
    }: {
        titleFrom: string,
        titleTo: string,
        items: ListItem[],
        onValuesChange: (selectedItems: ListItem[]) => void;
    }
) {
    const [selectedItems, setSelectedItems] = useState<ListItem[]>([]);
    const [fromItemsSearch, setFromItemsSearch] = useState('');
    const [toItemsSearch, setToItemsSearch] = useState('');
    const {t} = useTranslation(['promotion_timeline'])

    const listItems = useMemo(() => {
        const selectedItemIds = new Set(selectedItems.map(it => it.Id))
        return items.filter(m => !selectedItemIds.has(m.Id));
    }, [items, selectedItems]);

    const fromItemsToShow = useMemo(() => {
        if (fromItemsSearch.length === 0)
            return listItems;
        return listItems.filter(m => m.Label.includes(fromItemsSearch))
    }, [listItems, fromItemsSearch])

    const toItemsToShow = useMemo(() => {
        if (toItemsSearch.length === 0)
            return selectedItems;
        return selectedItems.filter(m => m.Label.includes(toItemsSearch))
    }, [selectedItems, toItemsSearch])

    const setItems = useCallback((it: ListItem[]) => {
        onValuesChange(it);
        setSelectedItems(it);
    }, []);

    const addItem = useCallback((it: ListItem) => {
        const newItems = [...selectedItems, it];
        setItems(newItems);
    }, [selectedItems]);

    const removeItem = useCallback((it: ListItem) => {
        const newItems = selectedItems.filter(i => i.Id !== it.Id);
        setItems(newItems);
    }, [selectedItems]);

    return (
        <Container className="p-3">
            <Row>
                <Col>
                    <h3>{titleFrom}</h3>
                </Col>
                <Col>
                    <h3>{titleTo}</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputGroup>
                        <Input placeholder= {t('search',{ns:'common'})} value={fromItemsSearch} onChange={(it) => setFromItemsSearch(it.target.value)}/>
                        { fromItemsSearch.length > 0 
                            ? <Button color="danger" onClick={() => setFromItemsSearch('')}><FontAwesomeIcon icon={faTimes}/></Button>
                            : <InputGroupText><FontAwesomeIcon icon={faSearch}/></InputGroupText>
                        }
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup>
                        <Input className="form-control" placeholder= {t('search',{ns:'common'})} value={toItemsSearch} onChange={(it) => setToItemsSearch(it.target.value)}/>
                        { toItemsSearch.length > 0 
                            ? <Button color="danger" onClick={() => setToItemsSearch('')}><FontAwesomeIcon icon={faTimes}/></Button>
                            : <InputGroupText><FontAwesomeIcon icon={faSearch}/></InputGroupText>
                        }
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col style={{ height:"500px", padding:"10px", overflowY:"scroll", overflowX:"hidden" }}>
                    { 
                        fromItemsToShow.map((c) =>{
                            return(
                                <>
                                <Row className="my-1">
                                    <Col lg={10} className="d-flex flex-column justify-content-center">
                                        <h6> {c.Label} </h6>
                                    </Col>
                                    <Col lg={2} className="d-flex justify-content-end">
                                        <Button color="success" onClick={() => addItem(c)}><FontAwesomeIcon size="xl" icon={faCircleRight} /></Button>                                                
                                    </Col>
                                </Row>
                                <Row>
                                    <hr/>
                                </Row>
                                </>
                            )
                        })
                    }
                </Col>
                
                <Col style={{ height:"500px", padding:"10px", overflowY:"scroll", overflowX:"hidden" }}>
                    { 
                        toItemsToShow.map((c) =>{
                            return(
                                <>
                                    <Row className="my-1">
                                        <Col lg={10} className="d-flex flex-column justify-content-center">
                                            <h6> {c.Label} </h6>
                                        </Col>
                                        <Col lg={2} className="d-flex justify-content-end">
                                            <Button color="danger" onClick={() => removeItem(c)}><FontAwesomeIcon size="xl" icon={faXmark} /></Button>                                                
                                        </Col>
                                    </Row>
                                    <Row>
                                        <hr/>
                                    </Row>
                                </>
                            )
                        })
                    }
                </Col>
            </Row>               
        </Container>
    );
}
export type ListId = string | number;
export interface ListItem {
    Id: ListId;
    Label: string;
}