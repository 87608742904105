import { Range, RangeKeyDict } from "react-date-range"
import { formatToYMD, today } from "./dateHelper"
import i18next from "i18next"

export type PromotionPartialChange = {
    mediaTypeId: number | null,
    dateRange: StrDateRange | null
}

export type DateRange = {
    fromDate: Date,
    toDate: Date
}

export type StrDateRange = {
    fromDate: string,
    toDate: string
}

export function rangeToStr(dateRange: DateRange): StrDateRange {
    return {
        fromDate: formatToYMD(dateRange.fromDate),
        toDate: formatToYMD(dateRange.toDate)
    };
}

export function rangeParse(dateRange: StrDateRange): DateRange {
    return {
        fromDate: new Date(dateRange.fromDate),
        toDate: new Date(dateRange.toDate)
    };
}

export function dateRangeToRange(dateRange: DateRange | undefined): Range {
    if (dateRange === undefined){
        return {
            startDate: undefined,
            endDate: undefined,
            key: 'dateRange'
        };
    }
    return {
        startDate: dateRange.fromDate,
        endDate: dateRange.toDate,
        key: 'dateRange'
    }
}

export function strDateRangeToRange(dateRangeStr: StrDateRange | undefined): Range {
    if (dateRangeStr === undefined){
        return {
            startDate: undefined,
            endDate: undefined,
            key: 'dateRange'
        };
    }
    const dateRange = rangeParse(dateRangeStr);
    return {
        startDate: dateRange.fromDate,
        endDate: dateRange.toDate,
        key: 'dateRange'
    }
}

export function rangeKeyDictToDateRange(rangeKeyDict: RangeKeyDict): DateRange | undefined {
    const selectedDateRange = rangeKeyDict["dateRange"];
    if (selectedDateRange === undefined)
        return undefined
    const dateRange: DateRange = {
        fromDate: selectedDateRange.startDate!,
        toDate: selectedDateRange.endDate ?? selectedDateRange.startDate!
    }

    return dateRange;
}

export function rangeKeyDictToStrDateRange(rangeKeyDict: RangeKeyDict): StrDateRange | undefined {
    const selectedDateRange = rangeKeyDict["dateRange"];
    if (selectedDateRange === undefined)
        return undefined
    const dateRange: DateRange = {
        fromDate: selectedDateRange.startDate!,
        toDate: selectedDateRange.endDate ?? selectedDateRange.startDate!
    }

    return rangeToStr(dateRange)
}

const dateRange: DateRange = {
    fromDate: new Date(today.getFullYear(), 0),
    toDate: new Date(today.getFullYear(), 11, 31)
}

const thisMonthRange: DateRange = {
    fromDate: new Date(today.getFullYear(), today.getMonth(), 1),
    toDate: new Date(today.getFullYear(), today.getMonth() + 1, 0)
}

const dateRangeStr = rangeToStr(dateRange)

export function getDefaultTimelineRange(): DateRange {
    return dateRange;
}

export function getDefaultTimelineRangeStr(): StrDateRange {
    return dateRangeStr;
}

export function getDataRangeMonthDuration(dateRange: DateRange): number {
    const fromDateMonths = dateRange.fromDate.getMonth();
    const toDateMonths = (dateRange.toDate.getFullYear() - dateRange.fromDate.getFullYear()) * 12 + dateRange.toDate.getMonth() + 1;
    return toDateMonths - fromDateMonths;
}

export function getUserReadableDateRange(date: DateRange): string {
    let stringBuilder = [date.fromDate.toLocaleString(i18next.language, {month: "long", year:"numeric"})];
    if (date.fromDate < date.toDate)
        stringBuilder.push(` - ${date.toDate.toLocaleString(i18next.language, {month: "long", year:"numeric"})}`);
    
    return stringBuilder.join("");
}

export function getDateRangeThisMonth(): DateRange {
    return thisMonthRange;
}

export function dateRangeEquals(a: DateRange | undefined, b: DateRange | undefined): Boolean {
    if (a === undefined || b === undefined)
        return a === b;

    let isEqual = a.fromDate === b.fromDate;
    isEqual &&= a.toDate === b.toDate;

    return isEqual;
}

export function strDateRangeEquals(a: StrDateRange | undefined, b: StrDateRange | undefined): Boolean {
    if (a === undefined || b === undefined)
        return a === b;

    let isEqual = a.fromDate === b.fromDate;
    isEqual &&= a.toDate === b.toDate;

    return isEqual;
}
