import { EventContentArg } from "@fullcalendar/core";
import EventPopOver from "./EventPopOver";
import InnerContent from "./InnerContent";
import {TimelineMasterWithEvent} from "../../models/NTimelineModel";

export function EventContent(arg: EventContentArg) {

    const event = arg.event.extendedProps as TimelineMasterWithEvent;

    if (!event)
        return <>
            Error, no promotion
        </>

    const id = `Tooltip-promotion_${event.event.id}`;
    return <>
        <InnerContent id={id} event={event}/>
        <EventPopOver target={id} event={event}/>
    </>
}