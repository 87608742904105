import React, {useCallback, useMemo, useState } from "react";
import Select from 'react-select';
import {Input, InputGroup} from "reactstrap";
import {SelectOption } from "../../../helpers/SelectOption";

export default function IdItemSelection(
    {
        id,
        disabled = false,
        options,
        setSelection,
        onBlur
    }: {
        id: number | null,
        disabled ?: boolean,
        options: SelectOption[],
        setSelection: (code: number | null) => void,
        onBlur ?: (control: "Input" | "Select") => void
    }
){
    const selection = useMemo(() => {
        if (id === null || id === -1)
            return { value: -1, label: "Επιλογή..."}
        return options.find(m => m.value === id)
            ?? { value: -1, label: "Μη υπάρχων επιλογή..."}
    }, [id, options]);
    
    const handleProductIdChange = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        let processedValue: number | null 
        debugger;
        const result = Number(value);
        if (typeof value !== "string" || value.trim().length === 0) {
            processedValue = null;
        } else if (!isNaN(result)) {
            processedValue = result
        } else {
            processedValue = id 
        }
        setSelection(processedValue)
    }, [setSelection, id])
    
    const preProcessBlur = useCallback((item: "Input" | "Select") => {
        onBlur && onBlur(item)
    }, [id, onBlur]);
    
    const selectionField = disabled
    ? <Input className="w-75" type="text" name='itemDescription' value={selection.label ?? -1} disabled/>
    :
    <Select
            isClearable={false}
            className='react-select w-75'
            classNamePrefix='select'
            options={options}
            value={selection}
            onChange={(choice) => {
                setSelection(choice!.value)
            }}
            onBlur={_ => preProcessBlur("Select")}
        />;
    
    return <InputGroup>
        <Input className="w-25 text-align-end" type="text" name='itemId' onChange={handleProductIdChange} onBlur={_ => preProcessBlur("Input")} value={id ?? -1} disabled={disabled}/>
        {selectionField}
    </InputGroup>
}
