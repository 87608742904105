import React, {ChangeEventHandler, useState} from "react";
import {Button, Input, InputGroup} from "reactstrap";

export function PasswordField(
        {
            name,
            value,
            onChange
        }: {
            name: string,
            value: string,
            onChange: ChangeEventHandler<HTMLInputElement>
        }
) {
    const [isShown, setShown] = useState(false)

    const type = isShown ? "text" : "password";
    const icon = isShown ? "fa-eye-slash" : "fa-eye";
    return <InputGroup className="p-0">
        <Input type={type} name={name} value={value} onChange={onChange}/>
        <Button className={`fa ${icon}`} onMouseDown={() => setShown(true)} onMouseUp={() => setShown(false)} color="primary"/>
    </InputGroup>
}