import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { user } from '../dtos/authentication/user';

export interface AuthenticationState {
  isAuthenticated: boolean
  user: user | null
}

const initialState: AuthenticationState = {
  isAuthenticated: false,
  user: null
}

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    login: (state, action: PayloadAction<user>) => {
      state.isAuthenticated = true
      state.user = action.payload
    },
    logout: (state) => {
      state.isAuthenticated = false
      state.user = null
    },
  },
});

export const { login, logout } = authenticationSlice.actions;

export const selectAuthentication = (state: RootState) => state.authentication

export default authenticationSlice.reducer;
