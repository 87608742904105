import i18next from "i18next"
export interface Customer {
    id: number
    vendorCode: string
    description: string
    shortDescription: string
    payerOfAccountCode: string
    order: number
    isDeleted: boolean
}

export interface NewCustomer{
    vendorCode?: string
    description?: string
    shortDescription?: string
    payerOfAccountCode?: string
}


export interface FromToOrder{
    fromId?: number
    toOrder?: number
}

export function validateCustomer(values: Customer | NewCustomer): Partial<Customer>  {
    const errors: Partial<Customer> = {};
        
    if (!values.description) {
      errors.description = i18next.t('description', { ns: 'customers' });
    }
  
    if (!values.shortDescription) {
      errors.shortDescription = i18next.t('short_description', { ns: 'customers' });
    }
  
    if (!values.vendorCode) {
      errors.vendorCode = i18next.t('vendor_code', { ns: 'customers' });
    } else if (values.vendorCode.length !== 8) {
        errors.vendorCode = i18next.t('vendor_code_length', { ns: 'customers' });
    } else if (values.vendorCode.charAt(0) !== "5") {
        errors.vendorCode = i18next.t('vendor_code_format', { ns: 'customers' });
    }
  
    if (!values.payerOfAccountCode) {
      errors.payerOfAccountCode = i18next.t('payer_of_account_code', { ns: 'customers' });
    } else if (values.payerOfAccountCode.length !== 10) {
        errors.payerOfAccountCode = i18next.t('payer_of_account_code_length', { ns: 'customers' });
    } else if (values.payerOfAccountCode.charAt(0) !== "3") {
        errors.payerOfAccountCode = i18next.t('payer_of_account_code_format', { ns: 'customers' });
    }      
    return errors;
}

