import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap'
import "./customersStyle.css"
import {  NewCustomer, validateCustomer } from '../../backoffice/dtos/customer/customer'
import { useAddCustomerMutation } from '../../backoffice/apis/customerAPI'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const CustomerSearch = ({
    searchTerm,
    setSearchTerm
}:{
    searchTerm: string,
    setSearchTerm: (value: string) => void
}) => {

    const {t} = useTranslation(['customers', 'common'])
    const [debouncedTerm, setDebouncedTerm] = useState<string>(searchTerm)
    const [customerForm,setCustomerForm] = useState<NewCustomer>({description:"",payerOfAccountCode:"",shortDescription:"",vendorCode:""})
    const [formErrors,setFormErrors] = useState<Partial<NewCustomer>>({})
    const [isOpen, setIsOpen] = useState(false)   

    const [addCustomer] = useAddCustomerMutation()

    const createCustomer = async () =>{
        try {
            await addCustomer(customerForm)
            toast(t('customer_created_alert'))
        } catch (error: any) {
            toast(error)
        }
    }

    useEffect(() => {
      const timer = setTimeout(() => setSearchTerm(debouncedTerm), 800);
      return () => clearTimeout(timer);
    }, [debouncedTerm])

    const handleSubmit = async () => {      
        const errors = validateCustomer(customerForm)
        setFormErrors(errors);
        
        if (Object.entries(errors).length === 0) {
          await createCustomer();
          toggle();
        }
      };
      

    const toggle = () => {
        setIsOpen(!isOpen)      
        setCustomerForm({})
        setFormErrors({})
      }  

  return (
    <Container  className='d-flex justify-content-center'>
        <Row className='mt-4 w-100 p-0 gy-1'>
            <Col className='p-0 d-flex flex-column justify-content-center'>
                <Form>
                    <InputGroup>
                        <Input type='text' placeholder= {t('search',{ns:'common'})} onChange={(e) => setDebouncedTerm(e.target.value)} />
                        <Button>                    
                            <span className="fa fa-search bg- fa-lg" ></span>
                        </Button>
                    </InputGroup>  
                </Form>
            </Col>
            <Col md={1}/>
            <Col md={2} className="p-0">
                <Button color='primary w-100' onClick={toggle}>
                   {t('add_customer_button_text')} <span className='fa fa-lg fa-plus' />
                </Button>
            </Col>
        </Row>
        {/* Creation Form for new Customer */}
        <React.Suspense fallback={<Spinner/>}>
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle} >{t('add_customer_title')}</ModalHeader>
            <ModalBody>
                <Label>{t('brand_name')}</Label>
                <Input onChange={(e) => setCustomerForm({...customerForm,description:e.target.value})} />
                    <p style={{color:'red'}}>{formErrors.description}</p>
                <Label>{t('short_brand_name')}</Label>
                <Input onChange={(e) => setCustomerForm({...customerForm,shortDescription:e.target.value})} />
                <p style={{color:'red'}}>{formErrors.shortDescription}</p>
                <Label>Payer Code</Label>
                <Input onChange={(e) => setCustomerForm({...customerForm,payerOfAccountCode:e.target.value})} />
                <p style={{color:'red'}}>{formErrors.payerOfAccountCode}</p>
                <Label>Vendor Code</Label>
                <Input onChange={(e) => setCustomerForm({...customerForm,vendorCode:e.target.value})} />
                <p style={{color:'red'}}>{formErrors.vendorCode   }</p>

            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={handleSubmit} >
                    {t('create',{ns:'common'})}
                </Button>                
            </ModalFooter>
        </Modal>
        </React.Suspense>
    </Container>
  )
}

export default CustomerSearch