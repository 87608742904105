import { Product,PageableProducts } from "../dtos/promotion/product";
import { baseApi } from "./baseAPI";
import { PageCriteria,CountPages} from "../dtos/promotion/pagesCriteria";
import { ShortDescription } from "../dtos/promotion/shortDescription";

export const descriptionsApi = baseApi.injectEndpoints({    
    endpoints: (build) => ({
        getShortDescriptions: build.query<PageableProducts, PageCriteria>({
            query: (criteria: PageCriteria) =>{
                let queryString = "Products/ShortDesc"
                let params: string[] = []
                if (criteria.limit)
                    params.push(`limit=${criteria.limit}`)
                if (criteria.page)
                    params.push(`page=${criteria.page}`)
                if (criteria.query)
                    params.push(`query=${criteria.query}`)
                if (params.length > 0)
                    queryString = `${queryString}?${params.join("&")}`
                return queryString
            },
            providesTags:() => [{type: 'ShortDescription', value: "LIST"}],
        }),
        getPagesCount: build.query<number, CountPages>({
            query: (criteria: CountPages) =>{
                let queryString = 'Products/GetPagesCount'
                let params: string[] = []
                if (criteria.limit)
                    params.push(`limit=${criteria.limit}`)
                if (criteria.query)
                    params.push(`query=${criteria.query}`)                
                    queryString = `${queryString}?${params.join("&")}`                
                return queryString
            }
        }),      
        addShortDescription: build.mutation<ShortDescription,Product>({
            query: (body) => ({
                url: `Products/ShortDesc`,
                method: 'POST',
                body,
            }),            
            transformErrorResponse: (response) => response.data,
            invalidatesTags: (isUpdated) => [{type: 'ShortDescription', value:'LIST'}]
        }),

        
    }),
    overrideExisting: false,
})

export const {
    useGetShortDescriptionsQuery,useGetPagesCountQuery,useAddShortDescriptionMutation
} = descriptionsApi