export function addBooleans(...params: boolean[]) {
    let activeFlags = 0;
    for(const b of params) {
        activeFlags += b ? 1 : 0;
    }
    return activeFlags;
}

export function normalizedNumber(num: number, decimalPlaces: number = 2){
    return num.toLocaleString('el-GR', {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
    });
}

export function download(blob: Blob, name: string) {
    const a = document.createElement("a");
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
}

export function handleUrl(url: string, search: string | URLSearchParams | undefined) {
    let params: string | null = null;
    switch (typeof search){
        case "string":
            params = search;
            break;
        case "object":
            params = search.toString();
            break;
    }
    
    let uri = url;
    if (params && params.length > 0)
        uri += `?${params}`;
    
    return uri;
}