import React, { useState } from 'react'
import { Button, Col, Container, ListGroup, ListGroupItem, Row, Spinner } from 'reactstrap'
import UserEditModal from './UserEditModal'
import { useGetAllRolesQuery, useGetAllUsersQuery } from '../../backoffice/apis/userApi'
import { RolesList } from '../../backoffice/dtos/authentication/role'
import { useTranslation } from 'react-i18next'
import { user } from '../../backoffice/dtos/authentication/user'

const Users = () => {
  const [seletctedUser, setSelectedUser] = useState<user>()
  const [isOpen, setIsOpen] = useState(false)
  const {data: users} = useGetAllUsersQuery()
  const {data: roles} = useGetAllRolesQuery()
 
  const {t} = useTranslation(['user_info','common'])

  
  const toggle = (user: user) => {
    setIsOpen(!isOpen)
    setSelectedUser(user)     
  }

  
  return (
    <Container >
      <Container >
        <ListGroup className='mt-4'>
          <ListGroupItem >
            <Row className='d-flex justify-content-center'>              
              <Col lg={2}><h3> {t('username')} </h3></Col>
              <Col lg={3}><h3> E-mail </h3> </Col>
              <Col lg={2}><h3> {t('roles')} </h3> </Col>
              <Col lg={2}><h3> {t('hierarchy_roles')} </h3> </Col>
              <Col lg={2}></Col>
            </Row>
          </ListGroupItem>
          {users ? users.map((user, index) => (
            <ListGroupItem key={index} >
              <Row className='d-flex justify-content-center ' divider>                
                <Col lg={2} className='bold-text'>{user.userName}</Col>
                <Col lg={3} >{user.email}</Col>                
                <Col className='text-truncate' lg={2}>{ user.roles.map((role)=> role.name).join(", ") }</Col>
                <Col className='text-truncate' lg={2}>{ user.hierarchyRoles.map((role)=> role.name).join(", ") }</Col>
                <Col lg={2} className='d-flex justify-content-end'> 
                    <Button color="primary " className='w-40 ' onClick={() => toggle(user)} >
                      <i className='fa fa-edit fa-lg'></i>
                    </Button>
                </Col>
              </Row>
            </ListGroupItem>
          ))
          : <Spinner></Spinner>
        }
        </ListGroup>
      </Container>      
      <UserEditModal isOpen={isOpen} toggle={() => setIsOpen(false)} selectedUser={seletctedUser as user} setSelectedUser={setSelectedUser} roles={roles as RolesList}/>
    </Container>
  )
}

export default Users