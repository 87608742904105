// PrivateRoute.tsx in v6
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { getUser } from '../../helpers/localStorage';
import { AllowedRoles, CustomerEditors, ProductEditors, getUserHasRole } from '../../backoffice/dtos/authentication/role';
import { Props } from 'react-select';
import { user } from '../../backoffice/dtos/authentication/user';
import { Promotion } from '../promotions/views/Promotion';


export const ProtectedRoute = ( {user}:{user: user | undefined} ) => {  
  const {pathname, search} = useLocation()
  if (user) {
    return <>
      <Outlet />
      <Promotion/>
    </>;
  } else {
    return <Navigate to={{
      pathname: "/login",
      search: `redirect=${pathname}${search}`
    }} />;
  }
};

export const AuthenticatedRoute = (
  {user}:{user: user | undefined}
) => {  
  if (user) {
    const url = new URL(window.location.href)
    const redirect = url.searchParams.get("redirect") ?? "/dashboard"
    return <Navigate to={redirect}/>
  } else {
    return <Outlet />;
  }
};

export const AuthenticatedRouteIsAdmin = ( ) => {  
  const user = getUser()

  if (user){
    return  AllowedRoles.some(u => getUserHasRole(user, u.name)) ? <Outlet/> : <Navigate to={"/dashboard"} />;
  }

  return <Navigate to={"/login"} />
};

export const AuthenticatedProductEditor = ( ) => {  
  const user = getUser()
  
  if (user)
    return  ProductEditors.some(u => getUserHasRole(user, u.name)) ? <Outlet/> : <Navigate to={"/dashboard"} />;
  
  return <Navigate to={"/login"} />
};

export const AuthenticatedCustomerEditor = ( ) => {  
  const user = getUser()

  if (user)    
    return  !CustomerEditors.some(u => getUserHasRole(user, u.name))  ? <Navigate to={"/dashboard"} /> : <Navigate to={"/dashboard"} />;
  
  return <Navigate to={"/login"} />
};


// "pendig": "Εκκρεμότητες",
// "promotion_timeline": "Χρονολόγιο Προωθητικών Ενεργειών",
// "user_info": "Πληροφορίες Χρήστη",
// "users": "Χρήστες",
// "customers":"Πελάτες",
// "products":"Προϊόντα",
// "log_out": "Αποσύνδεση",
// "connection_error": "Πρόβλημα επικοινωνίας",
// "username": "Όνομα Χρήστη",
// "password": "Κωδικός Πρόσβασης",
// "login_button_text": "Είσοδος",
// "reset_button_text": "Επαναφορά"