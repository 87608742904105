import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { auto } from "@popperjs/core";
import { useMemo } from "react";
import { Col, Row } from "reactstrap";

interface TableHeader extends React.HTMLAttributes<HTMLElement>{
    __TYPE ?: string;
}

export function TableHeader(props: TableHeader){
    if (props.__TYPE !== "__TPM__TableHeader__")
        throw new Error("Please do not alter the __TYPE prop" )

    return (
        <Row className={["cursor-pointer", "unselectable", "align-items-center", "p-2", "rounded", "light-shadow", (props.className ?? "")].join(" ")} onClick={props.onClick}>
            <Col className="invisible" sm={auto}><FontAwesomeIcon icon={faChevronDown}/></Col>
            <>
                { props.children }
            </>
        </Row>
    )
}

(TableHeader as any).defaultProps = {
    __TYPE: "__TPM__TableHeader__"
}