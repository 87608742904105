import { SelectKey, SelectOption, SelectOptionAlt } from "../../../helpers/SelectOption";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FormGroup, Input, Label, Spinner } from "reactstrap";

export function SelectionList(
    {
        items, error, isFetching, onChange, selection: initialSelection
    }:{
    items: SelectOptionAlt[] | undefined, 
    error: string | undefined, 
    isFetching: boolean,
    onChange: (option: SelectOptionAlt[] | undefined) => void,
    selection: SelectKey[] | undefined
}) {
    const optionsMap = useMemo(() => {
        if (!items)
            return new Map<SelectKey, SelectOptionAlt>()
        return new Map<SelectKey, SelectOptionAlt>(items.map(m => [m.value, m]));
    }, [items]);

    const selectedOptions = useMemo(() => new Set<SelectKey>(initialSelection), [initialSelection])

    const [filter, setFilter] = useState<string>()

    const filteredOptions = useMemo(() => {
        if (!items)
            return undefined

        return items.filter(
            m => filter ? m.label.includes(filter) : true
        )
    }, [items, filter])

    const innerOnChange = useCallback((selectOption: SelectOptionAlt, checked: boolean) => {
        let selectedOptionsSet = selectedOptions ? new Set<SelectKey>(selectedOptions as any | undefined): new Set<SelectKey>() ;
        let changed = false;

        if(!checked && selectedOptionsSet.has(selectOption.value)){
            changed = true;
            selectedOptionsSet.delete(selectOption.value);
        } 
        
        if(checked && !selectedOptionsSet.has(selectOption.value)){
            changed = true;
            selectedOptionsSet.add(selectOption.value);
        }

        if (changed) {
            if (selectedOptionsSet.size === 0) {
                onChange(undefined)
            } else {
                const options = [...selectedOptionsSet].map(m => optionsMap.get(m)).filter(m => m)
                onChange(options as SelectOptionAlt[])
            }
        }
    }, [onChange, selectedOptions]);

    const setFilterMediator = useCallback((item: ChangeEvent<HTMLInputElement>) => {
        if (item.target.value.length === 0) {
            setFilter(undefined);
            return;
        }
        setFilter(item.target.value);
    }, []);

    if (error) {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: "50px" }}>
                <FontAwesomeIcon icon={faCircleXmark} size="3x"/>
                <h3>{error}</h3>
            </div>
        )
    } else if (isFetching || !filteredOptions) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "50px" }}>
                <Spinner />
            </div>
        )
    } else {
        return (
            <>
                <div>
                    <Input type="text" onChange={setFilterMediator}/>
                </div>
                <div style={{
                    maxHeight:500,
                    overflow:"scroll"
                }}>
                    {
                    filteredOptions.map((m) => (
                        <FormGroup className="m-1" key={m.value}>
                            <Input 
                                type="checkbox" 
                                value={m.value} 
                                onChange={(event) => innerOnChange(m, event.target.checked)} 
                                checked={selectedOptions?.has(m.value)}
                            />{" "}

                            <Label className="m-0">
                                    {m.label}
                            </Label>
                        </FormGroup>
                    ))
                    }
                </div>
            </>
        )
    }
}
