import React,{useState} from 'react'
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Label, Row, Spinner } from 'reactstrap'
import { useNavigate } from 'react-router'
import { ResetPasswordRequestDto } from '../../backoffice/dtos/authentication/ChangePasswordDto'
import { toast } from 'react-toastify'
import { resetPasswordRequest } from '../../backoffice/apis/axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { supportedLanguages } from '../../i18n/i18nProps'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import ReactCountryFlag from 'react-country-flag'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const ResetPasswordRequest = () => {
        const [formData, setFormData] = useState<ResetPasswordRequestDto>({})
        const [formError,setFormError] = useState<string>("")
        const [isLoading,setLoader] = useState<boolean>(false)
        const [globeOpen, setGlobeOpen] = useState(false);
        const {t} = useTranslation(['login'])
        const navigate = useNavigate()
   
        const handleSubmit = async (e:any) =>{
            e.preventDefault();
            if(!formData?.username || formData.username.length === 0){
                setFormError(t('no_username_error'));
                return;
            }
            setLoader(true);
            setFormError("");
            try {
                await resetPasswordRequest(formData);
                toast(t('successful_reset_alert'));
                navigate("/login")
            } catch (error:any) {
                toast(error.response.data);
            } finally {
                setLoader(false);
            }
        }
  return (
    <div className='bg-login-lvl-3 d-flex vh-100 align-items-center justify-content-center'>
            <div >
              <div className='button-logout ms-auto d-block'>
                  <Dropdown nav className='list-unstyled' isOpen={globeOpen} toggle={() => setGlobeOpen(!globeOpen)}>
                      <DropdownToggle>
                          <FontAwesomeIcon icon={faGlobe} />
                      </DropdownToggle>
                      <DropdownMenu>
                          {supportedLanguages.map((item, index) => {
                              return (
                                  <DropdownItem key={index} className={i18next.language === item.code ? 'bold' : ''} onClick={() => i18next.changeLanguage(item.code)}>
                                      <ReactCountryFlag
                                          className="emojiFlag"
                                          countryCode={item.countryCode}
                                          style={{
                                              fontSize: '1.2em',
                                              lineHeight: '1.2em',
                                          }}
                                          aria-label="United States"
                                      />
                                      <span className='mx-2'>{item.name}</span>
                                  </DropdownItem>
                              )
                          })}
                      </DropdownMenu>
                  </Dropdown>
              </div>
            </div>
                <div className='bg-lvl-3 rounded-3 shadow-sm p-3' >
                <Form onSubmit={handleSubmit}>
                    <img src='../../images/logo.jpg' className='img-fluid' alt='Logo Αθηναϊκής Ζυθοποιίας'></img>
                    <div className='m-3 text-align-start'>
                        <Label for="username">{t('username')}</Label>
                        <Input type="text" name='username' onChange={(e) => setFormData(prev => ({...prev, username: e.target.value}))} />
                        { formError.length > 0 && <p style={{color:"red"}}>{formError}</p>}
                        <br/>

                        <Row className='gy-3'>
                            <Col sm={5}>
                                <Button className='w-100' outline onClick={() => navigate("/login")}>{t('return')}</Button>
                            </Col>
                            <Col sm={7}>
                                <Button className="w-100" onClick={handleSubmit} color="primary" disabled={isLoading}>
                                    { isLoading && <Spinner size="sm"/>} {t('reset_button_text')}
                                </Button>   
                            </Col>
                        </Row>
                    </div>
                    
                </Form>
            </div>
    </div>

  )
}

export default ResetPasswordRequest