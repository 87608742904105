import { DateRangePicker, Range, RangeKeyDict } from "react-date-range";
import { DateRange, dateRangeToRange, rangeKeyDictToDateRange} from "../../../helpers/Types";
import { useMemo, useCallback } from "react"
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

export default function CloneDate({
    dateRange,
    setDateRange
}:{
    dateRange: DateRange | undefined
    setDateRange: (dateRange: DateRange | undefined) => void
}) {

    const range: Range = useMemo(() => {
        return dateRangeToRange(dateRange)
    }, [dateRange]);

    const setDateRangeChange = useCallback((item: RangeKeyDict) => {
        const date = rangeKeyDictToDateRange(item);

        if (date) {
            setDateRange(date);
        }
    }, []);

    return (
        <div className='d-flex justify-content-center mt-1 mb-1'>
            <DateRangePicker
                onChange={setDateRangeChange} 
                ranges={[range]}
            />
            {dateRange !== undefined &&
                <div>
                    <Button color="link" onClick={() => setDateRange(undefined)}>
                        <FontAwesomeIcon icon={faX} color="red"/>
                    </Button>
                </div>
            }
        </div>
    );

}
