import './User.css'

import {Button, Col, Container, Label, Row} from "reactstrap"
import { ERole, RoleDescription } from '../../enums/ERole';
import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo, useState, useTransition } from 'react';
import { getUser } from '../../helpers/localStorage';
import { useTranslation } from 'react-i18next';
import { auto } from '@popperjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';

export default function User() {

    const user = getUser()
    const {t} = useTranslation(['user_info'])
    const navigate = useNavigate();

    const onPasswordEditClick = useCallback(()=>{
        navigate("/changePassword")
    }, []);

    const userRoles = useMemo(() => {
        if (!user || !user.roles || user.roles.length === 0)
            return ""

        return user.roles.map(m => m.name).reduce((acc, m) => `${acc}, ${m}`);
    }, [user])

    const acceptFa = useMemo(() => {
        return <FontAwesomeIcon icon={faCheckSquare} color="green"/>
    }, [])

    const declineFa = useMemo(() => {
        return <FontAwesomeIcon icon={faSquare} color="red"/>
    }, [])

    const userHierarchyRoles = useMemo(() => {
        if (!user || !user.hierarchyRoles)
            return "";

        return user.hierarchyRoles
            .map(m => ({name: m.name, update: m.informViaEmail, primary: m.isPrimary}))
            .map(m => (
                <>
                    <Row className='p-0 align-items-center'>
                        <Col md={0}>{m.name}</Col>
                        <Col md={3} className="d-flex justify-content-center">
                            {m.primary? acceptFa : declineFa}
                        </Col>
                        <Col md={2} className="d-flex justify-content-center">
                            {m.update ? acceptFa : declineFa}
                        </Col>
                    </Row>
                </>
            ));
    }, [user])

    return (
        <>
            <div className="p-3"/>
            <div className="p-3">
                <Container className="bg-lvl-3 shadow rounded-4 p-4 gy-2" style={{maxWidth:"900px"}}>
                    <Row tag="h3" className='text-align-start mx-3 header-opacity'>
                       {t('basic_info')}
                    </Row>
                    <br/>
                    <Row className='my-1 m-3'>
                        <Col sm={3}  className="text-align-start light-text bold">
                            <Label for="userName"> {t('username')}</Label>
                        </Col>
                        <Col className="text-align-start">
                            <Label>{user?.userName}</Label>
                        </Col>
                    </Row>
                    <Row className='m-2' style={{background: "#0006", height:"1px"}}/>
                    <Row className='my-1 m-3'>
                        <Col sm={3} className="text-align-start">
                            <Label className='bold light-text' for="description"> {t('roles')}</Label>
                        </Col>
                        <Col className="text-align-start">
                            <span>{userRoles}</span>
                        </Col>
                    </Row>
                    <Row className='my-1 m-3'>
                        <Col sm={3} className="text-align-start">
                            <Label className='bold light-text my-0' for="description">{t('hierarchy_roles')}</Label>
                        </Col>
                        <Col className="text-align-start align-content-center text">
                            <Row className='mb-2'>
                                <Col md={0} className="bold">{t('role')}</Col>
                                <Col md={3} className="bold d-flex justify-content-center">{t('is_primary')}</Col>
                                <Col md={2} className="bold d-flex justify-content-center">{t('inform_via_email')}</Col>
                            </Row>

                            {
                                userHierarchyRoles
                            }
                        </Col>
                        {
                      //<Col md={auto}>
                      //    <Button className='px-3 d-flex flex-column p-2' color='primary' onClick={undefined} style={{width:50}}>
                      //        <span className='fa fa-pencil'/>
                      //    </Button>
                      //</Col>
                        }
                    </Row>
                    <Row className='m-2' style={{background: "#0006", height:"1px"}}/>
                    <Row className='my-1 m-3'>
                        <Col md={3} className="text-align-start bold light-text">
                            <Label for="description">{t('user_password')}</Label>
                        </Col>
                        <Col md={0} className="text-align-start">
                            <Label>***************</Label>
                        </Col>
                        <Col md={auto}>
                            <Button className='w-100 d-flex flex-column px-3' color='primary' onClick={onPasswordEditClick} style={{width:50}}>
                                <span className='fa fa-pencil'/>
                            </Button>
                        </Col>
                    </Row>
                    <Row className='m-2' style={{background: "#0006", height:"1px"}}/>
                    <Row className='my-1 m-3'>
                        <Col sm={3} className="text-align-start bold light-text">
                            <Label for="description">E-Mail</Label>
                        </Col>
                        <Col className="text-align-start">
                            <Label>{user?.email}</Label>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )

}
