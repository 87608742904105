export function StatisticsEntry(
        {
            columns
        }: {
            columns: ColumnEntry[]
        }
) {
    return <tr>
        {columns.map(m => <td className={m.className ?? ""}>{m.value.toLocaleString()}</td>)}
    </tr>
}

export type ColumnEntry = {
    value: any
    className ?: string
}