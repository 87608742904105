import { user } from "./user";

export interface RolesList {
    Roles: Role[];
    HierarchyRoles: Role[];
}

export interface Role{
    level: number;
    name: string;
    informViaEmail: boolean | undefined;
    isPrimary: boolean | undefined;
}

export const AllowedRoles: Role[] = [{ level: 999, name: "Admin", informViaEmail: undefined, isPrimary: undefined }]

export const ProductEditors: Role[] = [{ level: 999, name: "Admin", informViaEmail: undefined, isPrimary: undefined },{ level: 160, name: "ProductEditor", informViaEmail: undefined, isPrimary: undefined }]

export const CustomerEditors: Role[] = [{ level: 999, name: "Admin", informViaEmail: undefined, isPrimary: undefined },{ level: 170, name: "CustomerEditor", informViaEmail: undefined, isPrimary: undefined }]

export function getUserHasRole(user: user, role: string): boolean {
    if(user.roles === undefined)
        return false;

    return user.roles.some(userRole => userRole.name == role)
}

export function getUserHasHierarchyRole(user: user, role: number | null): boolean {
    
    if(user.hierarchyRoles === undefined)
        return false;

  //if (role == null)
  //    return false;

    switch(typeof(user.hierarchyRoles)) {
        case "number":
            return user.hierarchyRoles === role
        default:
            return user.hierarchyRoles.some(userRole => userRole.level == role)
    }
}
