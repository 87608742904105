import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function ErrorComponent({
    error
}: {
    error: string
}) {
    return (
            <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
                <FontAwesomeIcon icon={faExclamationCircle} size="5x" color="red"/>
                <div>
                    <strong>{error}</strong>
                </div>
            </div>
    );
}

