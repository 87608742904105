import  i18next from "i18next";
export interface ChangePasswordDto {
    existingPassword: string;
    newPassword: string;
    repeatNewPassword: string;
}

export interface ResetPasswordRequestDto {
    username?: string;
}

export interface ResetPasswordDto{
    token?: string;
    username: string;
    password: string;
    repeatPassword: string;
}

export function changePasswordValidation(changePasswordDto: ChangePasswordDto): string | null {
    if (changePasswordDto.newPassword.length <= 8)        
        return i18next.t('short_password_error', { ns: 'user_info' });

    if (changePasswordDto.newPassword === changePasswordDto.existingPassword)
        return i18next.t('existing_password_error', { ns: 'user_info' });
        
        if (changePasswordDto.newPassword !== changePasswordDto.repeatNewPassword)
       
        return i18next.t('different_password_error',{ ns: 'user_info' });

    if (!/(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z])/.test(changePasswordDto.newPassword))
        
        return i18next.t('wrong_format_password_error', { ns: 'user_info' })

    return null;
}
export function resetPasswordValidation(resetPasswordDto: ResetPasswordDto): string | null {
    if (resetPasswordDto.username.length === 0)
        return i18next.t('input_username_error', { ns: 'user_info' });
    if (!resetPasswordDto.password || !resetPasswordDto.repeatPassword)
        return i18next.t('input_password_error', { ns: 'user_info' });
    if (resetPasswordDto.password.length <= 8)
        return i18next.t('short_password_error', { ns: 'user_info' });   

    if (resetPasswordDto.password !== resetPasswordDto.repeatPassword)
        return i18next.t('different_password_error',{ ns: 'user_info' });
    if (!/(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z])/.test(resetPasswordDto.repeatPassword))
        return i18next.t('wrong_format_password_error', { ns: 'user_info' })

    return null;
}
