import { MediaType } from "../dtos/media/MediaType"
import { baseApi } from "./baseAPI"

export const mediaApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getMediaTypes: build.query<MediaType[], void>({
            query: () => 'MediaTypes',
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetMediaTypesQuery
} = mediaApi