import { useTranslation } from "react-i18next"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
export function PromotionColorInfo(
args : {
    isOpen: boolean,
    toggle: () => void
}) {
    const { t } = useTranslation('common');
    return (
        <Modal isOpen={args.isOpen} toggle={args.toggle}>
            <ModalHeader toggle={args.toggle}>
                {t('promotion_colors', {ns:'promotion'})}           
            </ModalHeader>
            <ModalBody>
                <Color color="--bg-promotion-deleted" text={t('deleted')}/>
                <Color color="--bg-promotion-rejected" text={t('rejected')}/>
                <Color color="--bg-promotion-draft" text={t('draft')}/>
                <Color color="--bg-promotion-pending-approval" text={t('pending_approval')}/>
                <Color color="--bg-promotion-approved" text={t('approved')}/>
                <Color color="--bg-promotion-po-submitted" text={t('po_submitted')}/>
                <Color color="--bg-promotion-po-finalized" text={t('po_finalized')}/>
                <Color color="--bg-promotion-completed" text={t('completed')}/>
            </ModalBody>
        </Modal>
    );
}

function Color(
    args : {
        color: string,
        text: string
    }
) {
    return (
        <div className="d-flex m-1">
            <div style={{height: "24px", width: "24px", background:`var(${args.color})`}}/>
            <div className="text-align-center">
                {'\u00A0'}{args.text}
            </div>
        </div>
    );
}
