import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { stat } from 'fs';

export interface ProductIdConsumerState {
  value: number | undefined;
  status: boolean;
}

const initialState: ProductIdConsumerState = {
  value: undefined,
  status: false,
};

export const producerConsumerSlice = createSlice({
  name: 'productConsumer',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clear: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      return {
        ...state, value: undefined
      };
    },
    assign: (state, consumer: PayloadAction<number>) => {
        return {
            ...state, value: consumer.payload
        };
    },
    allow: (state) => {
        return {
            ...state, status: true
        };
    },
    disallow: (state) => {
        return {
            ...state, status: false
        }
    }
  }
})
export const { clear, assign, allow, disallow } = producerConsumerSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectProductReduce = (state: RootState) => state.product;

export default producerConsumerSlice.reducer;