import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { promotionApi } from '../apis/promotionAPI';
import { Promotion } from '../dtos/product/promotion';
import { PromotionCriteria } from '../dtos/product/promotionCriteria';

export interface PromotionsState {
    promotionsList: Promotion[];
    status: 'idle' | 'loading' | 'failed';
    error: string
}

const initialState: PromotionsState = {
    promotionsList: [],
    status: 'idle',
    error: ''
};

//
// Not used - Prefer useGetPromotionsQuery (RTK query API)
//
export const fetchPromotionsAsync = createAsyncThunk(
    'promotions/fetchPromotions',
    async (criteria: PromotionCriteria, thunkAPI) => {
        const result = await fetch(`${process.env.REACT_APP_BASE_URL}Promotion?customerId=${criteria.customerIds}`);
        const promotions: Promotion[] = await result.json();
        return promotions;
    }
);

const slice = createSlice({
    name: 'promotions',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder.addCase(fetchPromotionsAsync.fulfilled,
            (state, { payload }) => {
                // Add user to the state array
                if (payload)
                    state.promotionsList = payload
                else
                    state.promotionsList = []
            })
            .addCase(fetchPromotionsAsync.rejected,
                (state, action) => {
                    state.error = action.error.message ?? "Αποτυχία"
                })
            .addMatcher(
                promotionApi.endpoints.getPromotions.matchFulfilled,
                (state, { payload }) => {
                    state.promotionsList = payload
                })
    }
})

export default slice.reducer

export const selectPromotions = (state: RootState) => state.promotions
