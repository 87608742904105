import React, { useCallback, useState } from 'react';
import { useNavigate } from "react-router-dom";
import {
    Navbar,
    Nav,
    NavbarToggler,
    Collapse,
    NavItem,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,   
    Button,
    Row,
    Col,
    Container,
    NavbarBrand,
    Offcanvas,
    OffcanvasBody
} from 'reactstrap';


import { NavLink } from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faGlobe, faPeopleGroup, faStore } from '@fortawesome/free-solid-svg-icons';
import { clearLocalStorage, getUser } from './helpers/localStorage';
import { user, userLogout } from './backoffice/dtos/authentication/user';
import PromotionFinder from './features/promotion_finder/PromotionFinder';
import { AllowedRoles, CustomerEditors, ProductEditors, getUserHasRole } from './backoffice/dtos/authentication/role';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import ReactCountryFlag from 'react-country-flag';
import { supportedLanguages } from './i18n/i18nProps';
import { logout } from './backoffice/apis/axios';
import { toast } from 'react-toastify';


export default function PhoneHeader() {
    const [isNavOpen, setIsNavOpen] = useState(false)
    const user = getUser()

    const { t } = useTranslation(['header','common'])
    
    function toggleNav() {
        setIsNavOpen(!isNavOpen)
    }

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [globeOpen, setGlobeOpen] = useState(false);
    let navigate = useNavigate();

    const toggle = () => setDropdownOpen(!dropdownOpen);
  
    const logoutHandler = useCallback(() => {
        userLogout()
    }, [])

    if (!user)
        return <></>;

    const dashboardLink = <NavItem>
            <NavLink className="nav-link" to='/dashboard'><span className="fa fa-home fa-lg"></span> {t('pending')} </NavLink>
        </NavItem>

    const promotionsLink = <NavItem>
            <NavLink className="nav-link" to='/promotions'><span className="fa fa-list fa-lg"></span> {t('promotion_timeline')}</NavLink>
        </NavItem>    


    const userLink = (
    <Dropdown  nav isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle nav caret>
                    {user.userName}
            </DropdownToggle>
            <NavItem onClick={() => navigate('user')}>
                <span className="fa fa-info fa-lg dropdown-icon"></span> {t('user_info')}
            </NavItem>
            <NavItem divider/>  
            
                {user && !AllowedRoles.some(r => getUserHasRole(user, r.name)) ? <></> :<>  
                <NavItem onClick={() => navigate('users')}>
                    <span className="fa fa-lg dropdown-icon"><FontAwesomeIcon icon={faPeopleGroup} /></span> {t('users')}
                </NavItem>
                <NavItem divider/>
                </>
            }

            { user && !CustomerEditors.some(r => getUserHasRole(user, r.name)) ? <></> :<>
                <NavItem onClick={() => navigate('customers')}>
                <span className='fa-lg fa dropdown-icon'><FontAwesomeIcon icon={faStore} /></span> {t('customers')}                
                </NavItem>
                <NavItem divider/>
                </>
            }
            { user && !ProductEditors.some(r => getUserHasRole(user, r.name)) ? <></> :<>
                <NavItem onClick={() => navigate('products')}>
                    <span className="fa fa-pencil-square fa-lg dropdown-icon"/> {t('products')}
                </NavItem>
                <NavItem divider/>
                </>
            }
            <NavItem className="ms-auto" onClick={logoutHandler}>
                <span className="fa fa-sign-out fa-lg dropdown-icon"></span> {t('log_out')}
            </NavItem>
        </Dropdown>
    )

    const languageOption = (
        <Dropdown nav isOpen={globeOpen} toggle={() =>setGlobeOpen(!globeOpen)}>
            <DropdownToggle nav >
                <FontAwesomeIcon icon={faGlobe} />
            </DropdownToggle>
            <DropdownMenu nav>
                {supportedLanguages.map((item,index) =>{
                    return(
                        <DropdownItem key={index} className={i18next.language === item.code ? 'bold' : ''} onClick={() => i18next.changeLanguage(item.code)}>
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode={item.countryCode}
                                style={{
                                    fontSize: '1.2em',
                                    lineHeight: '1.2em',
                                }}
                                aria-label="Country Flag"
                            />
                            <span className='mx-2'>{item.name}</span>
                        </DropdownItem>
                    )
                })}                
            </DropdownMenu>                    
        </Dropdown>
    )

    return (
        <Navbar className="z-1 row-shadow color-navbar" expand="md">

            <Container fluid>
                <NavbarToggler onClick={toggleNav} />

                
                <Offcanvas isOpen={isNavOpen} toggle={() => setIsNavOpen(prev => !prev)}>
                    <OffcanvasBody className='d-flex flex-column justify-content-between'>
                        <Nav navbar>
                            {dashboardLink}

                            {promotionsLink}

                            {languageOption}
                            {userLink}
                        </Nav>
                        <div>
                            <PromotionFinder />
                        </div>
                    </OffcanvasBody>
                </Offcanvas>
            </Container>
        </Navbar>
    )
}
