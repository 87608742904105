import {SelectOption} from "../helpers/SelectOption";
import i18next from "i18next";

export enum EMediaType {
    Leaflet = 1,
    Instore = 2,
    TV = 3
}

export const MediaTypeDescription  = {
    [EMediaType.Leaflet]: i18next.t('leaflet', {ns:'common'}),
    [EMediaType.Instore]: i18next.t('instore', {ns:'common'}),
    [EMediaType.TV]: i18next.t('tv', {ns:'common'})
}

export const getMediaTypeDescription = (mediaType: EMediaType): string => {
    switch (mediaType) {
      case EMediaType.Leaflet:
        return i18next.t('leaflet', { ns: 'common' });
      case EMediaType.Instore:
        return i18next.t('instore', { ns: 'common' });
      case EMediaType.TV:
        return i18next.t('tv', { ns: 'common' });
      default:
        return '';
    }
  };

export const MediaTypeOptions: SelectOption[] = Object.entries(EMediaType).filter(([key, mediaType]) => typeof(mediaType) !== typeof('')).map(([key, mediaType]) => {
    const value = MediaTypeDescription[mediaType as EMediaType]
    return { label: value, value: mediaType as number }
})