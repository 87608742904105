import { baseApi } from "./baseAPI"
import { PromotionCriteria, promotionCriteriaToQuery } from "../dtos/product/promotionCriteria"
import { CustomerTimelineResource } from "../../features/promotions/models/NTimelineModel"
import { handleUrl } from "../../helpers/utils"

export const timelineAPI = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getTimelineResources: build.query<CustomerTimelineResource[], PromotionCriteria>({
            query(criteria: PromotionCriteria) {
                return handleUrl("Timeline", promotionCriteriaToQuery(criteria));
            },
            // Cache by id
            providesTags: (result) => [{ type: 'TimelineResource', id: 'LIST' }],
            // transformResponse: (rawResults: Promotion[], meta) => rawResults.map(r => fixDates(r)),
        })
    }),
    overrideExisting: false,
})

export const {
    useGetTimelineResourcesQuery
} = timelineAPI
