import { useCallback, useState } from "react";
import { Button,  Form, Input, InputGroup} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import PromotionSelectionModal from "./PromotionSelectionModal";
import { useTranslation } from "react-i18next";
import { useSetPromotionId } from "../../app/hooks";

export default function PromotionFinder() {
    const [promotionId, setPromotionId] = useState<string>('');
    const [debouncedTerm, setDebouncedTerm] = useState<string>(promotionId)
    const [isModalOpen, setModalOpen] = useState(false);   
    const {t} = useTranslation(['header'])

    const toggle = useCallback(() => {
        setModalOpen(prev => !prev)
    }, [setModalOpen])

    const onSubmit = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent) => {        
        e.preventDefault();
        setPromotionId(debouncedTerm);
        setModalOpen(true)
        setDebouncedTerm("")
    }, [debouncedTerm])

    return (
        <>
            <Form>
                <InputGroup>
                    <Input name="productId" value={debouncedTerm} onChange={(change) => setDebouncedTerm(change.target.value)} placeholder={t('search_input')}/>
                    <Button type="submit" color="primary" onClick={onSubmit} title={t('search')}>
                        <FontAwesomeIcon icon={faSearch}/>
                    </Button>
                </InputGroup>
            </Form>

            {isModalOpen && <PromotionSelectionModal promotionId={promotionId} isOpen={isModalOpen} toggle={toggle}/>}
        </>
    );
}

