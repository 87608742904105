import './Statistics.css'
import { Table } from "reactstrap"
import {useGetStatisticsQuery} from "../../backoffice/apis/statisticsAPI";
import { DateRange } from '../../helpers/Types';
import {StatisticsEntry} from "./StatisticsEntry";
import { normalizedNumber } from '../../helpers/utils';
import { useTranslation } from 'react-i18next';
import { PromotionCriteria } from '../../backoffice/dtos/product/promotionCriteria';


export default function StatisticsBody(
        {
            promotionCriteria
        } : {
            promotionCriteria: PromotionCriteria
        }
) {
    const { data: statisticsModel, isLoading: areStatisticsLoading } = useGetStatisticsQuery(promotionCriteria, {refetchOnMountOrArgChange: true})
    
    const {t} = useTranslation(['statistics'])
    if (!statisticsModel)
        return <></>

    return <div className="p-3">
        
        <Table>
            <thead>
                <tr>
                    <th className="text-align-center">Top 5</th>
                </tr>
            </thead>
        </Table>
        <Table striped hover>
            <thead>
                <tr>
                    <th className="text-align-center">{t('brand')}</th>
                    <th className="text-align-center">{t('cider')} (Hls)</th>
                    <th className="text-align-center">{t('cider')} (€)</th>
                    <th className="text-align-center">{t('beer')} (Hls)</th>
                    <th className="text-align-center">{t('beer')} (€)</th>
                </tr>
            </thead>
            <tbody>
                {statisticsModel.topCustomersDetails.map(m =>
                <StatisticsEntry columns={[
                    { value: m.company },
                    { value: normalizedNumber(m.ciderHls), className: "text-align-end" },
                    { value: normalizedNumber(m.ciderEuro), className: "text-align-end"},
                    { value: normalizedNumber(m.beerHls), className: "text-align-end"},
                    { value: normalizedNumber(m.beerEuro), className: "text-align-end"}
                ]}/>)}
                <StatisticsEntry columns={[
                    { value: t('total'), className: "strong"},
                    { value: normalizedNumber(statisticsModel.topCustomers.ciderHls), className: "text-align-end"},
                    { value: normalizedNumber(statisticsModel.topCustomers.ciderEuro), className: "text-align-end"},
                    { value: normalizedNumber(statisticsModel.topCustomers.beerHls), className: "text-align-end"},
                    { value: normalizedNumber(statisticsModel.topCustomers.beerEuro), className: "text-align-end"}
                ]}/>
            </tbody>
        </Table>

        <br/>
        <Table>
            <thead>
                <tr>
                    <th className="text-align-center">Other</th>
                </tr>
            </thead>
        </Table>
        <Table striped hover>
            <thead>
                <tr>
                    <th className="text-align-center">{t('brand')}</th>
                    <th className="text-align-center">{t('cider')} (Hls)</th>
                    <th className="text-align-center">{t('cider')} (€)</th>
                    <th className="text-align-center">{t('beer')} (Hls)</th>
                    <th className="text-align-center">{t('beer')} (€)</th>
                </tr>
            </thead>
            <tbody>
                {statisticsModel.otherCustomersDetails.map(m =>
                <StatisticsEntry columns={[
                    { value: m.company },
                    { value: normalizedNumber(m.ciderHls), className: "text-align-end" },
                    { value: normalizedNumber(m.ciderEuro), className: "text-align-end"},
                    { value: normalizedNumber(m.beerHls), className: "text-align-end"},
                    { value: normalizedNumber(m.beerEuro), className: "text-align-end"}
                ]}/>)}
                <StatisticsEntry columns={[
                    { value: t('total')},
                    { value: normalizedNumber(statisticsModel.otherCustomers.ciderHls), className: "text-align-end"},
                    { value: normalizedNumber(statisticsModel.otherCustomers.ciderEuro), className: "text-align-end"},
                    { value: normalizedNumber(statisticsModel.otherCustomers.beerHls), className: "text-align-end"},
                    { value: normalizedNumber(statisticsModel.otherCustomers.beerEuro), className: "text-align-end"}
                ]}/>
            </tbody>
        </Table>
        <br/>
        <Table>
            <thead>
                <tr>
                    <th className="text-align-center">Totals</th>
                </tr>
            </thead>
        </Table>
        <Table striped hover>
            <thead>
                <tr>
                    <th className="text-align-center">{t('brand')}</th>
                    <th className="text-align-center">{t('cider')} (Hls)</th>
                    <th className="text-align-center">{t('cider')} (€)</th>
                    <th className="text-align-center">{t('beer')} (Hls)</th>
                    <th className="text-align-center">{t('beer')} (€)</th>
                </tr>
            </thead>
            <tbody>
                <StatisticsEntry columns={[
                    { value: "Top 5"},
                    { value: normalizedNumber(statisticsModel.topCustomers.ciderHls), className: "text-align-end"},
                    { value: normalizedNumber(statisticsModel.topCustomers.ciderEuro), className: "text-align-end"},
                    { value: normalizedNumber(statisticsModel.topCustomers.beerHls), className: "text-align-end"},
                    { value: normalizedNumber(statisticsModel.topCustomers.beerEuro), className: "text-align-end"}
                ]}/>
                <StatisticsEntry columns={[
                    { value: "Others"},
                    { value: normalizedNumber(statisticsModel.otherCustomers.ciderHls), className: "text-align-end"},
                    { value: normalizedNumber(statisticsModel.otherCustomers.ciderEuro), className: "text-align-end"},
                    { value: normalizedNumber(statisticsModel.otherCustomers.beerHls), className: "text-align-end"},
                    { value: normalizedNumber(statisticsModel.otherCustomers.beerEuro), className: "text-align-end"}
                ]}/>
                <StatisticsEntry columns={[
                    { value: t('total')},
                    { value: normalizedNumber(statisticsModel.allCustomers.ciderHls), className: "text-align-end"},
                    { value: normalizedNumber(statisticsModel.allCustomers.ciderEuro), className: "text-align-end"},
                    { value: normalizedNumber(statisticsModel.allCustomers.beerHls), className: "text-align-end"},
                    { value: normalizedNumber(statisticsModel.allCustomers.beerEuro), className: "text-align-end"}
                ]}/>
            </tbody>
        </Table>
    </div>
}
