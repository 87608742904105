import {
    faCheckCircle,
    faCircleHalfStroke,
    faCircleXmark,
    faHourglassHalf, faListCheck,
    faPaperPlane,
    faPencil,
    faTrashCan,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";
import { SelectOption } from "../helpers/SelectOption";

export enum EApprovalStatus {
    Draft = 10,
    Rejected = 15,
    PendingApproval = 30,
    Approved = 50,
    POSubmitted = 70,
    Deleted = 90,
    POFinalized = 110,
    Completed = 130
}

export const ApprovalStatusColor = {
    [EApprovalStatus.Draft]: 'var(--bg-promotion-draft)',
    [EApprovalStatus.Rejected]: 'var(--bg-promotion-rejected)',
    [EApprovalStatus.PendingApproval]: 'var(--bg-promotion-pending-approval)',
    [EApprovalStatus.Approved]: 'var(--bg-promotion-approved)',
    [EApprovalStatus.POSubmitted]: 'var(--bg-promotion-po-submitted)',
    [EApprovalStatus.Deleted]: 'var(--bg-promotion-deleted)',
    [EApprovalStatus.POFinalized]: 'var(--bg-promotion-po-finalized)',
    [EApprovalStatus.Completed]: 'var(--bg-promotion-completed)'
}

export const ApprovalStatusDescription = {
    [EApprovalStatus.Draft]: 'Προσωρινή',
    [EApprovalStatus.Rejected]: 'Απορρίφθηκε',
    [EApprovalStatus.PendingApproval]: 'Διαδικασία Έγκρισης',
    [EApprovalStatus.Approved]: 'Εγκρίθηκε',
    [EApprovalStatus.POSubmitted]: 'Δημιουργήθηκε PO',
    [EApprovalStatus.Deleted]: 'Ακυρώθηκε',
    [EApprovalStatus.POFinalized]: 'Οριστικοποιήθηκε PO',
    [EApprovalStatus.Completed]: 'Ολοκληρώθηκε'    
}

export const ApprovalStatusDescriptionList = {
    [EApprovalStatus.Draft]: 'Προσωρινές',
    [EApprovalStatus.Rejected]: 'Απορριφθείσες',
    [EApprovalStatus.PendingApproval]: 'Αναμένουν Έγκριση',
    [EApprovalStatus.Approved]: 'Εγκεκριμένες',
    [EApprovalStatus.POSubmitted]: 'Δημιουργημένες PO',
    [EApprovalStatus.POFinalized]: 'Οριστικοποιημένες PO',
    [EApprovalStatus.Completed]: 'Ολοκληρωμένες',
    [EApprovalStatus.Deleted]: 'Ακυρωμένες', 
}



export function getApprovalStatusDescription(status: EApprovalStatus): string {
    switch (status) {
      case EApprovalStatus.Draft:
        return i18next.t('draft_1', { ns: 'common' });
      case EApprovalStatus.Rejected:
        return i18next.t('rejected_1', { ns: 'common' });
      case EApprovalStatus.PendingApproval:
        return i18next.t('pending_approval_1', { ns: 'common' });
      case EApprovalStatus.Approved:
        return i18next.t('approved_1', { ns: 'common' });
      case EApprovalStatus.POSubmitted:
        return i18next.t('po_submitted_1', { ns: 'common' });
      case EApprovalStatus.POFinalized:
        return i18next.t('po_finalized_1', { ns: 'common' });
      case EApprovalStatus.Completed:
        return i18next.t('completed_1', { ns: 'common' });
      case EApprovalStatus.Deleted:
        return i18next.t('deleted_1', { ns: 'common' });
      default:
        return '';
    }
  };
export function getApprovalStatusDescriptionList(status: EApprovalStatus): string {
    switch (status) {
      case EApprovalStatus.Draft:
        return i18next.t('draft', { ns: 'common' });
      case EApprovalStatus.Rejected:
        return i18next.t('rejected', { ns: 'common' });
      case EApprovalStatus.PendingApproval:
        return i18next.t('pending_approval', { ns: 'common' });
      case EApprovalStatus.Approved:
        return i18next.t('approved', { ns: 'common' });
      case EApprovalStatus.POSubmitted:
        return i18next.t('po_submitted', { ns: 'common' });
      case EApprovalStatus.POFinalized:
        return i18next.t('po_finalized', { ns: 'common' });
      case EApprovalStatus.Completed:
        return i18next.t('completed', { ns: 'common' });
      case EApprovalStatus.Deleted:
        return i18next.t('deleted', { ns: 'common' });
      default:
        return '';
    }
  };

export function getApprovalStatusSymbol(approvalStatus: number): IconDefinition | null {
    switch(approvalStatus) {
        case 10: return faPencil;
        case 15: return faCircleXmark;
        case 30: return faHourglassHalf;
        case 50: return faListCheck;
        case 70: return faPaperPlane;
        case 90: return faTrashCan;
        case 110: return faCircleHalfStroke;
        case 130: return faCheckCircle
        default: return null;
    }
}

export function getApprovalStatusOptions(): SelectOption[] { 
  return [
    { value: EApprovalStatus.Deleted,     label: getApprovalStatusDescription(EApprovalStatus.Deleted) },
    { value: EApprovalStatus.Rejected,    label: getApprovalStatusDescription(EApprovalStatus.Rejected) },
    { value: EApprovalStatus.Draft,       label: getApprovalStatusDescription(EApprovalStatus.Draft) },
    { value: EApprovalStatus.PendingApproval, label: getApprovalStatusDescription(EApprovalStatus.PendingApproval) },
    { value: EApprovalStatus.Approved,    label: getApprovalStatusDescription(EApprovalStatus.Approved) },
    { value: EApprovalStatus.POSubmitted, label: getApprovalStatusDescription(EApprovalStatus.POSubmitted) },
    { value: EApprovalStatus.POFinalized, label: getApprovalStatusDescription(EApprovalStatus.POFinalized) },
    { value: EApprovalStatus.Completed,   label: getApprovalStatusDescription(EApprovalStatus.Completed) }
  ];
}