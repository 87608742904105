import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Row } from "reactstrap"

export function SuccessUI(
    {
        navBack
    }:{
        navBack: () => void
    }
) {
    return <>
        <br/>
        <div>
            <FontAwesomeIcon icon={faCheckCircle} style={{width:"150px", height:"150px", color:"green"}}/>
        </div>
        <br/>
        <div>
            Επιτυχής Αλλαγή Κωδικού.
        </div>
        <br/>
        <Row className="m-5 my-3">
            <Button onClick={navBack}>Επιστοφή</Button>
        </Row>
    </>
}