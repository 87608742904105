import { IconDefinition, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo } from "react"
import { Button, CardHeader, Collapse } from "reactstrap"

export interface AccordionItemProps extends React.HTMLAttributes<HTMLElement> {
    id: string,
    title: string,
    headerButtonIcon ?: IconDefinition,
    isExpanded ?: boolean,
    setHeaderButtonClick ?: () => void
    setExpandedId ?: (elementId: string | undefined) => void,
}

export function AccordionItem({ 
    id, title, headerButtonIcon, isExpanded, setHeaderButtonClick: onHeaderButtonClick, setExpandedId, ...props
 }: AccordionItemProps) {
    
    const accordionClick = useCallback(() => {
        if (setExpandedId === undefined)
            return undefined;

        return isExpanded ? setExpandedId(undefined) : setExpandedId(id)
    }, [setExpandedId, isExpanded])

    const setHeaderButtonClick = useCallback((mouseEvent: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!onHeaderButtonClick)
            return undefined;

        mouseEvent.stopPropagation();

        return onHeaderButtonClick()
    }, [onHeaderButtonClick])

    return <>
        <CardHeader className="cursor-pointer unselectable d-flex justify-content-between align-items-center" onClick={accordionClick}>
            {title}
            <Button color="" className={onHeaderButtonClick ? "" : "invisible"} onClick={setHeaderButtonClick}>
                <FontAwesomeIcon icon={headerButtonIcon ?? faXmark}/>
            </Button>
        </CardHeader>
        <Collapse isOpen={isExpanded == true}>
            {props.children}
        </Collapse>
    </>
}

