import React from 'react'
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { Role, RolesList } from '../../backoffice/dtos/authentication/role';
import { useDeleteUserMutation, useUpdateUserRolesMutation } from '../../backoffice/apis/userApi';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { user } from '../../backoffice/dtos/authentication/user';

const UserEditModal = ({
    isOpen,
    toggle,
    selectedUser,
    setSelectedUser,
    roles
}:{
    isOpen: boolean;
    toggle: () => void;
    selectedUser: user;
    setSelectedUser: React.Dispatch<React.SetStateAction<user | undefined>>;
    roles: RolesList ;
}) => {

  const [updateRoles] = useUpdateUserRolesMutation();
  const [deleteUser,{data}] = useDeleteUserMutation();
  const {t} = useTranslation(['user_info','common'])

  const handleSubmit = async () => {
    
    try {
      if (selectedUser)
       await updateRoles(selectedUser)    
       toggle()
    } catch (e:any) {
      toast(e.data)
    }
  } 

  const handleDelete = async () => {
    
    confirmAlert({
        title: t('user_delete_alert_title'),
        message: t('user_delete_alert_description', {user:selectedUser?.userName}) ,
        buttons: [
            { label: t('yes',{ns:'common'}), onClick: async () => {
                try {
                  if(selectedUser?.userName){
                    await deleteUser({userName:selectedUser.userName})
                    toggle()
                    toast(data)
                  }
                } catch (e:any) {
                  toast(e.data)
                }
            }},
            { label: t('no',{ns:'common'}), onClick: () => { } }]
    });
}

  const handleRoleChange = (role: Role, roleType: 'roles' | 'hierarchyRoles') => {
    if (!selectedUser) return;
  
    var updatedRoles = [...selectedUser[roleType]];
  
    if (selectedUser[roleType].find((r) => r.level === role.level)) {
      updatedRoles = updatedRoles.filter((r) => r.level !== role.level);
    } else {
      updatedRoles.push(role);
    }
  
    setSelectedUser({ ...selectedUser, [roleType]: updatedRoles });
  };

  return (
    <div>
        <Modal size='lg' isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('edit',{ns:'common'})}</ModalHeader>
        
          <ModalBody>
            {selectedUser && roles ?
            <Row>     
              <Row>           
                <Col>
                    <div className="form-floating m-2">
                        <Input type='text' style={{fontWeight:"700",fontSize:"1rem"}} name='username' value={selectedUser.userName} disabled={true} />   
                        <Label style={{fontWeight:"900",fontSize:"1.2rem"}} for="username">Username</Label> 
                    </div>   
                </Col>
                <Col>
                      <div className="form-floating m-2 ">
                          <Input type='text' style={{fontWeight:"700",fontSize:"1rem"}} name='email' value={selectedUser.email} disabled={true} />   
                          <Label style={{fontWeight:"900",fontSize:"1.2rem"}} for="email">Email</Label> 
                      </div>                      
                </Col>
                </Row>
                { selectedUser.roles && selectedUser.hierarchyRoles &&  roles.Roles && roles.HierarchyRoles &&
                <Row className='mx-2 my-4'>
                  <Col>
                    <h5>{t('roles')}</h5>    
                    {roles.Roles.map((role) => (
                      <FormGroup switch key={role.level}>
                        <Input
                          type="switch"
                          role="switch"
                          value={role.level}
                          checked={selectedUser.roles.find((r) => r.level === role.level) !== undefined}
                          onChange={() => handleRoleChange(role, "roles")}
                        />
                        <Label check>{role.name}</Label>
                      </FormGroup>
                    ))}
                  </Col>
                  <Col>
                    <h5>{t('hierarchy_roles')}</h5>
                    {roles.HierarchyRoles.map((role) => (
                      <FormGroup switch key={role.level}>
                        <Input
                          type="switch"
                          role="switch"
                          value={role.level}
                          checked={selectedUser.hierarchyRoles.find((r) => r.level === role.level) !== undefined}
                          onChange={() => handleRoleChange(role, "hierarchyRoles")}
                        />
                        <Label check>{role.name}</Label>
                      </FormGroup>
                    ))}
                </Col>
                </Row>      
                }          
            </Row>
            : <></>} 
        </ModalBody>
        
        
        <ModalFooter>
          <Button color="success" onClick={handleSubmit}>
          {t('update',{ns:'common'})}
          </Button>
          <Button color="danger" onClick={handleDelete}>
          {t('delete',{ns:'common'})}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default UserEditModal
