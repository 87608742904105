import { useCallback, useEffect, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from './store';
import { useSearchParams } from 'react-router-dom';


// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useWindowHeight() {
    const [width, setWidth] = useState<number>(window.innerWidth)
    
    useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener("resize", handleResizeWindow);
        return () => window.removeEventListener("resize", handleResizeWindow)
    }, []);

    return width
}

export function useSetPromotionId() {
    const [_, setSearchParams] = useSearchParams();
    return useCallback((id: number | undefined) => {
        setSearchParams(prev => {
            if (id === undefined) {
                prev.delete("promotionId")
            } else {
                prev.set("promotionId", id.toString());
            }
            return prev;
        })
    }, []);
}