import { useState } from "react";
import { Tooltip } from "reactstrap";
import {TimelineMasterWithEvent} from "../../models/NTimelineModel";

export default function EventPopOver(
        { target, event }: { target: string, event: TimelineMasterWithEvent }
) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const promotionsElements = event.event.timelineEventDetails.map((m, index) => {
        return <>{`${index + 1}. ${m.description} ${m.discount} ${m.hls}Hls`}</>
    });
    const productDescriptions = event.event.timelineEventDetails.length > 0
        ? promotionsElements.reduce((acc, m) => <>{acc}<br/>{m}</>)
        : "No Products";
    return <Tooltip target={target} placement="right"  isOpen={tooltipOpen} toggle={toggle}>
        <div>
            {productDescriptions}
        </div>
    </Tooltip>
}
