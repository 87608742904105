import { StrDateRange, getDefaultTimelineRangeStr, strDateRangeEquals } from "../../../helpers/Types"
import { shallowEquals } from "../../../helpers/arrayHelpers"

export interface PromotionCriteria {
     customerIds ?: number[]
     approvalStatus ?: number[]
     mediaTypeIds ?: number[]
     dateRange ?: StrDateRange
     products ?: string[]
     onlyOwned ?: boolean
     onlyAvailableToPromote ?: boolean
     take ?: number
}

export function promotionCriteriaToQuery(criteria: PromotionCriteria | undefined): URLSearchParams | undefined{
     if (!criteria)
          return undefined;
          
     let params = new URLSearchParams();

     if (criteria.customerIds && criteria.customerIds.length > 0)
          for(const customerId of criteria.customerIds)
               params.append("customerIds", `${customerId}`);
     if (criteria.approvalStatus)
          for(const approvalStatus of criteria.approvalStatus)
               params.append("approvalStatus", `${approvalStatus}`);
     if (criteria.mediaTypeIds && criteria.mediaTypeIds.length > 0)
          for(const mediaTypeId of criteria.mediaTypeIds)
               params.append("mediaTypeIds", `${mediaTypeId}`);
     if (criteria.products && criteria.products.length > 0)
     {
        for(const product of criteria.products)
            params.append("products", `${product}`);
     }
     if (criteria.dateRange?.fromDate)
          params.append('fromDate', `${criteria.dateRange.fromDate}`);
     if (criteria.dateRange?.toDate)
          params.append('toDate', `${criteria.dateRange.toDate}`);
     if (criteria.onlyAvailableToPromote)
          params.append('onlyAvailableToPromote', `${criteria.onlyAvailableToPromote}`);
     if (criteria.onlyOwned)
          params.append('onlyOwned', `${criteria.onlyOwned}`);

     return params;
}
/*
     customerIds ?: number[]
     approvalStatus ?: number[]
     mediaTypeIds ?: number[]
     dateRange ?: StrDateRange
     onlyOwned ?: boolean
     onlyAvailableToPromote ?: boolean
*/
export function PromotionCriteriaIsDateRangeDefault(promotionCriteria: PromotionCriteria): boolean {
     const defaultDateRange = getDefaultTimelineRangeStr();
     const dateRange = promotionCriteria.dateRange;
     return (dateRange === undefined && defaultDateRange === undefined) 
          || (dateRange !== undefined
               && dateRange.fromDate === defaultDateRange.fromDate
                    && dateRange.toDate === defaultDateRange.toDate);
}

export function PromotionCriteriaIsCustomerIdsDefault(promotionCriteria: PromotionCriteria): boolean {
     return promotionCriteria.customerIds === undefined || promotionCriteria.customerIds.length === 0;
}

export function PromotionCriteriaIsMediaTypeIdsDefault(promotionCriteria: PromotionCriteria): boolean {
     return promotionCriteria.mediaTypeIds === undefined || promotionCriteria.mediaTypeIds.length === 0;
}

export function PromotionCriteriaIsApprovalStatusDefault(promotionCriteria: PromotionCriteria): boolean {
     return promotionCriteria.approvalStatus === undefined || promotionCriteria.approvalStatus.length === 0;
}

export function PromotionCriteriaIsParamsDefault(promotionCriteria: PromotionCriteria): boolean {
     return !(promotionCriteria.onlyAvailableToPromote || promotionCriteria.onlyOwned);
}

export function PromotionCriteriaIsDefault(promotionCriteria: PromotionCriteria): Boolean {
     return PromotionCriteriaIsApprovalStatusDefault(promotionCriteria)
          && PromotionCriteriaIsCustomerIdsDefault(promotionCriteria)
               && PromotionCriteriaIsDateRangeDefault(promotionCriteria)
                    && PromotionCriteriaIsMediaTypeIdsDefault(promotionCriteria)
                         && PromotionCriteriaIsParamsDefault(promotionCriteria)
}

export function PromotionCriteriaComparison(a: PromotionCriteria, b: PromotionCriteria): Boolean {
     let areEqual = shallowEquals(a.approvalStatus, b.approvalStatus);
     areEqual &&= shallowEquals(a.customerIds, b.customerIds);
     areEqual &&= shallowEquals(a.mediaTypeIds, b.mediaTypeIds);
     areEqual &&= strDateRangeEquals(a.dateRange, b.dateRange)
     areEqual &&= a.onlyAvailableToPromote === b.onlyAvailableToPromote;
     areEqual &&= a.onlyOwned === b.onlyOwned;
     return areEqual;
}
