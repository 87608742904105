import { baseApi } from "./baseAPI"
import {statisticsModel} from "../dtos/statistics/statisticsModel";
import {DateRange} from "../../helpers/Types";
import {formatToYMD} from "../../helpers/dateHelper";
import { PromotionCriteria, promotionCriteriaToQuery } from "../dtos/product/promotionCriteria";
import { handleUrl } from "../../helpers/utils";

export const statisticsAPI = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getStatistics: build.query<statisticsModel, PromotionCriteria | undefined>({
            query: (criteria) => {
                
                return {
                    url: handleUrl("Statistics", promotionCriteriaToQuery(criteria)),
                    method: 'GET'
                }
            },
            // transformErrorResponse: (response) => response.data
        })
    }),
    overrideExisting: false,
})

export const { useGetStatisticsQuery } = statisticsAPI