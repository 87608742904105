import {Button, Col, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import PromotionComponent from "./PromotionComponent";
import {useCallback, useEffect, useState} from "react";
import CloneModal from "./CloneModal/CloneModal";

export default function PromotionModal(
        {
            currentPromotionId,
            promotionModalOpen,
            toggle
        }:
        {
            currentPromotionId: number,
            promotionModalOpen: boolean,
            toggle: () => void
        }
) {
    const [openCopyModal, setOpenCopyModal] = useState(false);
    const toggleCopyModal = useCallback(() => {
        setOpenCopyModal(ref => !ref)
    }, [])
    return <>
    <Modal size="lg" isOpen={promotionModalOpen} toggle={toggle}>
        <ModalBody>
            <PromotionComponent promotionId={currentPromotionId} toggle={toggle} toggleCopyModal={toggleCopyModal} isOpen={promotionModalOpen}/>
        </ModalBody>
    </Modal>
    <CloneModal promotionId={currentPromotionId} toggle={toggleCopyModal} isOpen={openCopyModal}/>
    </>
}
