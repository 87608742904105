import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { user } from '../dtos/authentication/user';

interface UserState {
    value: user | undefined
}

const initialState: UserState = {
    value: undefined
}

export const userStateConsumerSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearUser: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      return {
        value: undefined
      };
    },
    assignUser: (state, consumer: PayloadAction<user>) => {
        return {
            value: consumer.payload
        };
    }
  }
})
export const { clearUser, assignUser } = userStateConsumerSlice.actions;

export default userStateConsumerSlice.reducer;