import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import logger from 'redux-logger'

import promotionsReducer from '../backoffice/slices/promotionsSlice';
import authenticationReducer from '../backoffice/slices/authenticationSlice';
import counterReducer from "../backoffice/slices/counterSlice";

import { baseApi, rtkQueryErrorInterceptor } from '../backoffice/apis/baseAPI';
import ProductIdReducer from '../backoffice/slices/ProductIdReducer';
import userSlice from '../backoffice/slices/userSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    promotions: promotionsReducer,
    api: baseApi.reducer,
    authentication: authenticationReducer,
    product: ProductIdReducer,
    user: userSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(baseApi.middleware)
      .concat(logger)
      .concat(rtkQueryErrorInterceptor)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;