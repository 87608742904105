import { useCallback, useState } from "react";
import { getUser } from "../../helpers/localStorage";
import { Container } from "reactstrap";
import { sendChangePassword, unwrapDataError } from "../../backoffice/apis/axios";
import { useNavigate } from "react-router-dom";
import { ChangePasswordDto } from "../../backoffice/dtos/authentication/ChangePasswordDto";
import { ChangePasswordUI } from "./ChangePasswordUI";
import { SuccessUI } from "./SuccessUI";
import { toast } from "react-toastify";

export function ChangePassword() {
    const user = getUser();
    const navigate = useNavigate();
    const [formState, setFormState] = useState<"login" | "success" | "failure">("login");

    const changePasswordRequest = useCallback<(changePassword: ChangePasswordDto) => Promise<void>>(async (changePassword: ChangePasswordDto) => {
        try {
            const result = await sendChangePassword({
                existingPassword: changePassword.existingPassword,
                newPassword: changePassword.newPassword,
                repeatNewPassword: changePassword.repeatNewPassword
            });
            setFormState("success");
        } catch(error) {
            const errorTxt = unwrapDataError(error);
            toast(errorTxt);
        }
    }, [navigate]);

    const navigateToUser = useCallback(() => {
        navigate("/user");
    }, [navigate])

    return (
        <>
            <div className="p-3"/>
            <div className="p-3">
                <Container className="bg-lvl-3 shadow rounded-4 p-4 gy-2" style={{maxWidth:"900px"}}>
                    {
                        formState == "login" &&
                            <ChangePasswordUI changePasswordRequest={changePasswordRequest}/>
                    }
                    {
                        formState == "success" &&
                            <SuccessUI navBack={navigateToUser}/>
                    }
                </Container>
            </div>
        </>
    )
}