import { useCallback, useMemo } from "react"
import { Col, Container, Row, Spinner } from "reactstrap"
import { useGetCustomersQuery } from "../../../backoffice/apis/customerAPI";
import ListSelection, { ListItem } from "../../views/common/ListSelection";
import { useTranslation } from "react-i18next";

export default function CloneForm(
    {
        setSelectedCustomerIds,
    }:{
        setSelectedCustomerIds: (customerIds: number[]) => void,
    }
) {
    const { data: customers, isFetching, error } = useGetCustomersQuery({ query:"", includeDeleted: false});
    const { t } = useTranslation(['promotion_timeline', 'common', 'promotion'])
    const fromCustomers = useMemo<ListItem[] | undefined>(() => {
        if (customers === undefined)
            return undefined;

        return [...customers].sort((m1, m2) => m1.order - m2.order).map(m => ({ Id: m.id, Label: m.shortDescription}))
    }, customers);


    const assignState = useCallback(
        (items: ListItem[]) => setSelectedCustomerIds(items.filter(m => typeof(m.Id) === "number").map(m => m.Id as number)), 
        [setSelectedCustomerIds]
    );
    
    let content: JSX.Element
    if (isFetching) {
        content = (
            <Row className="d-flex flex-row justify-content-center" style={{height: "200px"}}>
                <Col className="d-flex flex-column justify-content-center w-auto" style={{maxWidth: "55px"}}>
                    <Spinner/>
                </Col>
            </Row>
        )
    } else if (error) {
        content = (
            <Row>
                <Col tag="p" className="text-align-center text-danger">
                    {'data' in error ? JSON.stringify((error as any).data) : "unknown error"}
                </Col>
            </Row>
        )
    } else if (customers) {
        content = (
            <ListSelection titleFrom={t('brands')} titleTo={t('brands_to_copy')} items={fromCustomers ?? []} onValuesChange={assignState}/>
        )
    } else {
        content = (
            <Row style={{height: "200px"}}>
                <Col className="d-flex flex-column justify-content-center align-items-center">
                    <Spinner/>
                </Col>
            </Row>
        )
    }

    return (
        <>
            <Container>                
                {content}                
            </Container>
        </>
    )
}
