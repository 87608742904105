import { Button, Col, Container, Row, Table } from "reactstrap";
import { CopiedPromotion } from "../../../backoffice/dtos/product/CopiedPromotion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faShare } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export function CloneResult(
    {
        copiedPromotions,
        setPromotionId
    }:{
        copiedPromotions: CopiedPromotion[],
        setPromotionId: (id: number) => void
    }
) {
    const {t} = useTranslation(['common'])
    return (
        <Container>
            <Table >
                <thead>
                    <tr>
                        <th>{t('promotion_code').toUpperCase()}</th>
                        <th>{t('customer').toUpperCase()}</th>
                        <th style={{width:"50px"}}></th>
                    </tr>
                </thead>
                <tbody >  
                    {copiedPromotions.map((prom) => {                                
                        return (
                        <tr key={prom.promotionId} >
                            <td style={{width:"200px",borderLeft:"4px solid #cc8a00"}} >{prom.promotionId}</td>   
                            <td style={{minWidth: "200px"}}>
                                {prom.customer}
                            </td>
                            <td style={{minWidth:"50px"}}>
                                <Button className="w-100" color="primary" onClick={() => setPromotionId(prom.promotionId)}><FontAwesomeIcon icon={faArrowRight}/></Button>
                            </td>
                        </tr>
                        );
                    })}
                </tbody>
            </Table>
        </Container>
    )
}
