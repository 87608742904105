import { Button, Col, Modal, ModalBody, ModalHeader, Row, Spinner} from "reactstrap"
import { useClonePromotionToManyMutation, useGetPromotionQuery } from "../../../backoffice/apis/promotionAPI"
import {  useState, useEffect, useCallback, useMemo } from "react"
import CloneForm from "./CloneForm"
import { CloneResult } from "./CloneResult"
import { CopiedPromotion } from "../../../backoffice/dtos/product/CopiedPromotion"
import BasePromotionModal from "../PromotionModal"
import { useTranslation } from "react-i18next"
import React from "react"
import CopyState from "./CopyState"
import { toast } from "react-toastify"
import CloneDate from "./CloneDate"
import { DateRange, rangeToStr } from "../../../helpers/Types"


export default function CloneModal({
    promotionId,
    isOpen,
    toggle,
    setCurrentPromotionId
}: {
    promotionId: number,
    isOpen: boolean,
    toggle: () => void,
    setCurrentPromotionId ?: (id: number) => void
}) {
    const [ copyState, setCopyState ] = useState(CopyState.SelectCompanies)
    
    const [ customerIds, setCustomerIds ] = useState<number[]>([])
    const [ dateRange, setDateRange ] = useState<DateRange | undefined>(undefined)

    const [ copiedPromotions, setCopiedPromotions ] = useState<CopiedPromotion[] | undefined>(undefined);
    const [ promotionToOpen, setPromotionToOpen ] = useState<number | undefined>()
    const {t} = useTranslation(['promotion_timeline'])
    const [ sending, setSending ] = useState(false);
    const [ cloneToMany ] = useClonePromotionToManyMutation();

    useEffect(() => {
        if (isOpen && copiedPromotions !== undefined) {
            setCopyState(CopyState.SelectCompanies);
            setCustomerIds([])
            setDateRange(undefined)
        }
    }, [isOpen])

    const handleCopySubmit = useCallback(async () => {
        if (customerIds.length === 0){
            toast(t('no_selected_customers_alert',{ns: 'promotion'}))
            return
        }            
        try {       
            setSending(true)
            const strDateRange = dateRange ? rangeToStr(dateRange) : undefined;
            //
            const copiedPromotions = await cloneToMany(
                { 
                    promotionId: promotionId,
                    body: { 
                        customerIds: customerIds,
                        promotionDate: strDateRange
                    } 
                }
            ).unwrap()
            setCopiedPromotions(copiedPromotions)
            setCopyState(CopyState.ViewResults);
        } catch (e:any) {
            toast(e.data)
        } finally {
            setSending(false)
        }
    }, [customerIds, dateRange])

    let content: JSX.Element; 
    switch(copyState) {
        case CopyState.SelectCompanies:
            content = (
                <CloneForm setSelectedCustomerIds={setCustomerIds}/>
            );
            break;
        case CopyState.SelectDate:
            content = (
                <CloneDate dateRange={dateRange} setDateRange={setDateRange}/>
            );
            break;
        case CopyState.ViewResults:
            if (copiedPromotions !== undefined){
                content = (
                    <CloneResult copiedPromotions={copiedPromotions} setPromotionId={setPromotionToOpen}/>
                );
            } else {
                content = (
                    <>"Error state."</>
                )
            }
            break;
    }

    return (
        <React.Suspense fallback={<Spinner />}>
        
        <Modal size={copyState === CopyState.ViewResults ? "lg" : "xl"} isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
            {t('copy_promotion_title')}
            </ModalHeader>
            <ModalBody>
                {
                    content
                }
                {copyState !== CopyState.ViewResults &&
                    <Row className="gy-2 my-2">
                        <Col/>
                        <Col lg={2}>
                            <Button className="w-100" color="primary" disabled={sending || copyState === CopyState.SelectCompanies} onClick={() => {setCopyState(CopyState.SelectCompanies)}}>
                                {sending && <Spinner size="sm"/>} {t('back', {ns: 'common'})}
                            </Button>
                        </Col>
                        <Col lg={2}>
                            <Button className="w-100" color="primary" disabled={sending || copyState !== CopyState.SelectCompanies} onClick={() => {setCopyState(CopyState.SelectDate)}}>
                                {sending && <Spinner size="sm"/>} {t('next', {ns: 'common'})}
                            </Button>
                        </Col>
                        <Col lg={2}>
                            <Button className="w-100" color="primary" disabled={sending} onClick={handleCopySubmit}  > 
                                {sending && <Spinner size="sm"/>} {t('copy',{ns:'common'})}
                            </Button>              
                        </Col>
                        <Col lg={2}>
                            <Button className="w-100" onClick={toggle}>{t('cancel',{ns:'common'})}</Button>
                        </Col>
                    </Row>
                }
            </ModalBody>
        </Modal>
        {promotionToOpen && <BasePromotionModal currentPromotionId={promotionToOpen} promotionModalOpen={promotionToOpen !== undefined} toggle={() => setPromotionToOpen(undefined)}/>}
        </React.Suspense>
    )
}
