import { Button, ButtonGroup, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Input, InputGroup, InputGroupText, Row } from "reactstrap";
import { Promotion } from "../../backoffice/dtos/product/promotion";
import { ApprovalStatusColor, EApprovalStatus } from "../../enums/EApprovalStatus";
import { dateOnly, formatToDMY } from "../../helpers/dateHelper";
import { getCanEdit, getCanPromote, user } from "../../backoffice/dtos/authentication/user";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";


export default function DashboardCard(
    {
        user,
        selectedElements,
        setSelectedElements,
        promotion,
        clickHandler
    }: {
        user: user | undefined,
        selectedElements: Map<number, { promotion: Promotion; approve: boolean; }>,
        setSelectedElements: (selectedElements: Map<number, { promotion: Promotion; approve: boolean; }>) => void,
        promotion: Promotion,
        clickHandler: (promotionId: number) => void
    }
) {
    const {t} = useTranslation(['dashboard'])
    const selectedElement = selectedElements.get(promotion.id)
    const isPositive = selectedElement?.approve
    const positiveClass = isPositive === true ? "highlight" : "";
    const negativeClass = isPositive === false ? "highlight" : "";
    const canEdit = useMemo(() => user && getCanEdit(user), [user]);
    const canReject = useMemo(() => canEdit &&
        (
            promotion.approvalStatus !== EApprovalStatus.Deleted
                && promotion.approvalStatus !== EApprovalStatus.Completed
        ), 
        [promotion, canEdit]
    )
    const canApprove = useMemo(() =>
        user
            && getCanPromote(user, promotion)
                && promotion.approvalStatus !== EApprovalStatus.Completed
                    && promotion.approvalStatus !== EApprovalStatus.Deleted, [promotion, user])

    const positiveClosure = isPositive === true
        ? (e: any) => {
            selectedElements.delete(promotion.id)
            setSelectedElements(new Map(selectedElements));
            e.stopPropagation()
        }
        : (e: any) => {
            selectedElements.set(promotion.id, {promotion: promotion, approve: true})
            setSelectedElements(new Map(selectedElements));
            e.stopPropagation()
        };
    const negativeClosure = isPositive === false
        ? (e: any) => {
            selectedElements.delete(promotion.id)
            setSelectedElements(new Map(selectedElements));
            e.stopPropagation()
        }
        : (e: any) => {
            selectedElements.set(promotion.id, {promotion: promotion, approve: false})
            setSelectedElements(new Map(selectedElements));
            e.stopPropagation()
        };

    const cost = useMemo(() => {
        return promotion.promotionProducts
            .map(m => ({
                ActualEuro: m.sellOutEuro,
                ActualHls: m.sellOutHls,
                EstimateEuro: m.euroEstimate,
                EstimateHls: m.hlsEstimate,
                MediaFee: 0.0
            }))
            .reduce((acc, p) => {
                return {
                    ActualEuro: acc.ActualEuro + p.ActualEuro,
                    ActualHls: acc.ActualHls + p.ActualHls,
                    EstimateEuro: acc.EstimateEuro + p.EstimateEuro,
                    EstimateHls: acc.EstimateHls + p.EstimateHls,
                    MediaFee: promotion.mediaFee
                }
            }, {
                ActualEuro: 0.0,
                ActualHls: 0.0,
                EstimateEuro: 0.0,
                EstimateHls: 0.0,
                MediaFee: promotion.mediaFee
            })
    }, [promotion])

    return<Col key={promotion.id} xxl={3} xl={4} md={7}>
        <button className="unset w-100 h-100" onClick={() => clickHandler(promotion.id)}>
            <Card className="h-100" style={{backgroundColor: ApprovalStatusColor[promotion.approvalStatus as EApprovalStatus], color: "white"}} >
                <CardBody style={{display:"flex", flexDirection:"column"}}>
                    <CardTitle tag="h5"> 
                        {promotion.description && promotion.description.substring(0, Math.min(promotion.description.length, 25))}
                    </CardTitle>
                    <CardSubtitle className="mb-2 text-muted status-card-subtitle" tag="h6">
                        <Row className="mb-2">
                            <Col style={{textAlign: "start"}}>
                                {t('details')}
                            </Col>
                        </Row>
                        <Row className="px-2">
                            <Col sm={6} className="px-1">
                                <InputGroup>
                                    <InputGroupText>TPM</InputGroupText>
                                    <Input value={promotion.id} disabled/>
                                </InputGroup>
                            </Col>
                            {
                            promotion.poNumber && (
                                <Col sm={6} className="px-1">
                                    <InputGroup>
                                        <InputGroupText>SAP</InputGroupText>
                                        <Input value={promotion.poNumber} disabled/>
                                    </InputGroup>
                                </Col>
                            )}
                        </Row>
                    </CardSubtitle>
                    <CardText className="mb-2">
                        <Row className="mb-2">
                            <InputGroup>
                                    <InputGroupText>
                                        {t('duration')}
                                    </InputGroupText>
                                    <Input value={formatToDMY(dateOnly(promotion.fromDate), '/')} disabled/>
                                    <Input value={formatToDMY(dateOnly(promotion.toDate), '/')} disabled/>
                            </InputGroup>
                        </Row>
                        { cost.ActualEuro > 0.0 && (
                            <Row className="mb-2">
                                <InputGroup>
                                    <InputGroupText>Actual</InputGroupText>
                                    <Input value={cost.ActualEuro.toFixed(0) + "€"} disabled/>
                                    <Input value={cost.ActualHls.toFixed(0) + "Hls"} disabled/>
                                </InputGroup>
                            </Row>
                        )}
                        { cost.EstimateEuro > 0.0 && (
                            <Row className="mb-2">
                                <InputGroup>
                                    <InputGroupText>Estimates</InputGroupText>
                                    <Input value={cost.EstimateEuro.toFixed(0) + "€"} disabled/>
                                    <Input value={cost.EstimateHls.toFixed(0) + "Hls"} disabled/>
                                </InputGroup>
                            </Row>
                        )}
                        { cost.MediaFee > 0.0 && (
                            <Row>
                                <InputGroup>
                                    <InputGroupText>Media Fee</InputGroupText>
                                    <Input value={cost.MediaFee.toFixed(0) + "€"} disabled/>
                                </InputGroup>
                            </Row>
                        )}
                    </CardText>
                    {(canApprove || canReject) && (
                        <>
                            <div style={{flex:"1"}}/>
                            <Row className="gy-2">
                                <ButtonGroup>
                                    {canApprove && <Button className={["fa", "fa-check", positiveClass].join(" ")} active={isPositive === true} color="success" onClick={positiveClosure}/>}
                                    {canReject && <Button className={["fa", "fa-remove", negativeClass].join(" ")} active={isPositive === false} color="danger" onClick={negativeClosure}/>}
                                </ButtonGroup>
                            </Row>
                        </>
                    )
                    }
                </CardBody>
            </Card>
        </button>
    </Col>
}