import { EApprovalStatus } from "../../../enums/EApprovalStatus"
import { clearLocalStorage } from "../../../helpers/localStorage"
import { logout, unwrapDataError } from "../../apis/axios"
import { Promotion } from "../product/promotion"
import { Role, getUserHasHierarchyRole, getUserHasRole } from "./role"


export interface user {
    userName: string
    email: string
    roles: Role[]
    hierarchyRoles: Role[]
}

export function getCanEdit(user: user): boolean {
  if (user.roles === undefined || user.roles === null) 
    return false

  return getUserHasRole(user, "Editor");
}

export function getCanPromote(user: user, promotion: Promotion): boolean {
  return getUserHasHierarchyRole(user, promotion.approver)
}

export function getCanReject(user: user, promotion: Promotion): boolean {
  if (user === undefined || user === null)
    return false

  return (
      getCanPromote(user, promotion)
  )
}

export async function userLogout(): Promise<string | null> {
  try {
    await logout()
    clearLocalStorage()
    return null
  } catch (error: any) {
    console.log(error)
    return unwrapDataError(error)
  }
}
