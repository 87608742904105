import {faCreditCard, faEuroSign, faPercent, faXmark, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import { SelectOption } from "../helpers/SelectOption";

export enum EDiscountType {
    Amount = 1,
    Percentage = 2,
    LoyaltyCardPoints = 3
}

export const DiscountTypeDescription = {
    [EDiscountType.Amount]: 'Αξία',
    [EDiscountType.Percentage]: 'Ποσοστό',
    [EDiscountType.LoyaltyCardPoints]: 'Loyalty Cards'
}
export function getDiscountTypeSymbol(discountType: number): IconDefinition | null {
    switch (discountType) {
        case 1: return faEuroSign;
        case 2: return faPercent;
        case 3: return faCreditCard;
        default: return null;
    }
}

export const DiscountTypeOptions: SelectOption[] = [
    { value: EDiscountType.Amount, label: DiscountTypeDescription[EDiscountType.Amount] },
    { value: EDiscountType.Percentage, label: DiscountTypeDescription[EDiscountType.Percentage] },
    { value: EDiscountType.LoyaltyCardPoints, label: DiscountTypeDescription[EDiscountType.LoyaltyCardPoints] }
]