export function shallowEquals<T>(array1: T[] | undefined, array2: T[] | undefined): Boolean {
    if (array1 === undefined || array2 === undefined)
        return array1 === array2;

    let isEqual = array1.length === array2.length;

    if (!isEqual)
        return false;

    for(let i = 0; i < array1.length; i++) {
        isEqual &&= array1[i] === array2[i];
    }

    return isEqual;
}