import React,{useState} from 'react'
import { toast } from 'react-toastify';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { useAddCustomerMutation, useDeleteCustomerMutation } from '../../backoffice/apis/customerAPI';
import { confirmAlert } from 'react-confirm-alert';
import { Customer, validateCustomer } from '../../backoffice/dtos/customer/customer';
import { useTranslation } from 'react-i18next';

const CustomerEditModal = ({
    isOpen,
    toggle,
    selectedCustomer,
    setSelectedCustomer,
    
  }:{
    isOpen: boolean;
    toggle: () => void;
    selectedCustomer: Customer;
    setSelectedCustomer: React.Dispatch<React.SetStateAction<Customer | undefined>>;  
    }) => {

        const {t} = useTranslation(['customers','common'])
        const [ addCustomer ] = useAddCustomerMutation()
        const [ deleteCustomer ] = useDeleteCustomerMutation()
        const [formErrors,setFormErrors] = useState<Partial<Customer>>({})

        

        const updateCustomer = async () => {
            try {
              if(selectedCustomer){
                addCustomer(selectedCustomer)
                toast(t('successful_update_alert'))
                toggle()   
            }
            } catch (e:any) {
              toast(e.data.message)
            }
          }          

          const handleUpdate = async () => {
            const errors = validateCustomer(selectedCustomer)
            setFormErrors(errors);
          
            if (Object.entries(errors).length === 0) {
              await updateCustomer()
            }
          }

          const handleDeleteCustomer = () =>{
            confirmAlert({
              title: t('delete_alert_title'),
              message: t('delete_alert_description',{customer:selectedCustomer.shortDescription}),
              buttons: [{ label: t('yes',{ns:'common'}), onClick: async () =>  {
                try {
                  if (selectedCustomer){
                    deleteCustomer({ id: selectedCustomer.id }) 
                    toast(t('successful_delete_alert'))
                    toggle()
                }
                } catch (e:any) {
                  toast(e.data)
                }
              } }, { label: t('no',{ns:'common'}) }]
            })
          }
  return (
    <div>
        <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('edit',{ns:'common'})}</ModalHeader>
        {
          selectedCustomer !== undefined || null ?
          <ModalBody>
          <Label>{t('brand_name')}</Label>       
          <Input 
            placeholder={selectedCustomer? selectedCustomer.description : undefined} 
            value={selectedCustomer? selectedCustomer.description : undefined} 
            onChange={(e) => setSelectedCustomer({...selectedCustomer,description:e.target.value})} 
          />  
            <p style={{color:'red'}}>{formErrors.description}</p>
          <Label>{t('short_brand_name')}</Label>       
          <Input type="text"  
            placeholder={selectedCustomer? selectedCustomer.shortDescription : undefined}
            value={selectedCustomer? selectedCustomer.shortDescription : undefined}
            onChange={(e) => setSelectedCustomer({...selectedCustomer,shortDescription:e.target.value})}
          />  
            <p style={{color:'red'}}>{formErrors.shortDescription}</p>

          <Label>Payer Code:</Label>
          <Input 
            placeholder={selectedCustomer? selectedCustomer.payerOfAccountCode : undefined} 
            value={selectedCustomer? selectedCustomer.payerOfAccountCode : undefined} 
            onChange={(e) => setSelectedCustomer({...selectedCustomer,payerOfAccountCode:e.target.value})}
            />   
            <p style={{color:'red'}}>{formErrors.payerOfAccountCode}</p>
          <Label>Vendor Code</Label>      
          <Input type="text"  
            placeholder={selectedCustomer? selectedCustomer.vendorCode : undefined}
            value={selectedCustomer? selectedCustomer.vendorCode : undefined}
            onChange={(e) => setSelectedCustomer({...selectedCustomer,vendorCode:e.target.value})}
          />  
          <p style={{color:'red'}}>{formErrors.vendorCode}</p>
           
        </ModalBody>
          :<Spinner></Spinner>
        }
        
        <ModalFooter>
          <Button color="success" onClick={handleUpdate}>
            {t('update',{ns:'common'})}
          </Button>{' '}
          <Button color="danger" onClick={handleDeleteCustomer}>
          {t('delete',{ns:'common'})}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default CustomerEditModal