import { baseApi } from "./baseAPI";
import { RolesList } from "../dtos/authentication/role";
import { user } from "../dtos/authentication/user";

export const userApi = baseApi.injectEndpoints({    
    endpoints: (build) => ({
        getAllUsers: build.query<user[], void>({
            query: () =>{
                return 'User'
            },
            providesTags:() => [{type: 'Users', value: "LIST"}],
        }),                
        getAllRoles: build.query<RolesList, void>({
            query: () =>{
                return 'User/Roles'
            },
            providesTags:() => [],
        }),                
        updateUserRoles: build.mutation<user, user>({
            query: (body) => ({
                url: `User/Roles`,
                method: 'POST',
                body,
            }),
            invalidatesTags: () => [{type: 'Users', value:'LIST'}]
        }),                
        deleteUser: build.mutation<string, {userName: string}>({
            query: (body) => ({
                url: `User/Delete`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: () => [{type: 'Users', value:'LIST'}]
        }),                
    }),
    overrideExisting: false,
})

export const {
    useGetAllUsersQuery,useGetAllRolesQuery,useUpdateUserRolesMutation,useDeleteUserMutation
} = userApi