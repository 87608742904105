import { baseApi } from "./baseAPI"
import { UserLogin } from "../dtos/authentication/userLogin"
import { user } from "../dtos/authentication/user"

export const authenticationAPI = baseApi.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation<user, UserLogin>({
            query: (body) => ({
                url: `Authentication/User/Login`,
                method: 'POST',
                body,
            }),
            // transformErrorResponse: (response) => response.data
        }),
        getUser: build.query<user, void>({
            query: () => ({
                url: "Authentication/GetUser",
                method: "GET"
            })
        }),
        logout: build.query<void, void>({
            query: () =>{
                return 'Authentication/User/Logout'
            }
        })
    }),
    overrideExisting: false,
})

export const { useLoginMutation, useGetUserQuery, useLogoutQuery } = authenticationAPI
