import { FormEvent, createRef, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Input, InputGroup, InputGroupText, Label, Progress, Row } from "reactstrap";
import { ChangePasswordDto, changePasswordValidation } from "../../backoffice/dtos/authentication/ChangePasswordDto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export function ChangePasswordUI(
    {
        changePasswordRequest
    }:{
        changePasswordRequest: (changePassword: ChangePasswordDto) => Promise<void>
    }
){
    const [changePassword, setChangePassword] = useState<ChangePasswordDto>({ newPassword: "", existingPassword: "", repeatNewPassword: ""})
    
    const editValuesCallback = useCallback<React.ChangeEventHandler<HTMLInputElement>>((values) => {
        setChangePassword(ref => ({...ref, [values.target.name]: values.target.value}))
    }, [changePassword])

    const errors = useMemo(() => changePasswordValidation(changePassword), [changePassword])

    const errorReact = useMemo(() => {
        if (errors == null || errors.length === 0)
            return null;
        return errors.split("\n").map(m => <>{m}</>).reduce((acc, cur) => <>{acc}<br/>{cur}</>)
    }, [errors])

    const progressColor = useMemo(() => {
        if (changePassword.newPassword.length > 12)
            return "success";
        if (changePassword.newPassword.length > 8)
            return "primary";
        return "danger";
    }, [changePassword])

    const changePasswordCallback = useCallback(async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (errors !== null && changePassword.repeatNewPassword.length > 0)
            return;

        await changePasswordRequest(changePassword);
    }, [changePassword, errors]);

    const {t} = useTranslation(['user_info'])

    return (
        <>
            <Row tag="h3" className='text-align-start mx-3 header-opacity'>
                {t('basic_info')}
            </Row>
            <Row className='m-2' style={{background: "#0006", height:"1px"}}/>
            <Form onSubmit={changePasswordCallback} className="m-auto p-3" style={{maxWidth: "400px"}}>
                <Label className="text-align-start w-100" for="existingPassword">{t('user_password')}</Label>
                <Input name="existingPassword" type="password" onChange={editValuesCallback}/>
                <br/>
                <Label className="text-align-start w-100" for="newPassword">{t('new_password')}</Label>
                <Input name="newPassword" type="password" onChange={editValuesCallback}/>
                <br/>
                <Label className="text-align-start w-100" for="repeatNewPassword">{t('repeat_password')}</Label>
                <InputGroup>
                    <Input name="repeatNewPassword" type="password" onChange={editValuesCallback}/>

                    { errorReact !== null && changePassword.repeatNewPassword.length > 0 &&
                        <InputGroupText style={{background:"#c22", color: "white"}}><FontAwesomeIcon icon={faCircleXmark}/></InputGroupText>
                    }
                    { errorReact === null &&
                        <InputGroupText style={{background:"#383", color: "white"}}><FontAwesomeIcon icon={faCheckCircle}/></InputGroupText>
                    }
                </InputGroup>
                {errorReact !== null && <Label className="text-align-start w-100" for="repeatNewPassword" style={{color:"#ff0000"}}>{errorReact}</Label>}
                <br/>
                <Progress color={progressColor} value={Math.min(16, changePassword.newPassword.length)} max={16}/>
                <br/>
                <Row>
                    <Col md={6}/>
                    <Col md={6}>
                        <Button name="submit" className="w-100" type="submit">{t('change_button_text')}</Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}