import { Container, Row } from "reactstrap";
import { user } from "../../backoffice/dtos/authentication/user";
import { Promotion } from "../../backoffice/dtos/product/promotion";
import DashboardCard from "./DashboardCard";
import { useMemo } from "react";

export default function DashboardCustomerLine(
    {
        customerDescription,
        user,
        selectedElements,
        setSelectedElements,
        promotions,
        clickHandler
    }: {
        customerDescription: string,
        user: user | undefined,
        selectedElements: Map<number, { promotion: Promotion; approve: boolean; }>,
        setSelectedElements: (selectedElements: Map<number, { promotion: Promotion; approve: boolean; }>) => void,
        promotions: Promotion[],
        clickHandler: (promotionId: number) => void
    }
) {

    const sortedPromotions = useMemo(() => {
        var result = [...promotions];
        result = result.sort((a, b) => a.approvalStatus - b.approvalStatus)
        return result
    }, [promotions])

    return (
        <div className="p-3 mx-5 my-3">
            <Row className="gx-3 gy-3 mx-3">
                {customerDescription}
            </Row>
            <Row className="gx-3 gy-3 flex-nowrap overflow-auto p-3">
                {sortedPromotions.map(p => 
                    <DashboardCard user={user} selectedElements={selectedElements} setSelectedElements={setSelectedElements} promotion={p} clickHandler={clickHandler}/>)}
            </Row>
        </div>
    )
}