import { baseApi } from "./baseAPI"
import {Holiday} from "../dtos/holidays/holiday";
import { StrDateRange } from "../../helpers/Types";

export const holidaysAPI = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getMonthsHolidays: build.query<Holiday[], StrDateRange | undefined>({
            query: (dates) => {
                let url = "Holidays"; 
                if (dates)
                    url += `?fromDate=${dates.fromDate}&toDate=${dates.toDate}`;
                return url;
            },
            // Cache for id
            providesTags: (_result, _error, month) => [{ type: 'holiday', month }],
            // transformResponse: (rawResult: Promotion, meta) => fixDates(rawResult)
        })
    }),
    overrideExisting: false,
})

export const {
    useGetMonthsHolidaysQuery
} = holidaysAPI