import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap'
import { useAddShortDescriptionMutation } from '../../backoffice/apis/descriptionsAPI'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next';



const ProductEditModal = ({
  isOpen,
  toggle,
  selectedProduct,
  setSelectedProduct,
  
}:{
  isOpen: boolean;
  toggle: any;
  selectedProduct: any;
  setSelectedProduct: any;  
  }) => {  


 
const [ addShortDescription ] = useAddShortDescriptionMutation()

const {t} = useTranslation(['products','common'])

const handleUpdate = async () => {
  try {
    await addShortDescription(selectedProduct)
    toast(t('successful_update_alert'))
    toggle()   
  } catch (e:any) {
    toast(e.data.message)
  }
}


  return (
    <div>
        <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('edit',{ns:'common'})}</ModalHeader>
        {
          selectedProduct !== undefined || null ?
          <ModalBody>
          <Label>{t('code')}</Label>
          <Input placeholder={selectedProduct? selectedProduct.code : undefined}  disabled={true}/>   
          <Label>{t('description')}</Label>       
          <Input placeholder={selectedProduct? selectedProduct.description : undefined}  disabled={true}/>   
          <Label>{t('short_description')}</Label>      
          <Input type="text"  
            placeholder={selectedProduct? selectedProduct.shortDescription : undefined}
            onChange={(e) => setSelectedProduct({...selectedProduct,shortDescription:e.target.value})}
          />  
        </ModalBody>
          : <Spinner></Spinner>
        }
        
        <ModalFooter>
          <Button color="success" onClick={handleUpdate}>
          {t('update',{ns:'common'})}
          </Button>{' '}
          <Button color="danger" onClick={toggle}>
          {t('cancel',{ns:'common'})}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default ProductEditModal