
import { baseApi } from "./baseAPI";
import { Customer,  FromToOrder,  NewCustomer } from "../dtos/customer/customer";

export const customerApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getCustomers: build.query<Customer[], {query: string, includeDeleted: boolean}>({
            query: ({query, includeDeleted}) => {
                return `Customers?query=${query}&includeDeleted=${includeDeleted}`
            },
            providesTags:() => [{type: 'Customers', value: "LIST"}],

        }),        
        deleteCustomer: build.mutation<{message: string}, {id: number}>({
            query(id) {
                return {
                    url: `Customers/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (isUpdated) => [{type: 'Customers', value:'LIST'}]
        }),
        addCustomer: build.mutation<Customer, Customer | NewCustomer>({
            query: (body) => ({
                url: `Customers`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (isUpdated) => [{type: 'Customers', value:'LIST'}]
        }),
        updateCustomerOrder: build.mutation<void,FromToOrder>({
            query: (body) => ({
                url: `Customers/${body.fromId}`,
                method: 'PATCH',
                body: {order: body.toOrder}
        }),
        invalidatesTags: (isUpdated) => [{type: 'Customers', value:'LIST'}]
    }),       
        activateDeletedCustomer: build.mutation<void,{id: number}>({
            query: (id) => ({
                url: `Customers/Deleted/${id}`,
                method: 'PATCH',
                body:{}               
        }),
        invalidatesTags: (isUpdated) => [{type: 'Customers', value:'LIST'}]
    }),       
    }),
    overrideExisting: false,
})

export const {
    useGetCustomersQuery,
    useAddCustomerMutation,
    useDeleteCustomerMutation,
    useUpdateCustomerOrderMutation,
    useActivateDeletedCustomerMutation
} = customerApi