import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';

import React, {useCallback, useState} from 'react';
import { Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"


import PromotionHeaderForm from './PromotionHeaderForm';
import PromotionProductForm from './PromotionProductForm';
import { useGetPromotionQuery } from '../../backoffice/apis/promotionAPI';
import {CreateNewPromotionProduct, PromotionProduct} from '../../backoffice/dtos/product/promotionProduct';
import {getCanEdit, user} from "../../backoffice/dtos/authentication/user";
import { getUser } from '../../helpers/localStorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faX } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Promotion } from '../../backoffice/dtos/product/promotion';
import { ErrorComponent } from '../views/ErrorComponent';
import { getMessageFromRtkError } from '../../backoffice/apis/baseAPI';
import { Loading } from '../views/Loading';
import { PromotionDetailsForm } from './PromotionDetailsForm';


export default function PromotionComponent({
    promotionId,
    toggle,
    toggleCopyModal,
    isOpen,
    hidePromotionControls = false
}: {
    promotionId: number,
    toggle: () => void,
    toggleCopyModal: () => void,
    isOpen: boolean,
    hidePromotionControls ?: boolean
}) {
    const user = getUser();    
    const {t} = useTranslation(['promotion_timeline','common','promotion'])
    const { data: promotion, isFetching: isFetchingPromotion, error } =
        useGetPromotionQuery(promotionId, { skip: !isOpen })

    const [newProductState, setNewProductState] = useState<PromotionProduct | null>(null)
    const [activeTab, setActiveTab] = useState(0);
    const generateNewProduct = useCallback(() => {
        if (promotion)
            setNewProductState(CreateNewPromotionProduct(promotion.id))
    }, [setNewProductState, promotion])

    const headerKey = `promotion-header-${promotionId}`

    let ui: JSX.Element;

    if (isFetchingPromotion){
        ui = (
            <Loading />
        )
    } else if (error) {
        ui = (
            <ErrorComponent error={getMessageFromRtkError(error)}/>
        );
    } else if (!promotion) {
        ui = (
            <div>
                {t('no_promotion_found_error')}
            </div>
        )
    } /* else if (promotion.promotionInfo ) {
        ui = (
            <div >
                <Nav tabs>
                    <NavItem >
                        <NavLink onClick={() => setActiveTab(0)} active={activeTab === 0}> 
                            {t('promotion', { ns: 'common' })}
                        </NavLink>
                    </NavItem>
                    <NavItem >
                        <NavLink onClick={() => setActiveTab(1)} active={activeTab === 1 }>
                            {t('details', { ns: 'common' })}
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={0}>
                        <PromotionInnerComponent
                            headerKey={headerKey}
                            user={user}
                            promotion={promotion}
                            newProductState={newProductState}
                            toggle={toggle}
                            generateNewProduct={generateNewProduct}
                            setNewProduct={setNewProductState}/>
                    </TabPane>
                    <TabPane tabId={1}>
                        <PromotionDetailsForm
                            promotionInfo={promotion.promotionInfo} promotionId={promotionId}/>
                    </TabPane>
                </TabContent>
            </div>
        );
    }*/ else {
        ui = (
            <PromotionInnerComponent
                headerKey={headerKey}
                user={user}
                promotion={promotion}
                newProductState={newProductState}
                toggle={toggle}
                generateNewProduct={generateNewProduct}
                setNewProduct={setNewProductState}/>
        );
    }

    return (
        <React.Suspense fallback={<Loading/>}>
            <Row>
                <Col className='d-flex flex-column justify-content-end'>
                    <h5> {t('promotion_creation_title')}</h5>
                </Col>
                <Col sm={3} md={3} className='justify-content-end d-flex flex-row'>
                    <Button color='link' onClick={toggleCopyModal}><FontAwesomeIcon icon={faCopy}/></Button>
                    <Button color='link' onClick={toggle}><FontAwesomeIcon icon={faX}/></Button>
                </Col>
            </Row>
            <hr />
            {ui}
        </React.Suspense>    
    );
}

function PromotionInnerComponent({
    headerKey,
    user,
    promotion,
    newProductState,
    toggle,
    generateNewProduct,
    setNewProduct
}: {
    headerKey: string
    user: user | undefined
    promotion: Promotion
    newProductState: PromotionProduct | null 
    toggle: () => void
    generateNewProduct: () => void
    setNewProduct: (current: PromotionProduct | null) => void
}) {
    const {t} = useTranslation(['promotion_timeline','common','promotion'])
    const headerForm =
        <PromotionHeaderForm user={user} key={headerKey} promotion={promotion!} isLoading={false} toggle={toggle} hidePromotionControls={false} />

    const products = promotion.promotionProducts.map(pp => (
        <PromotionProductForm
            user={user}
            key={pp.id}
            promotionProduct={pp}
            promotionStatus={promotion.approvalStatus}
            setNewProduct={setNewProduct} />
    ))

    let newProduct = <></>
    if (user && getCanEdit(user) && newProductState !== null && promotion) {
        newProduct = <PromotionProductForm
            user={user}
            key={-1}
            promotionProduct={newProductState}
            promotionStatus={promotion.approvalStatus}
            setNewProduct={setNewProduct}
        />
    } else if (user && getCanEdit(user)) {
        newProduct = <Row className="p-2">
            <Col lg={8}/>
            <Col lg={4}>
                <Button className="w-100" onClick={generateNewProduct}>
                {t('add_product_button_text')}
                </Button>
            </Col>
        </Row>
    }

    return (
        <>
            {headerForm}
            <hr />
            <h5>{t('products')}</h5>
            <hr />
            {products}

            {newProduct}
        </>
    )
}
