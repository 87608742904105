import React,{useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next';
import { Form, Input, InputGroup, Button, Col } from 'reactstrap'

const ProductSearch = ({
    limit,
    setLimit,
    searchTerm,
    setSearchTerm
  }: {
    limit: number;
    setLimit: (value: number) => void;
    searchTerm: string;
    setSearchTerm: (value: string) => void;
  }) => {    

    const [debouncedTerm, setDebouncedTerm] = useState<string>(searchTerm)
    const {t} = useTranslation('common')

    const handleSearch = (event: { preventDefault: () => void; }) => {
        event.preventDefault()     
    }   
    

    useEffect(() => {
      const timer = setTimeout(() => setSearchTerm(debouncedTerm), 300);
      return () => clearTimeout(timer);
    }, [debouncedTerm])

  return (
      <>
        <Col lg={3}>
          <InputGroup>                
            <Button className={limit === 50? 'bg-primary w-50': 'w-25' } onClick={() => setLimit(50)}>50</Button>
            <Button className={limit === 100? 'bg-primary w-50': 'w-25' } onClick={() => setLimit(100)}>100</Button>
            <Button className={limit === 150? 'bg-primary w-50': 'w-25' } onClick={() => setLimit(150)}>150</Button>
          </InputGroup>
        </Col>
        <Col>
          <Form onSubmit={handleSearch}>            
              <InputGroup>
                  <Input type='text' placeholder= {t('search')} onChange={(e) => setDebouncedTerm(e.target.value)} />
                  <Button onClick={handleSearch}>                    
                      <span className="fa fa-search bg- fa-lg"></span>
                  </Button>
              </InputGroup>                     
          </Form>
        </Col>
      </>
  )
}

export default ProductSearch