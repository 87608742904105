import { baseApi } from "./baseAPI"
import { Promotion } from "../dtos/product/promotion"
import { CopiedPromotion} from "../dtos/product/CopiedPromotion"
import { PromotionCriteria, promotionCriteriaToQuery } from "../dtos/product/promotionCriteria"
import { PromotionProduct } from "../dtos/product/promotionProduct"
import {PromotionsToChange} from "../dtos/product/promotionsToChange";
import { PromotionPartialChange } from "../../helpers/Types";
import { handleUrl } from "../../helpers/utils";
import { MassSubmitResult } from "../dtos/promotion/MassSubmitResult";
import { PromotionInfo } from "../dtos/product/PromotionInfo"
import { CloneToManyRequest } from "../dtos/product/CloneToManySettings"

export const promotionApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getPromotion: build.query<Promotion, number>({
            query: (id) => ({ url: `Promotions/${id}` }),
            // Cache for id
            providesTags: (_result, _error, id) => [{ type: 'Promotion', id }],
            // transformResponse: (rawResult: Promotion, meta) => fixDates(rawResult)
        }),
        getPromotionByIdOrSap: build.query<Promotion[], string>({
            query: (id) => ({ url: `Promotions/sap/${id}` }),
            
            // Cache for id
            providesTags: (_result, _error, id) => {
                return [{ type: 'Promotion', id }];
            },
            // transformResponse: (rawResult: Promotion, meta) => fixDates(rawResult)
        }),
        getPromotions: build.query<Promotion[], PromotionCriteria>({
            query: (criteria: PromotionCriteria) => {
                return handleUrl("Promotions", promotionCriteriaToQuery(criteria));
            },
            // Cache by id
            providesTags: (result) => result
                ? [
                    ...result.map(({ id }) => ({ type: 'Promotion' as const, id })),
                    { type: 'Promotion', id: 'LIST' },
                ]
                : [{ type: 'Promotion', id: 'LIST' }],
            // transformResponse: (rawResults: Promotion[], meta) => rawResults.map(r => fixDates(r)),
        }),
        addPromotion: build.mutation<Promotion, Partial<Promotion>>({
            query: (body) => ({
                url: `Promotions`,
                method: 'POST',
                body,
            }),
            transformErrorResponse: (response) => response.data,
            invalidatesTags: [{ type: 'Promotion', id: 'LIST' }, { type: 'TimelineResource', id: "LIST" }],
        }),
        clonePromotion: build.mutation<Promotion, {id: number, newCustomerId: number, body: PromotionPartialChange | null}>({
            query: ({ id, newCustomerId, body }) => ({
                url: `Promotions/${id}/customer/${newCustomerId}`,
                method: 'POST',
                body,
            }),
            transformErrorResponse: (response) => response.data,
            invalidatesTags: [{ type: 'Promotion', id: 'LIST' }, { type: 'TimelineResource', id: "LIST" }],
        }),
        clonePromotionToMany: build.mutation<CopiedPromotion[], CloneToManyRequest>({
            query: (request) => ({
                url: `Promotions/${request.promotionId}/customers/copy`,
                method: 'POST',
                body: request.body,
            }),
            transformErrorResponse: (response) => response.data,
            invalidatesTags: [{ type: 'Promotion', id: 'LIST' }, { type: 'TimelineResource', id: "LIST" }],
        }),
        updatePromotion: build.mutation<void, Pick<Promotion, 'id'> & Partial<Promotion>>({
            query: ({ id, ...promotion }) => ({
                url: `Promotions/${id}`,
                method: 'PUT',
                body: promotion,
            }),
            transformErrorResponse: (response) => response.data,
            // Invalidate Cache for id
            invalidatesTags: (_result, _error, { id }) => [{ type: 'Promotion', id }, { type: 'TimelineResource', id: "LIST" }],
        }),
        approvePromotion: build.mutation<void, {id: number} & {description: string}>({
            query: ({ id, description }) => ({
                url: `Promotions/${id}/approve`,
                method: 'PATCH',
                body: {'description': description}
            }),
            transformErrorResponse: (response) => response.data,
            // Invalidate Cache for id
            invalidatesTags: (_result, _error, { id }) => [{ type: 'Promotion', id }, { type: 'TimelineResource', id: "LIST" }],
        }),
        rejectPromotion: build.mutation<void, {id: number} & {description: string}>({
            query: ({ id, description }) => ({
                url: `Promotions/${id}/reject`,
                method: 'PATCH',
                body: {'description': description}
            }),
            transformErrorResponse: (response) => response.data,
            // Invalidate Cache for id
            invalidatesTags: (_result, _error, { id }) => [{ type: 'Promotion', id }, { type: 'TimelineResource', id: "LIST" }],
        }),
        stateChangePromotions: build.mutation<MassSubmitResult, PromotionsToChange>({
            query: (promotionsToChange) => ({
                url: `Promotions/ApprovalStateChange`,
                method: 'PATCH',
                body: promotionsToChange
            }),
            transformErrorResponse: (response) => response.data,
            // Invalidate Cache for id
            invalidatesTags: (_result, _error, { promotionStates }) => promotionStates.map(it => {
                return {type: 'Promotion', id: it.id}
            }),
        }),
        deletePromotion: build.mutation<{ success: boolean; id: number }, number>({
            query(id) {
                return {
                    url: `Promotions/${id}`,
                    method: 'DELETE',
                }
            },
            transformErrorResponse: (response) => response.data,
            invalidatesTags: (_result, _error, id) => [{ type: 'Promotion', id }, { type: 'TimelineResource', id: "LIST" }],
        }),
        addPromotionProduct: build.mutation<PromotionProduct, Partial<PromotionProduct>>({
            query: (promotionProduct) => ({
                url: `Promotions/${promotionProduct.promotionId}/Products`,
                method: 'POST',
                body: promotionProduct,
            }),
            transformErrorResponse: (response) => response.data,
            invalidatesTags: (_result, _error, { promotionId }) => [{ type: 'Promotion', promotionId }, { type: 'TimelineResource', id: "LIST" }],
        }),
        updatePromotionProduct: build.mutation<void, Partial<PromotionProduct>>({
            query: (promotionProduct) => ({
                url: `Promotions/${promotionProduct.promotionId}/Products`,
                method: 'PUT',
                body: promotionProduct,
            }),
            // Invalidate Cache for id
            transformErrorResponse: (response) => response.data,
            invalidatesTags: (_result, _error, { promotionId }) => [{ type: 'Promotion', promotionId }, { type: 'TimelineResource', id: "LIST" }],
        }),
        alterPromotionProduct: build.mutation<void, {id: number, body:PromotionPartialChange}>({
            query: ({id, body}) => ({
                url: `Promotions/${id}/alter`,
                method: 'PATCH',
                body: body,
            }),
            // Invalidate Cache for id
            transformErrorResponse: (response) => response.data,
            invalidatesTags: (_result, _error, { id }) => [{ type: 'Promotion', id }, { type: 'TimelineResource', id: "LIST" }],
        }),
        deletePromotionProduct: build.mutation<void, Partial<PromotionProduct>>({
            query(promotionProduct) {
                return {
                    url: `Promotions/${promotionProduct.promotionId}/Products/${promotionProduct.id}`,
                    method: 'DELETE',
                }
            },
            transformErrorResponse: (response) => response.data,
            invalidatesTags: (_result, _error, promotionId) => [{ type: 'Promotion', promotionId }, { type: 'TimelineResource', id: "LIST" }],
        }),
        alterSapPromotion: build.mutation<void, {id: number, info: Partial<PromotionInfo>}>({
            query(promotionInfo) {
                return {
                    url: `Sap/promotion/${promotionInfo.id}`,
                    method: 'PATCH',
                    body: promotionInfo.info
                }
            },
            invalidatesTags: (_result, _error, promotionInfo) => [{ type: 'Promotion', promotionInfo}]
        }),
        sendPromotionToSap: build.mutation<void, number>({
            query(id) {
                return {
                    url: `Sap/promotion/${id}/send`,
                    method: 'POST'
                }
            },
            invalidatesTags: (_result, _error, promotionId) => [{ type: 'Promotion', promotionId}]
        })
    }),
    overrideExisting: false,
})

export const {
    useGetPromotionQuery, useGetPromotionsQuery,useGetPromotionByIdOrSapQuery,
    useAddPromotionMutation, useAlterPromotionProductMutation, useUpdatePromotionMutation, useDeletePromotionMutation,
    useAddPromotionProductMutation, useUpdatePromotionProductMutation, useDeletePromotionProductMutation,
    useClonePromotionMutation,
    useApprovePromotionMutation, useRejectPromotionMutation, useStateChangePromotionsMutation,useClonePromotionToManyMutation,
    useAlterSapPromotionMutation, useSendPromotionToSapMutation
} = promotionApi
