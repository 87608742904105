import React,{useEffect, useMemo, useState} from 'react'
import { ResetPasswordDto,resetPasswordValidation } from '../../backoffice/dtos/authentication/ChangePasswordDto'
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, InputGroup,Label, Modal, ModalBody, ModalHeader, Progress, Row, Spinner } from 'reactstrap'
import { useNavigate,useParams } from 'react-router'
import { resetPassword, tokenExist } from '../../backoffice/apis/axios'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { supportedLanguages } from '../../i18n/i18nProps'
import ReactCountryFlag from 'react-country-flag'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const ResetPassword = () => {
    const parms = useParams()    
    const navigate = useNavigate()

    const [formData, setFormData] = useState<ResetPasswordDto>({token:parms.token, username: "", password: "", repeatPassword: ""})    
    const [formError,setFormError] = useState<string | null>()
    const [show,setShow] = useState<boolean>(false)
    const [isLoading,setLoader] = useState<boolean>(false)
    const [globeOpen, setGlobeOpen] = useState(false);

    const {t} = useTranslation(['login','user_info'])


    const progressColor = useMemo(() => {
        if (formData.password.length > 12)
            return "success";
        if (formData.password.length > 8)
            return "primary";
        return "danger";
    }, [formData])

    
    const tokenExistAsync = async () => {
        try {
            if (parms.token){
                const {data} = await tokenExist(parms.token)                
            }
        } catch (error:any) {
            setShow(true)
        }
    }

    useEffect(()=>{
       tokenExistAsync()
    },[])

    const resetPasswordAsync = async () => {
        try {
            setLoader(true)
            const {data} = await resetPassword(formData)
            toast(data)
            navigate("/login")
        } catch (error:any) {
            toast(error.response.data)
        }
        setLoader(false)
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();         
        const errors = resetPasswordValidation(formData);
        setFormError(errors);        
        if ( errors === null) {
            setFormError("")
           resetPasswordAsync()
        }      
    }

return (
<div className='bg-login-lvl-3 d-flex vh-100 align-items-center justify-content-center'>
        <div >
            <div className='button-logout ms-auto d-block'>
                <Dropdown nav className='list-unstyled' isOpen={globeOpen} toggle={() => setGlobeOpen(!globeOpen)}>
                    <DropdownToggle>
                        <FontAwesomeIcon icon={faGlobe} />
                    </DropdownToggle>
                    <DropdownMenu>
                        {supportedLanguages.map((item, index) => {
                            return (
                                <DropdownItem key={index} className={i18next.language === item.code ? 'bold' : ''} onClick={() => i18next.changeLanguage(item.code)}>
                                    <ReactCountryFlag
                                        className="emojiFlag"
                                        countryCode={item.countryCode}
                                        style={{
                                            fontSize: '1.2em',
                                            lineHeight: '1.2em',
                                        }}
                                        aria-label="United States"
                                    />
                                    <span className='mx-2'>{item.name}</span>
                                </DropdownItem>
                            )
                        })}
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
        <div className='bg-lvl-3 rounded-3 shadow-sm p-3' >
            <img src='../../images/logo.jpg' className='img-fluid' alt='Logo Αθηναϊκής Ζυθοποιίας'></img>
            <Form onSubmit={handleSubmit} className="m-auto p-3 text-align-start" style={{ maxWidth: "400px" }}>
                <Label className="text-align-start w-100" for="username">{t('username')}</Label>
                <Input type="text" onChange={(e) => setFormData({ ...formData, username: e.target.value })} />
                <br />

                <Label className="text-align-start w-100" for="newPassword">{t('new_password',{ns:'user_info'})}</Label>
                <Input type="password" onChange={(e) => setFormData({ ...formData, password: e.target.value })} />

                <Label className="text-align-start w-100" for="repeatNewPassword">{t('repeat_password',{ns:'user_info'})}</Label>
                <Input type="password" onChange={(e) => setFormData({ ...formData, repeatPassword: e.target.value })} />                    
                {formError && <Label className="text-align-start w-100" for="repeatNewPassword" style={{ color: "#ff0000" }}>{formError}</Label>}
                <Progress className="my-2" color={progressColor} value={Math.min(16, formData.password.length)} max={16} />
                <br />
                
                <Row className='gy-3'>
                        <Col sm={5}>
                            <Button className='w-100' outline onClick={() => navigate("/login")}>{t('return')}</Button>
                        </Col>
                        <Col sm={7}>
                            <Button 
                                className="w-100" 
                                onClick={handleSubmit} 
                                color="primary"
                                disabled={isLoading}
                                >
                                { isLoading && <Spinner size="sm"/>} Αλλαγή
                            </Button>   
                        </Col>
                    </Row>
            </Form>
        </div>

        <Modal size="md" isOpen={show} >        
            <ModalBody >
                <ModalHeader>{t('no_token_exist_title')}</ModalHeader>
                <ModalBody>
                    <Row className='m-2'>
                        {t('no_token_exist_description')}
                    </Row>
                    <br/>
                    <Row className='gy-3'>
                        <Col md={5}>
                            <Button className='w-100' onClick={()=>navigate("/login")}>{t('login_button_text')}</Button>
                        </Col>
                        <Col md={7}>
                            <Button className='w-100' color="primary" onClick={()=>navigate("/reset/password/request")} >{t('reset_button_text')}</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </ModalBody>
        </Modal>
</div>

)
}

export default ResetPassword