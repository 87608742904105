import { user } from "../backoffice/dtos/authentication/user"

export const setUser = (user: user) =>{
    sessionStorage.setItem('user', JSON.stringify(user))
    window.dispatchEvent(new StorageEvent('localstorage-user-changed'))
}

export const getUser = (): user | undefined => {
    const value = sessionStorage.getItem('user')

    if (!value)
        return undefined

    return JSON.parse(value)
}

export const clearLocalStorage = () =>{
    if (sessionStorage.getItem('user')) {
        sessionStorage.removeItem('user')
        window.dispatchEvent(new StorageEvent('localstorage-user-changed'))
    }
}
