import "./table.css"
import React, { useMemo } from "react";
import { useCallback, useState } from "react";
import { Collapse, Container } from "reactstrap";

interface TableArguments extends React.HTMLAttributes<HTMLElement>{
    __TYPE ?: string;
}
export function Table(props: TableArguments) {
    if (props.__TYPE !== "__TPM__Table__")
        throw new Error("Please do not alter the __TYPE prop" )

    return (
        <Container className={["tpm_table",(props.className ?? "")].join(" ")}>
            {props.children}
        </Container>
    )
}

(Table as any).defaultProps = {
    __TYPE: "__TPM__Table__"
}