export const defaultLanguage = "en-US";

export const supportedLanguages = [
  { code: "en-US", name: "English" , countryCode:'US'},
  { code: "el-GR", name: "Ελληνικά", countryCode:'GR'},
];

export const supportedILanguages = [
  "en",
  "el"
];