import "./table.css"
import { Col, Collapse, Row } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { useMemo, useState } from "react"
import React from "react"

interface TableSectionArguments extends React.HTMLAttributes<HTMLElement> {
    expanded ?: boolean
}

export function TableRow(props: TableSectionArguments) {
    const [expanded, setExpanded] = useState(false);
    
    const table = useMemo(() => {
        if (!props.children)
            return undefined;

        var nodes = React.Children.toArray(props.children as any)
            .filter(m => (m as any).props?.__TYPE === "__TPM__Table__");

        return nodes.length > 0 ? nodes[0] : undefined;
    }, [props.children]);

    const otherChildren = useMemo(() => {
        if (!props.children)
            return undefined;

        var nodes = React.Children.toArray(props.children as any)
            .filter(m => (m as any).props?.__TYPE !== "__TPM__Table__");

        return nodes;
    }, [props.children]);

    const hasTable = useMemo(() => {
        return table !== undefined
    }, [table])

    const expand = useMemo(() => {
        if (!hasTable)
            return undefined;
        return () => setExpanded(prev => !prev)
    }, [hasTable])

    return (
        <>
            <Row className={["cursor-pointer", "unselectable", "align-items-center", "p-2", "rounded", expanded ? "gray" : "", (props.className ?? "")].join(" ")} onClick={expand}>
                <Col className={hasTable ? "" : "invisible"} md="auto"><FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown}/>{" "}</Col>
                <>
                    {otherChildren}
                </>
            </Row>
            <div className="m-2">
                {hasTable && expanded && (
                    <Collapse isOpen={expanded}>
                        {table}
                    </Collapse>
                )}
            </div>
        </>
    )
}