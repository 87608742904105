import React from "react";
import { useMemo, useState } from "react";
import { Card } from "reactstrap";

export interface AccordionProps extends React.HTMLAttributes<HTMLElement> {
    expandedElement ?: string
}

export function Accordion(props: AccordionProps) {
    const [expandedElement, setExpanded] = useState<string | undefined>(props.expandedElement);

    const children = useMemo(() => {
        if (!props.children)
            return <></>

        return React.Children.map(props.children, (child: any) => {
            const prps = child.props as any
            if (React.isValidElement(child) && prps.id && typeof prps.id === "string"){
                return React.cloneElement(child, 
                    { 
                        isExpanded: prps.id === expandedElement,
                        setExpandedId: setExpanded
                    } as any
                ) as any
            }
            return child as any
        })
    }, [props.children, expandedElement])
    return <Card>
        {children}
    </Card>
}