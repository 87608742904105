import { baseApi } from "./baseAPI"
import { Product } from "../dtos/promotion/product"
import { ProductCriteria } from "../dtos/promotion/productCriteria"

export const productApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getProducts: build.query<Product[], ProductCriteria>({
            query: (criteria: ProductCriteria) => {
                let queryString = "Products"
                let params: string[] = []
                if (criteria.code)
                    params.push(`code=${criteria.code}`)
                if (criteria.description)
                    params.push(`fromDate=${criteria.description}`)
                if (params.length > 0)
                    queryString = `${queryString}?${params.join("&")}`

                return queryString
            },
            // Cache by id
            providesTags: (result) => result
                ? [
                    ...result.map(({ code }) => ({ type: 'Product' as const, code })),
                    // { type: 'Product', id: 'LIST' },
                ]
                : [{ type: 'Promotion', id: 'LIST' }],
            // transformResponse: (rawResults: Promotion[], meta) => rawResults.map(r => fixDates(r)),
        }),
        getProduct: build.query<Product, number>({
            query: (id) => ({ url: `Products/${id}` }),
            // Cache for id
            providesTags: (_result, _error, id) => [{ type: 'Product', id }],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetProductQuery, useGetProductsQuery
} = productApi