import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { Button, ButtonGroup, Col, Container, Spinner } from "reactstrap";

import { Promotion } from "../../backoffice/dtos/product/promotion";
import { useGetPromotionsQuery, useStateChangePromotionsMutation } from "../../backoffice/apis/promotionAPI";
import DashboardCustomerLine from "./DashboardCustomerLine";
import { useGetCustomersQuery } from "../../backoffice/apis/customerAPI";
import { getUser } from "../../helpers/localStorage";
import { useSetPromotionId } from "../../app/hooks";
import { useTranslation } from "react-i18next";
import { PromotionCriteria, PromotionCriteriaComparison } from "../../backoffice/dtos/product/promotionCriteria";
import CriteriaModal from "../promotions/views/CriteriaModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleInfo, faExclamationCircle, faFileExcel, faFilter, faInfo, faShare, faX, faXmark, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { getMessageFromRtkError } from "../../backoffice/apis/baseAPI";
import { Table } from "../views/common/Table/Table";
import { TableHeader } from "../views/common/Table/TableHeader";
import { TableBody } from "../views/common/Table/TableBody";
import { TableRow } from "../views/common/Table/TableRow";
import { getCanReject, getCanPromote, user } from "../../backoffice/dtos/authentication/user";
import { PromotionProduct } from "../../backoffice/dtos/product/promotionProduct";
import { formatToDMY } from "../../helpers/dateHelper";
import { PromotionApprovalModel } from "./models/promotionApprovalModel";
import { confirmAlert } from "react-confirm-alert";
import { PromotionApproveState } from "../../backoffice/dtos/product/promotionApproveState";
import { toast } from "react-toastify";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import { downloadExcel } from "../../backoffice/apis/excelAPI";
import { ApprovalStatusColor, EApprovalStatus, getApprovalStatusSymbol } from "../../enums/EApprovalStatus";

const initialCriteria: PromotionCriteria = { onlyAvailableToPromote: true };
export default function Dashboard() {
    const user = getUser()
    const { t } = useTranslation(['dashboard'])

    const setPromotionId = useSetPromotionId();

    const [criteraModalOpen, setCriteriaModalOpen] = useState(false);
    const [selectedElements, setSelectedElements] = useState<Map<number, { promotion: Promotion, approve: boolean }>>(new Map())

    const [promotionCriteria, setPromotionCriteria] = useState<PromotionCriteria>(initialCriteria)
    const [changeStateOnPromotions] = useStateChangePromotionsMutation()

    const { data: allpromotions, error: promotionsError, isFetching } = useGetPromotionsQuery(promotionCriteria, { refetchOnMountOrArgChange: true });

    const { data: customers, error: customersError, isFetching: isFetchingCustomers } = useGetCustomersQuery({ query: "", includeDeleted: false });

    const promotions = useMemo(() => {
        if (!allpromotions)
            return allpromotions;

        return allpromotions
    }, [allpromotions])

    const approveSelected = useMemo(() => {
        const usr = user;
        if (!usr || !promotions)
            return null;

        const promotionsToPromote = promotions
            .filter(p => p !== undefined && getCanPromote(usr, p));

        if (promotionsToPromote.length === 0)
            return null;

        return promotionsToPromote
            .every(p => selectedElements.get(p!!.id)?.approve === true);
    }, [promotions, selectedElements])

    const rejectedSelected = useMemo(() => {
        const usr = user;
        if (!usr || !promotions)
            return null

        const promotionsToPromote = promotions
            .filter(p => p !== undefined && getCanPromote(usr, p));

        if (promotionsToPromote.length === 0)
            return null;

        return promotionsToPromote.every(p => selectedElements.get(p!!.id)?.approve === false);
    }, [promotions, selectedElements])

    const approvalFlow = useMemo(() => {
        const action = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            if (!user || !promotions)
                return
            for (const promotion of promotions.filter(p => getCanPromote(user, p))) {
                if (!approveSelected) {
                    selectedElements.set(promotion.id, {
                        approve: true,
                        promotion: promotion
                    });
                } else {
                    selectedElements.delete(promotion.id);
                }
            }
            const newElements = new Map(selectedElements);
            setSelectedElements(newElements);
        }
        return action
    }, [user, promotions, approveSelected, selectedElements, setSelectedElements])

    const rejectFlow = useMemo(() => {
        const action = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            if (!user || !promotions)
                return
            for (const promotion of promotions.filter(p => getCanReject(user, p))) {
                if (!rejectedSelected) {
                    selectedElements.set(promotion.id, {
                        approve: false,
                        promotion: promotion
                    });
                } else {
                    selectedElements.delete(promotion.id);
                }
            }
            const newElements = new Map(selectedElements);
            setSelectedElements(newElements);
        }
        return action
    }, [user, promotions, rejectedSelected, selectedElements, setSelectedElements])


    const clickHandler = useCallback((promotionId: number) => {
        setPromotionId(promotionId)
    }, [])

    const customersSorted = useMemo(() => {
        if (!customers)
            return undefined;
        return [...customers].sort((a, b) => a.order - b.order);
    }, [customers])

    const companySeperatedPromotions = useMemo(() => {
        if (!promotions)
            return undefined;

        return promotions.reduce((groups, item) => {
            let customerPromotions = groups.get(item.customerId);

            if (customerPromotions == null) {
                customerPromotions = { customerDescription: item.customerDescription, promotions: [] };
                groups.set(item.customerId, customerPromotions);
            }
            customerPromotions.promotions.push(item);

            return groups;
        }, new Map<number, { customerDescription: string; promotions: Promotion[]; }>());
    }, [promotions])


    const submitElements = useCallback(async (promotions: PromotionApprovalModel[]) => {
        try {
            const promotionStates: Array<PromotionApproveState> = [];
            for (const value of promotions) {
                promotionStates.push(
                    {
                        id: value.promotion.id,
                        isPromoted: value.approve
                    }
                )
            }

            if (promotionStates.length > 0) {
                const promotionsToChange = { promotionStates, description: "" };
                const stateChangePromise = await changeStateOnPromotions(promotionsToChange).unwrap();
                toast(t('successful_edit_alert', { success: stateChangePromise.success, total: stateChangePromise.total }))

            }

            setSelectedElements(new Map());
        } catch (e) {
            toast(t('unsuccessful_edit_alert', { error: e }))
        }
    }, [changeStateOnPromotions]);

    const askToSubmit = useCallback((res: PromotionApprovalModel[]) => {
        confirmAlert({
            title: t('submit_confirm_alert_title'),
            message: t('submit_confirm_alert_desctription'),
            buttons: [
                { label: t('yes', { ns: 'common' }), onClick: () => submitElements(res) },
                { label: t('no', { ns: 'common' }), onClick: () => { } }]
        });
    }, [submitElements]);

    const submitSelected = useCallback(() => {
        const res = [...selectedElements.entries()].map(m => m[1]);
        askToSubmit(res)
    }, [selectedElements, askToSubmit])

    let pendingPromotions = <></>;
    if (isFetching) {
        pendingPromotions = (
            <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
                <Spinner />
                <div>
                    <strong>{t('pending_loading')}</strong>
                </div>
            </div>
        );
    } else if (promotionsError) {
        pendingPromotions = (
            <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
                <FontAwesomeIcon icon={faExclamationCircle} size="5x" color="red" />
                <div>
                    <strong>{getMessageFromRtkError(promotionsError)}</strong>
                </div>
            </div>
        );
    } else if (!companySeperatedPromotions || companySeperatedPromotions.size === 0) {
        pendingPromotions = (
            <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
                <FontAwesomeIcon icon={faCheckCircle} size="5x" color="green" />
                <div>
                    <strong>{t('no_pending')}</strong>
                </div>
            </div>
        );
    } else if (isFetchingCustomers || !customersSorted) {
        pendingPromotions = (
            <div>
                {[...companySeperatedPromotions.entries()].map(p =>
                    <DashboardCustomerLine customerDescription={p[1].customerDescription} user={user} selectedElements={selectedElements} setSelectedElements={setSelectedElements} promotions={p[1].promotions} clickHandler={clickHandler} />)}
            </div>
        );
    } else {
        const customerPromotions = [...customersSorted]
            .map(m => ({ customer: m, products: companySeperatedPromotions.get(m.id) }))
            .filter(m => m.products !== undefined && m.products.promotions.length > 0);

        pendingPromotions = (
            <>
                <Table className="mt-2 shadow">
                    <TableHeader className="bold first-layer-header">
                        <Col>{t("retail_chains")}</Col>
                        <Col className={["p-0 ps-1", approveSelected === null ? "invisible" : ""].join(" ")} md="auto">
                            <Button outline={!approveSelected} className="w-100" color="success" onClick={approvalFlow} title={t("select_all_for_approval")}>
                                <FontAwesomeIcon icon={faCheckCircle} />
                            </Button>
                        </Col>
                        <Col className={["p-0 ps-1", rejectedSelected === null ? "invisible" : ""].join(" ")} md="auto">
                            <Button outline={!rejectedSelected} className="w-100" color="danger" onClick={rejectFlow} title={t("select_all_for_rejection")}>
                                <FontAwesomeIcon icon={faXmarkCircle} />
                            </Button>
                        </Col>
                    </TableHeader>
                    <TableBody>
                        {customerPromotions
                            .map(p => (
                                <CustomerRow customerDescription={p.customer.description} user={user} selectedElements={selectedElements} setSelectedElements={setSelectedElements} promotions={p.products!!.promotions} launchPromotion={clickHandler} />
                            ))
                        }
                    </TableBody>
                </Table>
            </>
        );
    }

    return (
        <>
            <Container className="mt-3 p-1 d-flex justify-content-between align-items-center">
                <div>
                    <ButtonGroup>
                        <Button onClick={() => setCriteriaModalOpen(prev => !prev)}>
                            <FontAwesomeIcon icon={faFilter} />
                        </Button>
                        {!PromotionCriteriaComparison(promotionCriteria, initialCriteria) && (
                            <Button color="danger" onClick={() => setPromotionCriteria(initialCriteria)}><FontAwesomeIcon icon={faXmark} /></Button>
                        )}
                    </ButtonGroup>{" "}
                    <Button color="success" onClick={async () => await downloadExcel(promotionCriteria)}>
                        <FontAwesomeIcon icon={faFileExcel} />
                    </Button>
                </div>

                <ButtonGroup>
                    <Button color="primary" onClick={() => submitSelected()} disabled={selectedElements.size === 0}>
                        {t('submit', { ns: 'common' })}
                    </Button>
                    {selectedElements.size > 0 && (
                        <Button color="danger" onClick={() => setSelectedElements(new Map())}><FontAwesomeIcon icon={faXmark} /></Button>
                    )}
                </ButtonGroup>
            </Container>
            <div className="d-flex flex-column flex-fill">

                {pendingPromotions}

                <CriteriaModal isOpen={criteraModalOpen} toggle={() => setCriteriaModalOpen(prev => !prev)} currentFilter={promotionCriteria} setSelectedPromotionCriteria={setPromotionCriteria} initialFilter={initialCriteria} />
            </div>
        </>
    )

}

interface CustomerRowAttributes extends React.HTMLAttributes<HTMLElement> {
    user: user | undefined;
    customerDescription: string
    promotions: Promotion[]
    launchPromotion: (promotionId: number) => void
    selectedElements: Map<number, { promotion: Promotion, approve: boolean }>
    setSelectedElements: Dispatch<SetStateAction<Map<number, { promotion: Promotion, approve: boolean }>>>
}
function CustomerRow(props: CustomerRowAttributes) {
    const { t } = useTranslation(['dashboard']);


    const approveSelected = useMemo(() => {
        const user = props.user;
        if (!user)
            return null;
        const customerPromotions = props.promotions.filter(m => getCanPromote(user, m));

        if (customerPromotions.length === 0)
            return null;

        return customerPromotions.every(p => props.selectedElements.get(p.id)?.approve === true);
    }, [props.selectedElements])

    const rejectSelected = useMemo(() => {
        const user = props.user;
        if (!user)
            return null;

        const customerPromotions = props.promotions.filter(m => getCanReject(user, m));

        if (customerPromotions.length === 0)
            return null;

        return customerPromotions.every(p => props.selectedElements.get(p.id)?.approve === false);
    }, [props.selectedElements])

    const approvalFlow = useMemo(() => {
        const action = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            if (!props.user)
                return
            for (const promotion of props.promotions) {
                if (getCanPromote(props.user, promotion)) {
                    if (!approveSelected) {
                        props.selectedElements.set(promotion.id, {
                            approve: true,
                            promotion: promotion
                        });
                    } else {
                        props.selectedElements.delete(promotion.id);
                    }
                }
            }
            const newElements = new Map(props.selectedElements);
            props.setSelectedElements(newElements);
        }
        return action
    }, [props.user, props.promotions, approveSelected, props.selectedElements, props.setSelectedElements])

    const rejectFlow = useMemo(() => {
        const action = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            if (!props.user)
                return
            for (const promotion of props.promotions) {
                if (getCanReject(props.user, promotion)) {
                    if (!rejectSelected) {
                        props.selectedElements.set(promotion.id, {
                            approve: false,
                            promotion: promotion
                        });
                    } else {
                        props.selectedElements.delete(promotion.id);
                    }
                }
            }
            const newElements = new Map(props.selectedElements);
            props.setSelectedElements(newElements);
        }
        return action
    }, [props.user, props.promotions, rejectSelected, props.selectedElements, props.setSelectedElements])


    return (
        <>
            <TableRow>
                <Col>{props.customerDescription}</Col>
                <Col className={["p-0 ps-1", approveSelected === null ? "invisible" : ""].join(" ")} md="auto">
                    <Button outline={!approveSelected} className="w-100" color="success" onClick={approvalFlow} title={t("select_companys_promotions_for_approval")}>
                        <FontAwesomeIcon icon={faCheckCircle} />
                    </Button>
                </Col>
                <Col className={["p-0 ps-1", rejectSelected === null ? "invisible" : ""].join(" ")} md="auto">
                    <Button outline={!rejectSelected} className="w-100" color="danger" onClick={rejectFlow} title={t("select_companys_promotions_for_rejection")}>
                        <FontAwesomeIcon icon={faXmarkCircle} />
                    </Button>
                </Col>
                <Table>
                    <TableHeader className="bold second-layer-header">
                        <Col md="auto"><FontAwesomeIcon className="icon" icon={faCircleInfo} /></Col>
                        <Col md={1}>{t("TPM")}</Col>
                        <Col md={0}>{t("ERP")}</Col>
                        <Col md={3}>{t("duration")}</Col>
                        <Col className="text-align-end" md={0}>{t("estimate_price")}</Col>
                        <Col className="text-align-end" md={0}>{t("actual_price")}</Col>
                        <Col className="text-align-end" md={0}>{t("mediafee")}</Col>
                        <Col className="p-0 ps-1 invisible" md="auto">
                            <Button className="w-100" color="success">
                                <FontAwesomeIcon icon={faShare} />
                            </Button>
                        </Col>
                        <Col className="p-0 ps-1 invisible" md="auto">
                            <Button className="w-100" color="success">
                                <FontAwesomeIcon icon={faXmarkCircle} />
                            </Button>
                        </Col>
                        <Col className="p-0 ps-1 invisible" md="auto">
                            <Button className="w-100" color="danger">
                                <FontAwesomeIcon icon={faXmarkCircle} />
                            </Button>
                        </Col>
                    </TableHeader>
                    <TableBody>
                        {
                            props.promotions
                                .sort((a, b) => a.approvalStatus - b.approvalStatus)
                                .map(promotion => {
                                    return (
                                        <PromotionRow
                                            user={props.user}
                                            promotion={promotion}
                                            selectedElements={props.selectedElements}
                                            setSelectedElements={props.setSelectedElements}
                                            launchPromotion={props.launchPromotion}
                                        />
                                    )
                                }
                                )
                        }
                    </TableBody>
                </Table>
            </TableRow>
        </>
    );
}

interface PromotionRowAttributes extends React.HTMLAttributes<HTMLElement> {
    user: user | undefined;
    promotion: Promotion
    launchPromotion: (promotionId: number) => void
    selectedElements: Map<number, { promotion: Promotion, approve: boolean }>
    setSelectedElements: Dispatch<SetStateAction<Map<number, { promotion: Promotion, approve: boolean }>>>
}
function PromotionRow(props: PromotionRowAttributes) {
    const { t } = useTranslation(['dashboard']);

    const launchProduct = useCallback((e: any) => {
        e.preventDefault();
        e.stopPropagation();
        props.launchPromotion(props.promotion.id);
    }, [props.launchPromotion, props.promotion])

    const approveSelected = useMemo(() => {
        return props.selectedElements.get(props.promotion.id)?.approve === true;
    }, [props.selectedElements])

    const rejectSelected = useMemo(() => {
        return props.selectedElements.get(props.promotion.id)?.approve === false;
    }, [props.selectedElements])

    const [userCanApprove, approvalFlow] = useMemo(() => {
        const promotion = props.promotion;
        if (!props.user || !getCanPromote(props.user, props.promotion))
            return [false, (e: any) => {
                e.preventDefault();
                e.stopPropagation();
            }]

        const action = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            const promotionPrev = props.selectedElements.get(promotion.id);
            if (promotionPrev?.approve === true) {
                props.selectedElements.delete(promotion.id)
            } else {
                props.selectedElements.set(promotion.id, {
                    approve: true,
                    promotion: promotion
                });
            }
            const newElements = new Map(props.selectedElements);
            props.setSelectedElements(newElements);
        }
        return [true, action]
    }, [props.user, props.promotion, props.selectedElements, props.setSelectedElements])

    const [userCanReject, rejectFlow] = useMemo(() => {
        const promotion = props.promotion;
        if (!props.user || !getCanReject(props.user, props.promotion))
            return [false, (e: any) => {
                e.preventDefault();
                e.stopPropagation();
            }]

        const action = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            const promotionPrev = props.selectedElements.get(promotion.id);
            if (promotionPrev?.approve === false) {
                props.selectedElements.delete(promotion.id)
            } else {
                props.selectedElements.set(promotion.id, {
                    approve: false,
                    promotion: promotion
                });
            }
            const newElements = new Map(props.selectedElements);
            props.setSelectedElements(newElements);
        }
        return [true, action]
    }, [props.user, props.promotion, props.selectedElements, props.setSelectedElements])
    const statusIcon = useMemo(() => getApprovalStatusSymbol(props.promotion.approvalStatus), [props.promotion.approvalStatus])

    return (
        <TableRow>
            <Col md="auto"><FontAwesomeIcon className="icon" icon={statusIcon ?? faExclamationCircle} style={{ color: ApprovalStatusColor[props.promotion.approvalStatus as EApprovalStatus] }} /></Col>
            <Col md={1}>{props.promotion.id}</Col>
            <Col className="text-nowrap overflow-scroll" md={0}>{props.promotion.poNumber}</Col>
            <Col md={3}>{formatToDMY(new Date(props.promotion.fromDate), '/')} - {formatToDMY(new Date(props.promotion.toDate), '/')}</Col>
            <Col className="text-align-end" md={0}>{props.promotion.promotionProducts.map(m => m.euroEstimate).reduce((a, b) => a + b, 0).toFixed(2)}</Col>
            <Col className="text-align-end" md={0}>{props.promotion.promotionProducts.map(m => m.sellOutEuro).reduce((a, b) => a + b, 0).toFixed(2)}</Col>
            <Col className="text-align-end" md={0}>{props.promotion.mediaFee}</Col>
            <Col className="p-0 ps-1" md="auto">
                <Button className="w-100" color="primary" onClick={launchProduct}>
                    <FontAwesomeIcon icon={faShare} />
                </Button>
            </Col>
            <Col className={["p-0", "ps-1", !userCanApprove ? "invisible" : ""].join(" ")} md="auto">
                <Button outline={!approveSelected} className="w-100" color="success" onClick={approvalFlow}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                </Button>
            </Col>
            <Col className={["p-0", "ps-1", !userCanReject ? "invisible" : ""].join(" ")} md="auto">
                <Button outline={!rejectSelected} className="w-100" color="danger" onClick={rejectFlow}>
                    <FontAwesomeIcon icon={faXmarkCircle} />
                </Button>
            </Col>
            <Table>
                <TableHeader className="bold third-layer-header">
                    <Col md={1}>
                        {t("code")}
                    </Col>
                    <Col md={5}>
                        {t("description")}
                    </Col>
                    <Col>
                        {t("discountType")}
                    </Col>
                    <Col className="text-align-end">
                        {t("estimate_price")}
                    </Col>
                    <Col className="text-align-end">
                        {t("actual_price")}
                    </Col>
                </TableHeader>
                <TableBody className="mb-4">
                    {props.promotion.promotionProducts.map(pp =>
                        <ProductRow promotionProduct={pp} />)}
                </TableBody>
            </Table>
        </TableRow>
    )
}

interface ProductRowAttributes extends React.HTMLAttributes<HTMLElement> {
    promotionProduct: PromotionProduct
}
function ProductRow(props: ProductRowAttributes) {
    const { t } = useTranslation(['dashboard']);
    return (
        <TableRow>
            <Col md={1}>{parseInt(props.promotionProduct.productCode)}</Col>
            <Col className="text-nowrap overflow-scroll" md={5}><div>{props.promotionProduct.productDescription}</div></Col>
            <Col md={0}>{props.promotionProduct.discountTypeDescription}</Col>
            <Col className="text-align-end" md={0}>{props.promotionProduct.euroEstimate}</Col>
            <Col className="text-align-end" md={0}>{props.promotionProduct.sellOutEuro}</Col>
        </TableRow>
    )
}
