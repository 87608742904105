export interface PromotionProduct {
    id: number
    productCode: string
    productDescription: string
    productShortDescription: string
    promotionId: number
    discountType: number
    discountTypeDescription: string
    discount: number
    discountPerc: number
    euroEstimate: number
    hlsEstimate: number
    sellOutQuantity: number
    sellOutHls: number
    sellOutEuro: number
    sellOutEuroIn: number
}

export function CreateNewPromotionProduct(promotionId: number): PromotionProduct {
    return {
        id: 0,
        productCode: '',
        productDescription: '',
        productShortDescription: '',
        promotionId: promotionId,
        discountType: 0,
        discountTypeDescription: '',
        discount: 0,
        discountPerc: 0,
        euroEstimate: 0,
        hlsEstimate: 0,
        sellOutQuantity: 0,
        sellOutHls: 0,
        sellOutEuro: 0,
        sellOutEuroIn: 0
    }
} 