import './App.css';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Route, Routes } from 'react-router-dom';
import Header from './Header'
import Dashboard from './features/dashboard/DashboardComponent';
import Login from './features/authentication/Login';
import { ProtectedRoute, AuthenticatedRoute, AuthenticatedRouteIsAdmin, AuthenticatedProductEditor, AuthenticatedCustomerEditor } from './features/authentication/ProtectedRoute';
import User from './features/UserInfo/User';
import { ToastContainer, toast } from 'react-toastify';
import PromotionsTimelineBase from "./features/promotions/views/PromotionsTimelineBase";
import Customers from './features/customers/Customers';
import Products from './features/products/Products';
import { ChangePassword } from './features/UserInfo/ChangePassword';
import ResetPasswordRequest from './features/UserInfo/ResetPasswordRequest';
import ResetPassword from './features/UserInfo/ResetPassword';
import Users from './features/users/Users';
import { withTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import i18next from 'i18next';
import { changeLocale, unwrapError} from './backoffice/apis/axios';
import { user } from './backoffice/dtos/authentication/user';
import { getUser } from './helpers/localStorage';
import { useWindowHeight } from './app/hooks';
import PhoneHeader from './PhoneHeader';
import { Accordion } from './features/views/common/Accordion/Accordion';
import { AccordionItem } from './features/views/common/Accordion/AccordionItem';
import { Promotion } from './features/promotions/views/Promotion';

function App()  {
  const height = useWindowHeight();
  const [user, setUser] = useState<user | undefined>()
  useEffect(()=> {
    const fetchLocale = async () => {
      try {
        await changeLocale(i18next.language);
      } catch (error: any) {
        toast(await unwrapError(error));
      }
    };
    fetchLocale();
  }, [i18next.language])

  useEffect(() => {
    function storageListener() {
        setUser(getUser())
    }
    window.addEventListener("localstorage-user-changed", storageListener)
    return () => window.removeEventListener("localstorage-user-changed", storageListener)
  }, [setUser])
  
  const header = useMemo(() => {
    if (height > 767) {
      return <Header />
    } else {
      return <PhoneHeader />
    }
  }, [height])

  return (
    <div className="App min-vh-100 bg-lvl-1 d-flex flex-column">
      {header}
      <React.Suspense fallback={
        <div className='d-flex vh-100 align-items-center justify-content-center'>
          <Spinner/>
        </div>
      }>
      <TransitionGroup className="d-flex flex-column flex-fill">
        <CSSTransition classNames="page" timeout={300}>
          <Routes>
            <Route path='/reset/password/request' element={<ResetPasswordRequest />} />
            <Route path='/reset/password/:token' element={<ResetPassword />} />
            
            <Route element={<AuthenticatedRoute user={user}/>}>
              <Route path='/login' element={<Login />} />
            </Route>
        
            <Route element={<ProtectedRoute user={user}/>}>
              <Route element={<AuthenticatedRouteIsAdmin  />}>
                <Route path='/users' element={<Users />} />
              </Route>

              <Route path='/dashboard' element={<Dashboard />} />

              <Route path='/promotions' element={<PromotionsTimelineBase />} />

              <Route element={<AuthenticatedProductEditor  />}>
                <Route path='/products' element={<Products />} />
              </Route>

              <Route path='/user' element={<User />} />

              <Route path='/changePassword' element={<ChangePassword />} />

              <Route element={<AuthenticatedCustomerEditor  />}>
                <Route path='/customers' element={<Customers />} />
              </Route>

              <Route path='*' element={<Dashboard />} />
            </Route>            
          </Routes>
        </CSSTransition>
      </TransitionGroup>
      <ToastContainer
        key='toast-container-global'
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      </React.Suspense>
    </div>
  );
}

export default withTranslation()(App);

