import React,{ useState } from 'react'
import { useDeleteCustomerMutation, useGetCustomersQuery, useUpdateCustomerOrderMutation,useActivateDeletedCustomerMutation } from '../../backoffice/apis/customerAPI'
import { Container, ListGroup, ListGroupItem, Row, Col, Button, Input } from 'reactstrap'
import CustomerSearch from './CustomerSearch'
import { Customer, FromToOrder } from '../../backoffice/dtos/customer/customer'
import CustomerEditModal from './CustomerEditModal'
import "../../App.css"
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashArrowUp } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

const Customers = () => {

  const {t} = useTranslation(['customers', 'common'])
  const [selectedCustomer,setSelectedCustomer]  = useState<Customer>()
  const [isOpen, setIsOpen] = useState(false)   
  const [searchTerm, setSearchTerm] = useState<string>("")

  const [ deleteCustomer ] = useDeleteCustomerMutation()


  const {data: customers} = useGetCustomersQuery({query: searchTerm, includeDeleted: true})
  const [patchCustomerOrder] = useUpdateCustomerOrderMutation()
  const [activateDeletedCustomer] = useActivateDeletedCustomerMutation()

  
  const toggle = (customer: Customer) => {
    setIsOpen(!isOpen)      
    setSelectedCustomer(customer)
  } 
  
  const handleChangeOrder = (e: React.KeyboardEvent<HTMLInputElement>, from: number, to: string) => {
    if (e.key === 'Enter') {
      if (customers && to !== '') {
        const parsedTo = parseInt(to);
        alertForUpdate({fromId:from,toOrder:parsedTo})        
      } else {
        toast(t('no_input_value_error'));
      }
  
      // Clear the input value
      (e.target as HTMLInputElement).value = '';
    }
  };  

  const updateOrderAsync = async (req: FromToOrder) =>{
      try {
        await patchCustomerOrder(req)
        toast(t('successful_update_alert'))
      } catch (error:any) {
        toast(error.message)
      }
  }
  const alertForUpdate =  (req: FromToOrder) =>{
    confirmAlert({
      title:t('update_order_alert_title'),
      message: t('update_order_alert_description'),
      buttons: [{ label: t('yes'), onClick:() => updateOrderAsync(req)  }, { label: t('no') }]
    })
  }

  const deleteCustomerAsync = async (id: any) => {
    try {
      deleteCustomer(id) 
      toast( t('successful_delete_alert') )
    } catch (e:any) {
      toast(e.data.message)
    }
  }
  const activateDeletedCustomerAsync = async (id: any) =>{
    try {
      await activateDeletedCustomer(id)
    } catch (e:any) {
      toast(e.data.message)
    }
  }
const handleDeleteCustomer = (id: number,customer: string) =>{
    confirmAlert({
      title: t('delete_alert_title'),
      message: t('delete_alert_description',{customer: customer}),
      buttons: [{ label: t('yes',{ns:'common'}), onClick:() => deleteCustomerAsync (id) }, { label: t('no',{ns:'common'}) }]
    })
  }

const handleActivateDeleteCustomer = (id: number, customer: string) =>{
    confirmAlert({
      title: t('activate_alert_title'),
      message: t('activate_alert_description',{customer: customer}),      
      buttons: [{ label: t('yes',{ns:'common'}),onClick:() => activateDeletedCustomerAsync (id) }, { label: t('no',{ns:'common'})}]
    })
  }
   
  return (
    <Container>
      <CustomerSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <ListGroup className='mt-4'>
        <ListGroupItem>
          <Row>
            <Col lg={2}><h3> {t('brand_name')} </h3></Col>
            <Col lg={2}><h3> {t('short_brand_name')} </h3></Col>
            <Col lg={2}><h3> Payer Code </h3> </Col>
            <Col lg={2}><h3> Vendor Code </h3> </Col>
            <Col lg={2}><h3> {t('order')} </h3></Col>             
            <Col lg={2}></Col>
          </Row>
        </ListGroupItem>
        {customers && customers.slice().sort((a, b) => a.order === -1 ? (b.order === -1 ? 0 : 1) : b.order === -1 ? -1 : a.order - b.order).map((customer, index) => 
        customer.isDeleted?       
        (
          <ListGroupItem key={index}>
            <Row className='my-2 gy-1 '  divider>
              <Col lg={2} className='bold-text-deleted'>{customer.description}</Col>
              <Col lg={2} className='bold-text-deleted'>{customer.shortDescription}</Col>
              <Col lg={2} className='bold-text-deleted'>{customer.payerOfAccountCode}</Col>
              <Col lg={2} className='bold-text-deleted'>{customer.vendorCode}</Col>
              <Col lg={2} className='center'>
                <Input className='order-input  text-align-center' placeholder={(customer.order).toString()} disabled />
              </Col> 
              <Col lg={2} className='gy-1'>
                <Row>
                  <Col className='p-1'>
                    <Button className='w-60  btn-success' onClick={() => handleActivateDeleteCustomer(customer.id,customer.shortDescription)}>
                    <FontAwesomeIcon icon={faTrashArrowUp} />
                    </Button>
                  </Col>                  
                </Row>
              </Col>
            </Row>
          </ListGroupItem>
        ):
        (
          <ListGroupItem key={index}>
            <Row className='my-2 gy-1 ' divider>
              <Col lg={2} className='bold-text'>{customer.description}</Col>
              <Col lg={2} className='bold-text'>{customer.shortDescription}</Col>
              <Col lg={2}>{customer.payerOfAccountCode}</Col>
              <Col lg={2}>{customer.vendorCode}</Col>
              <Col lg={2} className='center'>
                <Input className='order-input text-align-center' placeholder={(customer.order).toString()} onKeyDown={(e) => handleChangeOrder(e, customer.id, (e.target as HTMLInputElement).value)} />
              </Col> 
              <Col lg={2} className='gy-1'>
                <Row>
                  <Col className='p-1'>
                    <Button color="primary" className='w-100' onClick={() => toggle(customer)}>
                      <i className='fa fa-edit fa-lg'></i>
                    </Button>
                  </Col>
                  <Col className='p-1'>
                    <Button color="danger" className='w-100' onClick={() => handleDeleteCustomer(customer.id,customer.shortDescription)}>
                      <i className='fa  fa-trash fa-lg'></i>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ListGroupItem>
        )
        
        )}
      </ListGroup>
      <CustomerEditModal isOpen={isOpen} toggle={() => setIsOpen(false)} selectedCustomer={selectedCustomer as Customer} setSelectedCustomer={setSelectedCustomer} />
    </Container>
  )
}

export default Customers