import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import { faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, {useCallback, useMemo, useState} from 'react';

import {Button, ButtonGroup, Col, Form, Input, InputGroup, InputGroupText, Label, Row} from "reactstrap"

import Select from 'react-select';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { DiscountTypeDescription, getDiscountTypeSymbol, EDiscountType, DiscountTypeOptions } from '../../enums/EDiscountType';
import { SelectOption } from '../../helpers/SelectOption';
import { EApprovalStatus } from '../../enums/EApprovalStatus';
import { toast } from 'react-toastify';
import { PromotionProduct } from '../../backoffice/dtos/product/promotionProduct';
import { useAddPromotionProductMutation, useDeletePromotionProductMutation, useUpdatePromotionProductMutation } from '../../backoffice/apis/promotionAPI';
import { useGetProductsQuery } from '../../backoffice/apis/productAPI';
import { ProductCriteria } from '../../backoffice/dtos/promotion/productCriteria';
import { addBooleans } from '../../helpers/utils';
import { Product } from '../../backoffice/dtos/promotion/product';
import { getCanEdit, user} from '../../backoffice/dtos/authentication/user';
import IdItemSelection from "../views/common/IdItemSelection";
import { useTranslation } from 'react-i18next';


function getProductAppearingDescription(product: Product): string {
    return `${product.description} (${Number(product.code)}) ${product.shortDescription ?? ''}`;
}

export default function PromotionProductForm({
    user,
    promotionProduct: initialPromotionProduct,
    promotionStatus,
    setNewProduct
}: {
    user: user | undefined,
    promotionProduct: PromotionProduct
    promotionStatus: EApprovalStatus,
    setNewProduct: (current: PromotionProduct | null) => void
}) {
    const [formState, setFormState] = useState(initialPromotionProduct)
    const [addPromotionProduct] = useAddPromotionProductMutation()
    const [updatePromotionProduct] = useUpdatePromotionProductMutation()
    const [deletePromotionProduct] = useDeletePromotionProductMutation()
    const  {t} = useTranslation(['promotion_timeline','common','promotion'])
    const canEdit = user ? getCanEdit(user) : false;
    const canEditActuals = canEdit && promotionStatus >= EApprovalStatus.POSubmitted

    const [discountType, setDiscountType] = useState(
        { value: initialPromotionProduct.discountType, label: initialPromotionProduct.discountTypeDescription })

    const { data: products } = useGetProductsQuery({} as Partial<ProductCriteria>)


    const productOptions = useMemo(() => {
        return products ? products.map(p => (
            { value: parseInt(p.code), label: getProductAppearingDescription(p) }
        ) as SelectOption) : []
    }, [products])

    const [showSaveButton, setShowSaveButton] = useState(false)

    const handleChange = ({
        target: { name, value },
    }: React.ChangeEvent<HTMLInputElement>) => {
        setFormState((prev) => ({ ...prev, [name]: value }))
        setShowSaveButton(true)
    }

    const selectedProductChanged = useCallback((choice: number | null) => {
        if (choice === null)
            setFormState((prev) => ({ ...prev, productCode: '', productDescription: '' }))

        const choiceStr = String(choice);
        const filteredProducts = products?.filter(p => p.code.includes(choiceStr));
        if (filteredProducts && filteredProducts.length > 0) {
            const product = filteredProducts[0];
            setFormState((prev) => ({ ...prev, productCode: product.code, productDescription: product.description }))
            setShowSaveButton(true)
        } else {
            setFormState((prev) => ({ ...prev, productCode: choiceStr, productDescription: '' }))
        }
    }, [products])

    const discountTypeIdToLabel = (choice: number) => choice === 1 ? 'Αξία' : choice === 2 ? 'Ποσοστό' : choice === 3 ? 'Loyalty Cards' : ''

    const discountTypeChanged = (choice: number) => {
        const label = discountTypeIdToLabel(choice)
        if (!label) return

        setFormState((prev) => ({ ...prev, 'discountType': choice, 'discountTypeDescription': label }))
        setDiscountType({ value: choice, label: label })
        setShowSaveButton(true)
    }



    const saveHandler = async () => {
        try {
            if (!formState.id) {
                setNewProduct(formState)
                await addPromotionProduct({ ...formState }).unwrap()
            } else {
                await updatePromotionProduct({ ...formState }).unwrap()
            }
            setShowSaveButton(false)
            setNewProduct(null)
        } catch (e: any) {
            toast(t('unsuccessful_save_text',{ns:'promotion', error: e}))
        }
    }

    const deletePromotionProductAsync = async () => {
        try {
            await deletePromotionProduct({ ...formState }).unwrap()
        } catch (e: any) {
            toast(t('unsuccessful_delete_text',{ns:'promotion', error: e}))
        }
    }

    const deleteHandler = () => {
        if (formState.id) {
            confirmAlert({
                title: t('delete_product_title'),
                buttons: [{ label: t('yes',{ns:'common'}), onClick: () => deletePromotionProductAsync() }, { label: t('no',{ns:'common'}), onClick: () => { } }]
            })
        } else {
            setNewProduct(null)
        }
    }

    const saveButton = showSaveButton ?
        <Button color="success" onClick={saveHandler}> <FontAwesomeIcon icon={faSave} /></Button> : <></>

    const discountTypeField = canEdit ? <Select
        isClearable={false}
        className='react-select w-60'
        classNamePrefix='select'
        options={DiscountTypeOptions}
        value={discountType}
        onChange={(choice) => discountTypeChanged(choice?.value ?? 1)}
    /> : <Input className="w-25" type="text" name='discountType' value={formState.discountTypeDescription} disabled={true} />

    const discountTypeLabel = useMemo(() => getDiscountTypeSymbol(formState.discountType), [formState.discountType])

    return (
        <Form >
                <Row className="gy-2 p-2">
                    <Label for="productId" md={2}>{t('product')}</Label>
                    <Col lg={10}>
                        <IdItemSelection id={parseInt(formState.productCode)} disabled={!canEdit} options={productOptions} setSelection={selectedProductChanged}/>
                    </Col>
                </Row>

                <Row className="gy-2 p-2">
                    <Label for="discountType" md={2}>{t('discount')}</Label>
                    <Col lg={5}>
                        <InputGroup>
                            {discountTypeField}
                            <Input className="text-align-end" type="text" name='discount' onChange={handleChange} value={formState.discount} disabled={!canEdit} />
                            {discountTypeLabel && <InputGroupText><FontAwesomeIcon icon={discountTypeLabel}/></InputGroupText>}
                        </InputGroup>
                    </Col>
                </Row>

                <Row className="gy-2 p-2">
                    <Label for="hlsEstimate" md={2}>{t('prediction')}</Label>
                    <Col lg={3}>
                        <InputGroup>
                            <Input className="text-align-end" type="text" name='hlsEstimate' onChange={handleChange} value={
                                formState.hlsEstimate.toLocaleString("en-US", {maximumFractionDigits: 1, useGrouping:false})
                            } disabled={!canEdit} />
                            <InputGroupText>Hls</InputGroupText>
                        </InputGroup>
                    </Col>
                    <Col lg={4}/>
                    <Col lg={3}>
                        <InputGroup>
                            <Input className="text-align-end" type="text" name='euroEstimate' onChange={handleChange} value={
                                formState.euroEstimate.toLocaleString("en-US", {maximumFractionDigits: 2, useGrouping:false})
                            } disabled />
                            <InputGroupText>€</InputGroupText>
                        </InputGroup>
                    </Col>
                </Row>

                <Row className="gy-2 p-2">
                    <Label for="sellOutEuro" md={2}>Actual</Label>
                    <Col lg={3}>
                        <InputGroup>
                            <Input className="text-align-end" type="text" name='sellOutQuantity' onChange={handleChange} value={
                                formState.sellOutQuantity.toLocaleString("en-US", {maximumFractionDigits: 0, useGrouping:false})
                            } disabled={!canEditActuals} />
                            <InputGroupText>Τεμ</InputGroupText>
                        </InputGroup>
                    </Col>
                    <Col lg={1}/>
                    <Col lg={3}>
                        <InputGroup>
                            <Input className="text-align-end" type="text" name='sellOutHls' value={
                                formState.sellOutHls.toLocaleString("en-US", {maximumFractionDigits: 1, useGrouping:false})
                            } disabled={true} />
                            <InputGroupText>Hls</InputGroupText>
                        </InputGroup>
                    </Col>

                    <Col lg={3}>
                        <InputGroup>
                            <Input className="text-align-end" type="text" name='sellOutEuroIn' onChange={handleChange} value={
                                formState.sellOutEuroIn === 0 ? undefined : formState.sellOutEuroIn
                            } placeholder={
                                formState.sellOutEuro.toLocaleString("en-US", {maximumFractionDigits: 2, useGrouping:false})
                            } disabled={!canEditActuals} />
                            <InputGroupText>€</InputGroupText>
                        </InputGroup>
                    </Col>
                </Row>

                { canEdit &&
                <Row className="gy-2 p-2">
                    <Col lg={12 - (addBooleans(showSaveButton, true))}/>
                    <Col lg={addBooleans(showSaveButton, true)}>
                            <ButtonGroup className="w-100">
                                {saveButton}
                                <Button color="danger" onClick={deleteHandler}> <FontAwesomeIcon icon={faTrash} /></Button>
                            </ButtonGroup>
                        </Col>
                </Row>
                }
                <hr/>
        </Form>
    )
}
