import React, { useCallback, useState } from 'react';
import { useNavigate } from "react-router-dom";
import {
    Navbar,
    Nav,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';


import { NavLink } from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faGlobe, faPeopleGroup, faStore } from '@fortawesome/free-solid-svg-icons';
import { getUser } from './helpers/localStorage';
import { userLogout } from './backoffice/dtos/authentication/user';
import PromotionFinder from './features/promotion_finder/PromotionFinder';
import { AllowedRoles, CustomerEditors, ProductEditors, getUserHasRole } from './backoffice/dtos/authentication/role';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import ReactCountryFlag from 'react-country-flag';
import { supportedLanguages } from './i18n/i18nProps';


export default function Header() {
    const user = getUser()
    const { t } = useTranslation(['header','common'])

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [globeOpen, setGlobeOpen] = useState(false);
    let navigate = useNavigate();

    const toggle = () => setDropdownOpen(!dropdownOpen);
  
    const logoutHandler = useCallback(() => {
        userLogout()
    }, [])

    if (!user)
        return <></>;

    const dashboardLink = <div className="p-2">
            <NavLink className="nav-link" to='/dashboard'><span className="fa fa-home fa-lg"></span> {t('pending')} </NavLink>
        </div>

    const promotionsLink = <div className="p-2">
            <NavLink className="nav-link" to='/promotions'><span className="fa fa-list fa-lg"></span> {t('promotion_timeline')}</NavLink>
        </div>    


    const userLink = (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle nav caret style={{minHeight:40, minWidth: 40}} className='justify-content-center align-items-center d-flex m-1'>
                {user ? user.userName : '' }
            </DropdownToggle>
            <DropdownMenu style={{width:'14rem'}} >
                <DropdownItem onClick={() => navigate('user')}>
                    <span className="fa fa-info fa-lg dropdown-icon"></span> {t('user_info')}
                </DropdownItem>
                <DropdownItem divider/>  
                
                    {user && !AllowedRoles.some(r => getUserHasRole(user, r.name)) ? <></> :<>  
                    <DropdownItem onClick={() => navigate('users')}>
                        <span className="fa fa-lg dropdown-icon"><FontAwesomeIcon icon={faPeopleGroup} /></span> {t('users')}
                    </DropdownItem>
                    <DropdownItem divider/>
                    </>
                }

                { user && !CustomerEditors.some(r => getUserHasRole(user, r.name)) ? <></> :<>
                    <DropdownItem onClick={() => navigate('customers')}>
                    <span className='fa-lg fa dropdown-icon'><FontAwesomeIcon icon={faStore} /></span> {t('customers')}                
                    </DropdownItem>
                    <DropdownItem divider/>
                    </>
                }
                { user && !ProductEditors.some(r => getUserHasRole(user, r.name)) ? <></> :<>
                    <DropdownItem onClick={() => navigate('products')}>
                        <span className="fa fa-pencil-square fa-lg dropdown-icon"/> {t('products')}
                    </DropdownItem>
                    <DropdownItem divider/>
                    </>
                }
                <DropdownItem className="ms-auto" onClick={logoutHandler}>
                    <span className="fa fa-sign-out fa-lg dropdown-icon"></span> {t('log_out')}
                </DropdownItem>
            </DropdownMenu>                    
        </Dropdown>
    )

    const languageOption = (
        <Dropdown isOpen={globeOpen} toggle={() =>setGlobeOpen(!globeOpen)}>
            <DropdownToggle nav style={{minHeight:40, minWidth: 40}} className='justify-content-center align-items-center d-flex m-1'>
                <FontAwesomeIcon icon={faGlobe} />
            </DropdownToggle>
            <DropdownMenu>
                {supportedLanguages.map((item,index) =>{
                    return(
                        <DropdownItem key={index} className={i18next.language === item.code ? 'bold' : ''} onClick={() => i18next.changeLanguage(item.code)}>
                            <ReactCountryFlag
                                className="emojiFlag"
                                countryCode={item.countryCode}
                                style={{
                                    fontSize: '1.2em',
                                    lineHeight: '1.2em',
                                }}
                                aria-label="Country Flag"
                            />
                            <span className='mx-2'>{item.name}</span>
                        </DropdownItem>
                    )
                })}                
            </DropdownMenu>                    
        </Dropdown>
    )

    return (
        <Navbar className="z-1 row-shadow d-flex flex-row justify-content-between align-items-center p-2 color-navbar flex-nowrap">
            <Nav navbar className='d-flex flex-row' style={{minWidth: 350, width: 500}}>
                {dashboardLink}

                {promotionsLink}
            </Nav>

            <span className='mx-3'  style={{minWidth: 250, width: 500}}>
                <PromotionFinder />
            </span>

            <div className='d-flex justify-content-end' style={{width: 500}}>
                {languageOption}
                {userLink}
            </div>         

        </Navbar>
    )
}
