import { useSearchParams } from "react-router-dom"
import PromotionModal from "../../product_modal/PromotionModal"
import { useCallback, useMemo } from "react"

export function Promotion() {
    const [searchParams, setSearchParams] = useSearchParams()

    const promotionId = useMemo(() => {
        const result = searchParams.get("promotionId");
        if (!result)
            return -1;

        const promotionId = parseInt(result);
        if (Number.isNaN(promotionId))
            return -1;

        return promotionId
    }, [searchParams])
    
    const toggle = useCallback(() => {
        searchParams.delete("promotionId")
        setSearchParams(searchParams)
    }, [searchParams])

    return <PromotionModal currentPromotionId={promotionId} promotionModalOpen={promotionId >= 0} toggle={toggle}/>
}
