import {ApprovalStatusColor, EApprovalStatus} from "../../../enums/EApprovalStatus";
import { getMediaTypeDescription } from "../../../enums/EMediaType";
import {normalizedNumber} from "../../../helpers/utils";
import  i18next from "i18next";

export type CustomerTimelineResource = {
    order: number;
    customerId: number;
    customerShortDescription: string;
    total: number;
    hls: number;
    mediaTypeTimelineModels: MediaTypeTimelineResource[]
}

export type MediaTypeTimelineResource = {
    mediaTypeId: number,
    mediaDescription: string,
    fromDate: string,
    toDate: string,
    hls: number,
    euro: number,
    total: number,
    mediaFee: number,
    timelineEventModels: TimelineEvents[]
}

export type TimelineEvents = {
    id: number,
    poNumber: string,
    description: string,
    fromDate: Date,
    toDate: Date,
    totalEuro: number,
    totalHls: number,
    hasActuals: boolean,
    mediaFee: number,
    approvalStatus: number,
    proposalSent: boolean,
    timelineEventDetails: TimelineEventDetails[]
}

export type TimelineMasterWithEvent = {
    customerMaster: CustomerTimelineResource,
    mediaTypeMaster: MediaTypeTimelineResource,
    event: TimelineEvents
}

export type TimelineEventDetails = {
    order: number,
    description: string,
    hls: number,
    discount: string
}

export interface ResourceAreaColumn {
    field: string
    headerContent: string
    group?: boolean
    width?: number;
    cellClassNames?: string | string[];
    headerClassNames ?: string | string[];
}

export interface TimelineEvent {
    resourceId: string
    title: string
    start: Date
    end: Date
    backgroundColor?: string
    borderColor?: string
    classNames?: string[]
    extendedProps?: any
    border?: string
}

//| 'EstimateHls' | 'MediaFee'
export interface ResourceItem {
    id: string;
    title: string;
    eventClassNames: string|string[]|undefined;
    customerId: number
    mediaTypeId: number
    customer: string;
    pageCost: string;
    euro: string;
    hls: string;
    budget: string;
    implemented: string;
    color: string;
    order: number
}


export const resourceAreaColumns = ():Array<ResourceAreaColumn>  => { return [
    {field: 'title', headerContent: i18next.t('media_type',{ns:'promotion_timeline',defaultValue:"Media Type"}), group: undefined, width: 60,cellClassNames:["rotate-90","min-height-50px"]},
    {field: 'total', headerContent: i18next.t('totals', {ns:'promotion_timeline',defaultValue:"Total"}) +  " €", group: undefined, width: 80, cellClassNames: ["backcolor-resource-total-euro", "justify-cell-end", "min-height-50px", "resource-font-size-1_1em"], headerClassNames: ["backcolor-resource-total-euro", "resource-font-size-1_1em"]},
    {field: 'pageCost', headerContent: 'Media Fee €', group: undefined, width: 80, cellClassNames: ["backcolor-resource-media-fee", "justify-cell-end", "min-height-50px", "resource-font-size-1_1em"], headerClassNames: ["backcolor-resource-media-fee", "resource-font-size-1_1em"]},
    {field: 'euro', headerContent: i18next.t('discount', {ns:'promotion_timeline',defaultValue:"Total"}) + " €", group: undefined, width: 80, cellClassNames: ["backcolor-resource-discount-euro", "justify-cell-end", "min-height-50px", "resource-font-size-1_1em"], headerClassNames: ["backcolor-resource-discount-euro", "resource-font-size-1_1em"]},
    {field: 'hls', headerContent: i18next.t('totals', {ns:'promotion_timeline',defaultValue:"Total"}) + " HLS", group: undefined, width: 80, cellClassNames: ["backcolor-resource-total-hls", "justify-cell-end", "min-height-50px", "resource-font-size-1_1em"], headerClassNames: ["backcolor-resource-total-hls", "resource-font-size-1_1em"]}
]
}





export function toResourceItems(customerTimelineResource: CustomerTimelineResource[] | undefined): ResourceItem[] {
    if (!customerTimelineResource)
        return [];

    customerTimelineResource = [...customerTimelineResource]
    return customerTimelineResource
        .sort((a, b) => a.order - b.order)
        .flatMap((c, i) => c.mediaTypeTimelineModels.map(m => ({
            index: i,
            customer: c,
            mediaType: m,
        })))
        .map(m => {
            return {
                id: `promotions_for_${m.customer.customerId}_${m.mediaType.mediaTypeId}`,
                // title: m.mediaType.description,

                title: getMediaTypeDescription(m.mediaType.mediaTypeId) ,
                eventClassNames: [`lane_color_${m.customer.order % 2}`, 'fullcalendar-z-index'],
                customerId: m.customer.customerId,
                customer: `${m.customer.customerShortDescription} ${normalizedNumber(m.customer.total, 2)}€ (${normalizedNumber(m.customer.hls, 0)}Hls)`,
                implemented: '',
                color: "white",
                order: m.index,
                pageCost: normalizedNumber(m.mediaType.mediaFee, 2),
                budget: "",
                total: normalizedNumber(m.mediaType.total, 2),
                euro: normalizedNumber(m.mediaType.euro, 2),
                hls: normalizedNumber(m.mediaType.hls, 0),
                mediaTypeId: m.mediaType.mediaTypeId
            } as ResourceItem
        });
}

export function toTimelineEvents(customerTimelineResource: CustomerTimelineResource[] | undefined): TimelineEvent[] {
    if (!customerTimelineResource)
        return [];
    return customerTimelineResource
        .flatMap(customer => customer.mediaTypeTimelineModels.flatMap(
            mediaType => mediaType.timelineEventModels.map(
                event => ({ customer, mediaType, event })
        )))
        .map(m => {
            const toDate = new Date(m.event.toDate);
            toDate.setDate(toDate.getDate() + 1);
            //
            const endDate = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate())
            //
            const newDate = toDate.getDate() + 1;
            toDate.setDate(newDate);
            //
            const classes: string[] = []
            if (m.event.hasActuals)
                classes.push('with-actual')
                        
            return {
                resourceId: `promotions_for_${m.customer.customerId}_${m.mediaType.mediaTypeId}`,
                title: m.event.description,
                start: m.event.fromDate,
                end: endDate,
                backgroundColor: ApprovalStatusColor[m.event.approvalStatus as EApprovalStatus],
                extendedProps: m,
                classNames: classes              
            }
        });
}
