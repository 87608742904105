import React,{useState,useEffect, useMemo, useTransition} from 'react'
import ProductSearch from './ProductSearch'
import { Col, ListGroup, ListGroupItem, Row,Button, Spinner, Container } from 'reactstrap'
import "./Product.css"
import { CountPages, PageCriteria } from '../../backoffice/dtos/promotion/pagesCriteria'
import { useGetPagesCountQuery, useGetShortDescriptionsQuery } from '../../backoffice/apis/descriptionsAPI'
import ProductEditModal from './ProductEditModal'
import { PageableProducts, Product } from '../../backoffice/dtos/promotion/product'
import { useTranslation } from 'react-i18next'




const Products = () => {
    const {t} = useTranslation(['products'])
    const [nextPage, setNextPage] = useState<number>(1)
    const [limit, setLimit] = useState<number>(100)
    const [searchTerm, setSearchTerm] = useState<string>("")
    const [isOpen, setIsOpen] = useState(false)   
    const [selectedProduct,setSelectedProduct] = useState<Product | null>()
    const [products, setProducts] = useState<PageableProducts>()

    const { data: productsResult } = useGetShortDescriptionsQuery({ limit: limit, page: nextPage, query: searchTerm } as Partial<PageCriteria>)
    const { data: pages} = useGetPagesCountQuery({limit:limit,query: searchTerm} as Partial<CountPages>)
    
    useEffect(()=>{
        setProducts(productsResult)
    },[productsResult])
    
    useEffect(()=>{
        setNextPage(1)
    },[limit,searchTerm])  

    const toggle = (product: Product) => {
      setIsOpen(!isOpen)      
      setSelectedProduct(product)
    }    
    
    const mapList = products !== undefined ?
    products.products.map((product)=>{
        return (
            <ListGroupItem key={product.code}>
                    <Row>
                        <Col>{product.code}</Col>
                        <Col>{product.description}</Col>
                        <Col>{product.shortDescription}</Col>                        
                        <Col md={1}><Button color='primary' onClick={() => toggle(product)}><i className='fa fa-edit fa-lg'></i></Button></Col>                        
                    </Row>
                </ListGroupItem>
        )
    }) : <Spinner/>;
    
    const pagesList = useMemo(() => {
        if (pages === undefined)
            return []

        return [
            <Button className='w-25' style={{borderTopRightRadius:'0px',borderBottomRightRadius:'0px'}} onClick = {() => setNextPage(prev => prev - 1)} disabled={nextPage <= 1}>Prev</Button>,
            <Button className='w-50' style={{borderRadius:'0px'}} color='primary' disabled>{nextPage}</Button>,
            <Button className='w-25' style={{borderTopLeftRadius:'0px',borderBottomLeftRadius:'0px'}} onClick = {() => setNextPage(prev => prev + 1)} disabled={nextPage >= pages}>Next</Button>
        ]
    }, [pages, nextPage])

  return (
        <>
            <Container>
                <Row className='m-3  gy-3'>
                    <ProductSearch limit={limit} setLimit = {setLimit} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />                   
                    <Col lg={3} className='text-right'>  
                            {pagesList}
                    </Col>
                </Row>
                <Row>
                    <ListGroup className='parentDiv'>
                            <ListGroupItem>
                                <Row>
                                    <Col style={{fontWeight:'bold'}}>{t('code').toUpperCase()}</Col>
                                    <Col style={{fontWeight:'bold'}}>{t('description').toUpperCase()}</Col>
                                    <Col style={{fontWeight:'bold'}}>{t('short_description').toUpperCase()}</Col>                        
                                    <Col md={1}></Col>                        
                                </Row>
                            </ListGroupItem>  
                        <div className='childDiv'>
                            {mapList }                
                        </div>              
                    </ListGroup>
                </Row>
            </Container>            
            <ProductEditModal isOpen = {isOpen} toggle = {toggle} selectedProduct= {selectedProduct || undefined} setSelectedProduct={setSelectedProduct} />
        </>
  )
}

export default Products